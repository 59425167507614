<template>
<span>
     <v-footer
         dark
         color="#01a59a"
         style="position: fixed;
            bottom: 0;
            width: 100%;"
         padless>
      <div
          style="text-align: center; width: 100%; padding: 7px"
      >
        <small>

Copyright © {{ new Date().getFullYear() }} Noregrets , All rights reserved.
  </small>

      </div>
    </v-footer>
</span>
</template>

<script>
export default {
  name: "MFooter"
}
</script>

<style scoped>

</style>