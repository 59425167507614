<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Groups</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2"
              dark
              color="teal"
              small
              @click="doAddNewGroup"
          >
      <v-icon dark>
       mdi-plus
      </v-icon>
            New Group
    </v-btn>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat>


                <v-row class="mx-auto mt-5">
<v-col cols="8"></v-col>
<v-col cols="4">
   <v-text-field
       clearable
       v-model="search"
       append-icon="mdi-magnify"
       label="Search"
       dense
       outlined
       persistent-hint
       hint="Press enter to search"
       v-on:keyup.enter="filterList"
   ></v-text-field>
</v-col>

            </v-row>




   <v-divider></v-divider>
                  <v-progress-linear
                      v-if="tableLoader"
                      indeterminate
                      color="#242f7e"
                      class="mb-0"
                      height="2"
                  ></v-progress-linear>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>



             <v-simple-table
                 fixed-header
             >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Description
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.number"
                    >
                      <td>{{ item.key }}</td>
                      <td>
                          <router-link class="text-decoration-none" :to="{name:'messenger', query:{type:'grp', id:item.number, q:item.name}}">
                              <v-icon class="mr-3">
                             mdi-account-group
                            </v-icon>
                            {{item.name}}
                          </router-link>
                      </td>
                      <td>{{ item.grp_desc }}</td>
                      <td>



                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                               <v-icon  v-bind="attrs"
                                        v-on="on" small class="mr-2" @click="contacts(item)">
                              mdi-account-multiple-plus
                            </v-icon>
                            </template>
                            <span> Add Contacts to group</span>
                        </v-tooltip>


                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                               <v-icon  v-bind="attrs"
                                        v-on="on" small class="mr-2" @click="editGroup(item)">
                              mdi-pencil
                            </v-icon>
                            </template>
                            <span>Edit Group</span>
                        </v-tooltip>

                      </td>


                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>


        </v-card>



        </v-card>

      </v-flex>
    </v-layout>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="groupDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>New Group</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="groupDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-group ref="group_component"></new-group>

         </v-card>


      </v-card>
    </v-dialog>


   <v-dialog
       transition="dialog-bottom-transition"
       v-model="editGroupDialog"
       width="42%"
   >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit Group</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editGroupDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-group :groupInfo="groupInfo" ref="edit_group_component"></new-group>

         </v-card>


      </v-card>
    </v-dialog>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="groupContactDialog"
        width="40%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Contacts under {{groupInfo.name}}</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="groupContactDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <group-contacts :groupInfo="groupInfo" ref="group_component"></group-contacts>

         </v-card>


      </v-card>
    </v-dialog>


</span>
</template>

<script>
import NewGroup from "@/views/Main/Groups/NewGroup";
import {mapActions, mapGetters} from "vuex";
import GroupContacts from "@/views/Main/Groups/GroupContacts";
export default {
  name: "Groups",
  components: {GroupContacts, NewGroup},
  data(){
    return{
      search: null,
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page: 15,
      items_per_page_options: [20, 50, 100, 500],
      server_items_length: 0,
      contactDialog:false,
      generalTableData: [],
      groupDialog:false,
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Name", value: "name", width: "30%"},
        {text: "Description", value: "grp_desc" , width: "50%"},
        {text: "Action", value: "actions", width: "5%"}
      ],
      groupInfo:{},
      groupContactDialog:false,
      editGroupDialog:false,
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
          "fetchGroupsPaginated"
        ]
    ),
    doAddNewGroup(){
      this.groupDialog = true
    },
    filterGroupData(data){
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.tableLoader = false;


      let tableJsn = {};
      //let tableObj = [];

      for (let i = 0; i < dataObj.length; i++) {
        let name = dataObj[i]
        tableJsn = {
          key: (current_page - 1) * per_page + i + 1,
          name: name.grp_name,
          grp_desc: name.grp_desc,
          number: name.number,
        };
        // tableObj.push(tableJsn);

        const index = this.generalTableData.findIndex(object => object.number === tableJsn.number);

        if (index === -1) {
          this.generalTableData.push(tableJsn);
        }


      }

      // this.generalTableData = tableObj;
    },
    contacts(item){
      this.groupContactDialog = true
      this.groupInfo = item
    },
    editGroup(item){
      this.editGroupDialog = true
      this.groupInfo = item
    },
    filterList() {
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchGroupsPaginated(options)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.groupLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
        }
        this.fetchGroupsPaginated(options)
      }
    },

  },
  computed:{
    ...mapGetters(
        [
          "getNewGroupFile",
          "getGroupListPagination",
          "getUpdateGroupInfo"
        ]
    )
  },
  watch:{
    getNewGroupFile(data){
      if(data){
        this.successToast("Success", data.message)
        this.groupDialog = false
        this.$refs["group_component"].spinner = false
        this.$refs["group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.filterList()
      }
    },
    getGroupListPagination(data){
      if(data){
        this.filterGroupData(data)
      }
    },
    getUpdateGroupInfo(data){
      if(data){
        this.editGroupDialog = false
        this.$refs["edit_group_component"].spinner = false
        this.$refs["edit_group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.filterList()
        this.successToast("Success", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>