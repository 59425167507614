import Vue from 'vue'
import Router from 'vue-router'
import MainDashboard from "@/views/Main/MainDashboard";
import SendFromFile from "@/views/Main/SendFromFile/SendFromFile";
import Messenger from "@/views/Messenger/Messenger";
import Home from "@/views/Main/Home/Home";
import PairPhone from "@/views/Messenger/PairPhone/PairPhone";
import Login from "@/views/Auth/Login/Login";
import Register from "@/views/Auth/Register/Register";
import AuthDashboard from "@/views/Auth/AuthDashboard";
import Contacts from "@/views/Main/contacts/Contacts";
import Groups from "@/views/Main/Groups/Groups";
import Company from "@/views/Main/company/Company";
import Users from "@/views/Main/users/Users";
import MessageLogs from "@/views/Main/messageLogs/MessageLogs";
import ImportContacts from "@/views/Messenger/importcontacts/ImportContacts";
import CallLogs from "@/views/Main/CallLogs/CallLogs";
import GroupMessageLogs from "@/views/Main/GroupMessageLogs/GroupMessageLogs";
import Settings from "@/views/Main/settings/Settings";
import GroupContactFrame from "@/views/Main/Groups/GroupContactFrame";

Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: AuthDashboard,
            redirect: '/login',
            children: [
                {
                    path: 'login',
                    name: 'login',
                    component: Login
                },
                {
                    path: 'register',
                    name: 'register',
                    component: Register
                },
            ]
        },
        {
            path: '/dashboard',
            component: MainDashboard,
            name :"dashboard",
            redirect: '/dashboard/home',
             meta: {
                 requiresAuth: true,
             },
            children: [
                {
                    path: 'messenger',
                    name: 'messenger',
                    component: Messenger
                },
                {
                    path: 'home',
                    name: 'home',
                    component: Home
                },
                {
                    path: 'send-from-file',
                    name: 'send_from_file',
                    component: SendFromFile
                },
                {
                    path: 'pair-phone',
                    name: 'pair-phone',
                    component: PairPhone
                },
                {
                    path: 'new-contact',
                    name: 'new-contact',
                    component: Contacts
                },
                {
                    path: 'group',
                    name: 'group',
                    component: Groups
                },
                {
                    path: 'company',
                    name: 'company',
                    component: Company
                },
                {
                    path: 'users',
                    name: 'users',
                    component: Users
                },
                {
                    path: 'group-contacts',
                    name: 'group-contacts',
                    component: GroupContactFrame
                },
                {
                    path: 'message-logs',
                    name: 'message-logs',
                    component: MessageLogs
                },
                {
                    path: 'import-contacts',
                    name: 'import-contacts',
                    component: ImportContacts
                },
                {
                    path: 'group-message-logs',
                    name: 'group-message-logs',
                    component: GroupMessageLogs
                },
                {
                    path: 'call-logs',
                    name: 'call-logs',
                    component: CallLogs
                },
                {
                    path: 'settings',
                    name: 'settings',
                    component: Settings
                },
                {
                    path: 'settings/:id',
                    name: 'settings',
                    component: Settings
                },

            ]
        },

    ],

});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("userProfile") == null) {
            next({
                name: "login",
                params: { nextUrl: to.fullPath },
            });
        } else {
            let user = JSON.parse(localStorage.getItem("userProfile"));
            if (user !== null) {
                next();
            } else {
                next({ name: "dashboard" });
            }
        }
    } else {
        if (localStorage.getItem("userProfile") === null) {
            next();
        } else {
            next({
                name: "dashboard",
            });
        }
    }
});






const DEFAULT_TITLE = "Messenger | Noregrets";
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });

});

export default router
