<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
     <v-form @submit.prevent="registerContact">

        <v-card-text>

          <v-text-field
              outlined
              v-model="form.surname"
              label="Surname"
              required
              type="text"
              dense
          ></v-text-field>


           <v-text-field
               outlined
               v-model="form.second_name"
               label="Second Name"
               required
               type="text"
               dense
           ></v-text-field>

           <v-text-field
               outlined
               v-model="form.other_names"
               label="Other Names"
               required
               type="text"
               dense
           ></v-text-field>


          <v-select
              v-model="form.gender"
              label="Sex"
              :items="genderList"
              item-value="id"
              outlined
              required
              dense
          ></v-select>



          <v-text-field
              @keypress="onlyNumberValidator($event)"
              maxlength="12"
              outlined
              v-model="form.phone"
              label="Phone"
              required
              type="tel"
              dense
          ></v-text-field>


          <v-row class="mx-auto">
             <v-btn
                 @click="clearContactForm"
                 outlined
                 class="mt-2"
             >
             Clear
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="mt-2"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
              Add Contact
            </v-btn>
          </v-row>


        </v-card-text>
      </v-form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewContact",
  props:{
    contactInfo:{}
  },
  data(){
    return{
      errorMessage:null,
      form:{
        surname:null,
        second_name:null,
        other_names:null,
        phone:null,
        gender:null,
      },
      spinner:false,
      genderList:[
        {text:"Male", id:"Male"},
        {text:"Female", id:"Female"}
      ],
      contactId:null
    }
  },
  mounted() {
    this.checkContacts()
  },
  methods:{
    ...mapActions(
        [
            "addNewContact",
            "fetchContactInfo",
            "editUserContact"
        ]
    ),
    registerContact(){

      this.errorMessage = ""
      if(this.form.surname == null){
        this.errorMessage = "Surname is required!"
        return
      }

    if(this.form.second_name == null){
        this.errorMessage = "Second is required!"
        return
      }

      if(this.form.gender == null){
        this.errorMessage = "Gender is required!"
        return
      }


      if(this.form.phone == null){
        this.errorMessage = "Phone is required!"
        return
      }

      this.spinner = true
      let formData = this.form
      if(this.contactId){
          formData.contactId = this.contactId
          this.editUserContact(formData)
      }else {
        this.addNewContact(formData)
      }

    },
    clearContactForm(){
      this.errorMessage = ""
      this.form = {
        surname:null,
        second_name:null,
        other_names:null,
        phone:null,
        gender:null,
      }
    },
    checkContacts(){
      this.fetchContactInfo(this.contactInfo.number)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewContactError",
            "getContactDataInfo"
        ]
    )
  },
  watch:{
    getNewContactError(data){
      if(data) {
        this.errorToast("Error", data.message)
        this.spinner = false
        this.errorMessage = data.message
      }
    },
    contactInfo(){
      this.checkContacts()
    },
    getContactDataInfo(data){
      if(data){
        let contact = data.data
        let user = contact.User
      this.form.surname = user.surname
      this.form.second_name = user.second_name
      this.form.other_names = user.other_names
      this.form.gender = user.gender
      this.form.phone = contact.contact
      this.contactId = contact.number
      }
    },
  }
}
</script>

<style scoped>

</style>