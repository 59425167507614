<template>
<span>
   <v-text-field
       @click:clear="returnsGroupListWhenCleared"
       @keyup="returnsGroupListWhenNull"
       class="mt-2 mb-2"
       dense
       clearable
       hide-details
       outlined
       v-model="search"
       placeholder="Search Group"
       prepend-inner-icon="mdi-magnify"
       v-on:keyup.enter="filterList"
   ></v-text-field>


            <v-divider></v-divider>


          <div style="height: 77vh;">

             <v-progress-linear
                 v-if="groupLoader"
                 indeterminate
                 color="#242f7e"
                 class="mb-0"
                 height="2"
             ></v-progress-linear>

                <v-virtual-scroll
                    :items="groupList"
                    item-height="61"
                    @scroll="onScroll"
                >
              <template v-slot:default="{ item }">

                <v-list  subheader>
                  <v-list-item-group
                      :v-model="item.number"
                  >
                    <v-list-item
                        style="border-bottom: 0.5px solid #f1f1f1;"
                        @click.prevent="selectUserContact(item)"
                        link>

                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-account-multiple-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <small class="text-truncate" v-text="item.description"></small>
                      </v-list-item-content>

                        <v-list-item-action>


                         <v-menu
                             bottom
                             left
                             open-on-hover

                         >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                       <v-card width="180">
                          <v-list dense>
                            <v-list-item @click="editGroup(item)">
                              <small>Edit Group</small>
                            </v-list-item>
                          </v-list>
                        </v-card>
                        </v-menu>

                      </v-list-item-action>

                    </v-list-item>

                  </v-list-item-group>

                </v-list>

              </template>
            </v-virtual-scroll>

  </div>



<v-dialog
    transition="dialog-bottom-transition"
    v-model="editGroupDialog"
    width="42%"
>

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit Group</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editGroupDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-group :groupInfo="groupInfo" ref="edit_group_component"></new-group>

         </v-card>


      </v-card>
    </v-dialog>



</span>
</template>

<script>
import NewGroup from "@/views/Main/Groups/NewGroup";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GroupList",
  components: {NewGroup},
  data(){
    return{
      search:"",
      groupList:[],
      groupLoader:false,
      items_per_page: 14,
      page: 1,
      groupContactDialog:false,
      editGroupDialog:false,
      groupInfo:{}
    }
  },
  mounted() {
    if(this.$route.query.q){
      this.search = this.$route.query.q
    }
    this.fetchListContacts()
  },
  methods:{
    ...mapActions(
        [
          "fetchGroups",
          "groupMessages",
          "fetchGroupProfile"
        ]
    ),
    selectUserContact(item){
      if(item){
        this.$emit("groupNumber", item);
      }
    },
    filterGroupData(data){
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.groupLoader = false
      let jsn = {};
      // let arr = [];

      dataObj.forEach(list =>{
        jsn = {
          title: list.grp_name,
          description: list.grp_desc,
          number: list.number,
        }
        // arr.push(jsn)

        const index = this.groupList.findIndex(object => object.title === this.groupList.title);

        if (index === -1) {
          this.groupList.push(jsn);
        }

      })


      // this.groupList = arr;
    },
    filterList(){
      this.groupLoader = true
      this.groupList = []
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchGroups(options)
    },
    fetchListContacts(){
      this.groupLoader = true
      this.groupList = []
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchGroups(options)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.contactLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
        }
        this.fetchGroups(options)

      }
    },
    editGroup(item){
      this.editGroupDialog = true
      this.groupInfo = item
    },
    returnsGroupListWhenNull(){
      if(!this.search){
        this.filterList()
      }
    },
    returnsGroupListWhenCleared(){
      this.search = ""
      this.filterList()
    }
  },
  computed:{
    ...mapGetters(
        [
          "getGroupListFile",
          "getUpdateGroupInfo"
        ]
    )
  },
  watch:{
    getGroupListFile(data){
      if(data){
        this.filterGroupData(data)

        //if no contact is selected then with use the first contact to display its data

            if(this.groupList.length > 0) {
              this.$emit("groupNumber", this.groupList[0]);
            }



      }
    },
    getUpdateGroupInfo(data){
      if(data){
        this.editGroupDialog = false
        this.$refs["edit_group_component"].spinner = false
        this.$refs["edit_group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.fetchListContacts()
        this.successToast("Success", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>