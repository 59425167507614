<template>
<span>

    <v-card
        tile
        class="mx-auto"
    >
            <v-toolbar dense flat>

              <v-badge
                  bordered
                  bottom
                  color="green"
                  dot
                  offset-x="11"
                  offset-y="13"
              >
                <v-avatar size="30" elevation="10">
<!--                  <img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" />-->
                   <v-icon large>
                            mdi-account-circle
                        </v-icon>
                </v-avatar>
              </v-badge>
              <span style="font-size: 14px;" class="ml-2">
                <div v-if="name"> {{name}} <br /></div>
                <div  v-if="selectedPhone">{{ selectedPhone }}</div>
              </span>



            </v-toolbar>

            <v-divider></v-divider>

       <v-progress-linear
           v-if="messageListLoader"
           indeterminate
           color="#242f7e"
           class="mb-0"
           height="2"
       ></v-progress-linear>

            <v-card
                v-chat-scroll="{ enable: false }"
                class="mx-auto messageChartDesign"
                tile
                color="#e5e5e5"
                :height="!showSender ? '70vh' :'66vh'"
                @scroll="scrollList"
                flat>



              <div class="mt-5" v-for="(chartData, index) in chartList" :key="index">

               <div
                   v-if="chartData.msgDate"
                   style="text-align: center;"
                   class="mb-5 mx-auto">
                   <v-chip
                       color="indigo darken-3"
                       outlined
                       small
                   >
                  {{chartData.msgDate}}
                </v-chip>
                </div>


              <!--        right chart    send    -->
              <v-row v-if="chartData.type === 'Sent'"  class="mb-1 mx-auto">
                <v-spacer></v-spacer>
                <v-card flat class="mr-2 mt-1" width="70%" color="#a9ec76" >
                  <v-list-item>
                    <v-list-item-content>
                      <div style="width: 100%;" class="mb-4">
                       {{chartData.message}}
                      </div>
                      <small >{{chartData.msg_status+" | "+chartData.seen}}</small>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-row>

                <!--  left chart      receive    -->
              <v-row v-if="chartData.type === 'Received'"  class="mb-1 mx-auto">
                <v-card flat class="ml-2 mt-1" max-width="370px">
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div style="width: 100%;" class=" mb-4">
                       {{chartData.message}}
                      </div>
                       <small>{{chartData.msg_status+" | "+chartData.seen}}</small>
                    </v-list-item-content>
                  </v-list-item>
                </v-card >

              </v-row>
              </div>
            </v-card>


      <!-- chart box   -->


  <div  style="background-color: #f6f4f4;">

    <v-row v-if="showSender" class="mx-auto pt-3">
      <v-col cols="4">
         <v-select
             v-model="senderPhone"
             label="Sender:"
             :items="senderList"
             item-value="id"
             dense
             hide-details
         ></v-select>
      </v-col>
      <v-col cols="6">
           <div class="ml-5">
      <small v-if="errorMessageDevicePhone" style="color: red">{{errorMessageDevicePhone}}</small>
      <small v-if="successMessageDevicePhone" style="color: green">{{successMessageDevicePhone}}</small>
    </div>
      </v-col>
       <v-col cols="2">
         <small class="float-right mt-2"><span :style="message.length > 479 ? 'color:red;' : ''">{{message.length}}</span>/479 </small>
      </v-col>
    </v-row>




    <div class="mx-auto" style="width: 98%;">
      <v-row class="mx-auto pt-2 mb-1" align="end">
  <v-textarea
      ref="text_component"
      :loading="messageLoader"
      style="width: 57%; font-size: 13px;"
      rows="2"
      no-resize
      class="mx-auto"
      v-model="message"
      outlined
      maxlength="480"
      placeholder="Add your message..."
      type="text"
      :rules="message_rules"
      hide-details
  ></v-textarea>



          <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              v-if="message.length <= 479"
              :loading="loaderSms"
              style="border: 0; bottom: 0"
              outlined
              small
              fab
              color="#717070"
              @click="sendMessage"
          >
      <v-icon>mdi-send</v-icon>
    </v-btn>



      </template>
      <span>Send Message</span>
    </v-tooltip>


</v-row>

    </div>



<v-row class="mx-auto mt-1 pb-5">

  <small style="font-size: 12px; color: #525050;" class="ml-5">
  Tags:
  <span class="tags" style="cursor: pointer;" @click="addTag('{SURNAME}')">{SURNAME}</span>
  <span class="tags" style="cursor: pointer;" @click="addTag('{SECOND_NAME}')">  {SECOND_NAME}</span>
  <span class="tags" style="cursor: pointer;" @click="addTag('{OTHER_NAMES}')"> {OTHER_NAMES}</span>
  <span class="tags" style="cursor: pointer;" @click="addTag('{FULL_NAME}')"> {FULL_NAME}</span>
  <span class="tags" style="cursor: pointer;" @click="addTag('{CONTACTS}')"> {CONTACTS}</span>
  <span class="tags" style="cursor: pointer;" @click="addTag('{PHONE}')">{PHONE}</span>
</small>
  </v-row>

  </div>

          </v-card>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MessengerCharts",
  data(){
    return{
      chartList: [],
      message: '',
      name:null,
      senderList:[],
      senderPhone:null,
      showSender:false,
      errorMessageDevicePhone:null,
      messageLoader:false,
      successMessageDevicePhone:null,
      messageListLoader:false,
      items_per_page: 11,
      page: 1,
      message_rules: [v => v.length <= 479 || 'Max 479 characters'],
      loaderSms:false,
      messageType:null,
      contactGroupNumber:null,
      selectedPhone:null
    }
  },
  mounted() {
    this.messengerCharts()
    this.scroll()
  },
  methods: {
    ...mapActions(
        [
            "contactMessageList",
            "groupMessages",
            "sendGroupMessage",
            "sendContactMessage",
            "connectedCompanyPhones"
        ]
    ),
    addTag(data){

      const insertText = data

      if (!insertText.length) return

      const textarea = this.$refs.text_component.$refs.input
      const sentence = textarea.value

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.message = before +" "+ insertText+" " + after

    },
    messengerCharts(){

      this.chartList = []
       this.messageListLoader = true
      let options = {
        page: 1,
        per_page: 11,
        contact_number: this.contactGroupNumber,
        search: this.search,
      }


     if(this.$route.query.type === "con"){

       if(this.contactGroupNumber) {
         this.contactMessageList(options)
       }

        //this.showSender = true
       this.connectedCompanyPhones()
     }else if(this.$route.query.type === "grp"){

        this.groupMessages(options)
       // this.showSender = false
      }
    },
    sendMessage () {
      let message = this.message

      if(message.length > 479){
        return;
      }

      this.errorMessageDevicePhone = ""
      this.successMessageDevicePhone = ""


      if(this.messageType === "personal"){
        if(this.senderPhone === null){
          this.errorMessageDevicePhone = "Please select sender phone"
          return
        }
        this.messageLoader = true

        this.loaderSms = true

        this.sendContactMessage({
          message:message,
          contactReceiverId:this.contactGroupNumber,
          contactSenderId:this.senderPhone
        } )
      }else if(this.messageType === "group"){
        this.messageLoader = true

        this.loaderSms = true

        this.sendGroupMessage({
          message:message,
          groupId:this.contactGroupNumber
        } )
      }



    },
    filterGroupMessages(data){
      this.messageListLoader = false;
      if(data.data) {
        if (data.page === 1) {
          this.chartList = []
        }

        let dataObj = data.data;

        let pageNumber = (data.page * 1)
        this.page = dataObj.length === 0 ? (pageNumber - 1) : pageNumber;

        this.items_per_page = data.per_page;


        let jsn = {}
        let arr = []
        let dateMsg = ""
        let printDate = ""
        dataObj.forEach(list => {
          let timeData = (new Date(list.created_at))

          let timeInfo = new Intl.DateTimeFormat('default',
              {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric'
              }).format(timeData);

          printDate = ""

          // let _dateMsg = ""
          let dataFormat = new Date(list.created_at).toDateString()

          if (dateMsg !== dataFormat) {
            dateMsg = dataFormat;
            printDate = dataFormat;
          }

          jsn = {
            number: list.number,
            message: list.message,
            seen: timeInfo,
            msgDate: printDate,
            type: 'Sent',
            msg_status: list.status,
          }


          const index = this.chartList.findIndex(object => object.number === jsn.number);

          if (index === -1) {
            //  this.chartList.push(jsn);
            arr.push(jsn)
          }

        })

        this.chartList = [...arr, ...this.chartList]

        //this.chartList = arr
      }

    },
    filterContactMessages(data){
      this.messageListLoader = false;
      if(data.data) {
        if (data.page === 1) {
          this.chartList = []
        }

        let dataObj = data.data;

        let pageNumber = (data.page * 1)
        this.page = dataObj.length === 0 ? (pageNumber - 1) : pageNumber;


        this.items_per_page = data.per_page;


        // this.chartList = []


        let jsn = {}
        let arr = []
        let dateMsg = ""
        let printDate = ""
        dataObj.forEach(list => {

          let timeData = (new Date(list.created_at))

          let timeInfo = new Intl.DateTimeFormat('default',
              {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric'
              }).format(timeData);

          printDate = ""

          // let _dateMsg = ""
          let dataFormat = new Date(list.created_at).toDateString()

          if (dateMsg !== dataFormat) {
            dateMsg = dataFormat;
            printDate = dataFormat;
          }

          jsn = {
            number: list.number,
            message: list.msg_text,
            seen: timeInfo,
            type: list.msg_type,
            msgDate: printDate,
            msg_status: list.msg_status,
          }


          const index = this.chartList.findIndex(object => object.number === jsn.number);

          if (index === -1) {
            arr.push(jsn)

          }

        })


        this.chartList = [...arr, ...this.chartList]  //this.chartList.concat(arr)

        // this.chartList.push(arr);
        // this.chartList = arr
      }

    },
    devicePhoneFilter(dataList) {
      let dataObj = [];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.DevicePhone.phone_number+" [ "+list.DevicePhone.ConnectedDevice.device_desc+" ]",
          id: list.DevicePhone.number
        };
        dataObj.push(dataJson);
      })


      return dataObj;
    },


   scrollList({ target: { scrollTop }}){
     if(scrollTop === 0) {

       this.messageListLoader = true
       let options = {
         page: (this.page) + 1,
         per_page: this.items_per_page,
         contact_number: this.contactGroupNumber,
         search: this.search,
       }


       if(this.messageType === "personal"){
         this.contactMessageList(options)
         this.showSender = true
       }else if(this.messageType === "group"){
         this.groupMessages(options)
         this.showSender = false
       }


     }
   }

  },
  computed:{
    ...mapGetters(
        [
            "getMessageError",
            "getContactMessages",
            "getGroupMessages",
            "getNewGroupMessages",
            "getNewContactMessages",
            "getContactProfile",
            "getGroupProfile",
            "getCompanyConnectedPhone"
        ]
    ),

  },
  watch:{
    getContactMessages(data){
      if(data){
        this.filterContactMessages(data)
      }
    },
    getGroupMessages(data){
      if(data){
        this.filterGroupMessages(data)
      }
    },
    getMessageError(data){
      if(data){
        this.loaderSms = false
        this.messageLoader = false
        this.errorMessageDevicePhone =  data.message
        this.errorToast("Error", data.message)
      }
    },
    getNewGroupMessages(data){
      if(data){
        this.message = ""
        this.messageLoader = false
        this.loaderSms = false
        this.successMessageDevicePhone =  data.message
        this.messengerCharts()
        //this.groupMessages(this.$route.query.id)
        this.successToast("Success", data.message)
      }
    },
    getNewContactMessages(data){
      if(data){
        this.message = ""
        this.messageLoader = false
        this.loaderSms = false
        this.successMessageDevicePhone =  data.message
       // this.contactMessages(this.$route.query.id)
        this.messengerCharts()
        this.successToast("Success", data.message)
      }
    },
    getContactProfile(data){
      if(data){
        this.showSender = true
        let profileData = data.data
        this.messageType = "personal"
        this.contactGroupNumber = profileData.Contact.number
        this.name = profileData.User.surname+" "+profileData.User.second_name+" "+profileData.User.other_names
        this.selectedPhone =  profileData.Contact.contact
      }
    },
    getGroupProfile(data){
      if(data){
        this.showSender = false
        let groupData = data.data
        this.name = groupData.grp_name
        this.messageType = "group"
        this.selectedPhone = ""
        this.contactGroupNumber = groupData.number
      }
    },
    getCompanyConnectedPhone(data){
      if(data){
       this.senderList = this.devicePhoneFilter(data.data)
      }
    }
  }
}
</script>

<style scoped>

/*.border {
  border-right: 1px solid grey;
}*/
.messageChartDesign{
  overflow: scroll;
  /*display: flex;*/
  /*flex-direction: column-reverse;*/
  /*border: 1px solid black;*/
}

.tags:hover{
  color: green;
}




</style>