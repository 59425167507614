<template>
<div>

  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="3" lg="3">

        <v-card
            tile
            class="mx-auto"
        >

          <v-toolbar dense flat>
            <v-toolbar-title>Groups</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                dark
                color="teal"
                small
                @click="doAddNewGroup"
            >
              New Group
            </v-btn>
          </v-toolbar>

          <v-divider></v-divider>
          <group-list ref="group_list_component" v-on:groupNumber="groupNumberInfo"></group-list>

        </v-card>


      </v-col>
      <v-col cols="12" sm="12" md="9" lg="9" >

        <group-contact-manager :groupInfo="groupInfo"></group-contact-manager>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog
      transition="dialog-bottom-transition"
      v-model="groupDialog"
      width="42%"
  >

    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>New Group</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon
            color="red"
            @click="groupDialog = false"
        >
          mdi-close
        </v-icon>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

        <new-group ref="group_component"></new-group>

      </v-card>


    </v-card>
  </v-dialog>

</div>
</template>

<script>
import GroupList from "@/views/Main/Groups/GroupList";
import GroupContactManager from "@/views/Main/Groups/GroupContactManager";
import NewGroup from "@/views/Main/Groups/NewGroup";
import {mapGetters} from "vuex";
export default {
  name: "GroupContactFrame",
  components: {NewGroup, GroupContactManager, GroupList},
  data(){
    return{
      groupInfo:null,
      groupDialog:null
    }
  },
  methods:{
    doAddNewGroup(){
      this.groupDialog = true
    },
    groupNumberInfo(data){
      if(data){
       this.groupInfo = data
      }
    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewGroupFile"
        ]
    )
  },
  watch:{
    getNewGroupFile(data){
      if(data){
        this.successToast("Success", data.message)
        this.groupDialog = false
        this.$refs.group_component.spinner = false
        this.$refs.group_component.form = {
                                        group_name:null,
                                        grp_desc:null,
                                      }
       this.$refs.group_list_component.fetchListContacts()
      }
    }
  }
}
</script>

<style scoped>

</style>