import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    contactProfile: null,
    groupProfile: null,
    contactProfileError: null,
};

const getters = {
    getContactProfile: state => state.contactProfile,
    getGroupProfile: state => state.groupProfile,
    getContactProfileError: state => state.contactProfileError,
};

const actions = {
    async fetchContactProfile({commit}, data) {
        await axios
            .get(IP + "message/contact-profile/"+data)
            .then(response => {
                commit("setContactProfile", response);
            })
            .catch(error => {
                commit("setContactProfileError", error);
            });
    },
    async fetchGroupProfile({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let group_number = data?data.group_number ? data.group_number: "":""
        await axios
            .get(IP
                + "message/group-profile?page="+data.page+
                "&per_page="+ data.per_page+
                "&group_number="+group_number+
                "&search="+search
            )
            .then(response => {
                commit("setGroupProfile", response);
            })
            .catch(error => {
                commit("setContactProfileError", error);
            });
    },


};

const mutations = {
    setContactProfile: (state, response) => {
        state.contactProfile = response.data;
    },
    setGroupProfile: (state, response) => {
        state.groupProfile = response.data;
    },
    setContactProfileError: (state, error) => {
        state.contactProfileError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

