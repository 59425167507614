<template>
<span>


<!--  <div style="max-height: 70vh;">-->

             <v-progress-linear
                 v-if="messageLoader"
                 indeterminate
                 color="#242f7e"
                 class="mb-0"
                 height="2"
             ></v-progress-linear>


<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>
     <v-list  subheader>
                  <v-list-item-group
                     v-for="(item, index) in messageList" :key="index"
                  >
                    <v-list-item
                        style="border-bottom: 0.5px solid #f1f1f1;"
                        @click.prevent="selectReceiverMessage(item)"
                        link>

                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-account-circle
                        </v-icon>
                      </v-list-item-avatar>


                      <v-list-item-content>


    <v-tooltip max-width="390" color="black" bottom>
      <template v-slot:activator="{ on, attrs }">


                <v-list-item-title v-bind="attrs" v-on="on" style="font-weight: bold;" v-if="item.user_name" v-text="item.user_name"></v-list-item-title>
                        <small class="text-truncate" style="font-weight: bold; color: #676666;" v-if="item.receiver_phone" v-text="item.receiver_phone"></small>
                        <small v-bind="attrs" v-on="on" class="text-truncate" v-if="item.description"  v-text="item.description"></small>
                        <small  class="text-truncate" style="color: #1976d2;" v-if="item.date"  v-text="item.date"></small>


      </template>
      <div>{{item.description}}</div>
    </v-tooltip>



                      </v-list-item-content>


                        <v-list-item-action>


                      </v-list-item-action>

                    </v-list-item>

                  </v-list-item-group>

                </v-list>
</v-card>



<!--  </div>-->

</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/Global/router";

export default {
  name: "ReceivedMessages",
  data(){
    return{
      messageLoader:false,
      items_per_page: 11,
      page: 1,
      messageList:[],
    }
  },
  mounted() {
    this.fetchReceivedMessageList()
  },
  methods:{
    ...mapActions(
        [
            "fetchReceivedMessages"
        ]
    ),
    fetchReceivedMessageList(){
      this.messageLoader = true
      this.messageList = []
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
      }
      this.fetchReceivedMessages(options)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.contactLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
        }
        this.fetchReceivedMessages(options)

      }
    },
    filterMessageData(data){
      if(data.data) {
        let dataObj = data.data;
        let current_page = (data.page * 1);
        this.page = dataObj.length === 0 ? (current_page - 1) : current_page;

        let per_page = data.per_page;
        this.items_per_page = (per_page * 1);

        this.messageLoader = false

        let jsn = {};

        // let arr = []


        dataObj.forEach(list => {
          jsn = {
            id: list.number,
            user_name: list.user_name ? list.user_name : "",
            receiver_phone: list.msg_receiver_phone ? list.msg_receiver_phone : "",
            description: list.msg_text ? list.msg_text : "",
            number: list.receiver_id_number ? list.receiver_id_number : "",
            date: list.updated_at ? this.timeAgo(new Date(list.updated_at)) : "",
          }

          const index = this.messageList.findIndex(object => object.id === this.messageList.id);

          if (index === -1) {
            this.messageList.push(jsn);

          }

        })
      }

    },
    selectReceiverMessage(item){
      router.push({name:'messenger', query: {type:'con', id:item.number, q: (item.receiver_phone).replace('+', ''), rv:item.id }})
    }
  },
  computed:{
    ...mapGetters(
        [
            "getReceivedMessages"
        ]
    )
  },
  watch:{
    getReceivedMessages(data){
      if(data){
        this.filterMessageData(data)
      }
    },

  }
}
</script>

<style scoped>

</style>