<template>
<span>
       <v-row class="mx-auto mt-5 mb-1">
      <v-col cols="12">
        <v-text-field
            @click:clear="clearSearchGroupContactData"
            @keyup="returnsListWhenNull"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            clearable
            outlined
            hide-details
            v-on:keyup.enter="searchGroupContactData"
        ></v-text-field>
      </v-col>
    </v-row>

   <v-row class="mx-auto">
      <v-col cols="12">
          <small class="float-right">{{totalContacts}} Contacts</small>
      </v-col>
   </v-row>

    <v-progress-linear
        v-if="contactLoader"
        indeterminate
        color="#242f7e"
        class="mb-0"
        height="2"
    ></v-progress-linear>

  <v-card
      tile
      flat
      @scroll="onScroll"
      max-height="510px"
      style="width: 100%; overflow: scroll; border-top: 1px solid #efefef;"
  >


             <v-simple-table

             >
                <template v-slot:default>
                  <tbody>
                    <tr
                        v-for="item in contactList"
                        :key="item.number"
                    >
                      <td>



<v-row class="py-3">
        <v-list-item-avatar>
         <v-icon large>
                            mdi-account-circle
                        </v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>

          <v-list-item-subtitle v-text="item.phone"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action  class="mr-5">
                <v-checkbox
                    v-model="item.status"
                    :value="item.status"
                    hide-details
                    dense
                    @change="addGroupContact(item)"
                ></v-checkbox>
        </v-list-item-action>
</v-row>







                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "RemainingContacts",
  props:{
    groupInfo:{}
  },
  data(){
    return{
      search: null,
      tableLoader: false,
      generalTableData: [],
      contactList: [],
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "10%",
          value: "key"
        },
        {text: "Name", value: "name"},
        {text: "Phone", value: "phone"},
        {text: "Action", value: "actions", width: "10%"}
      ],
      groupNumber:null,
      selectedPhone:[],
      selectedPhoneList:[],
      form:{
        groupNumber:null,
        contactList:[],
      },
      page: 1,
      items_per_page: 16,
      contactLoader:false,
      totalContacts:null,
    }
  },
  mounted() {
    this.checkGroup()
  },
  methods:{
    ...mapActions(
        [
          "fetchGroupContacts",
          "doAddGroupContacts"
        ]
    ),
    checkGroup(){
      this.contactList = []
      this.contactLoader = true
      this.groupNumber = this.groupInfo.number
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        group_number: this.groupInfo.number,
      }
      this.fetchGroupContacts(options)
    },
    filterGroupContact(data){

      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;
      this.totalContacts = data.total_contact;


      this.contactLoader = false;


      let jsn = {};
      dataObj.forEach(list => {
        jsn = {
          title: list.User.surname + " " + list.User.second_name + " " + list.User.other_names,
          phone: list.contact,
          number: list.number,
          contact_number: list.contact_number,
          status: false,
        }

        const index = this.contactList.findIndex(object => object.number === jsn.number);

        if (index === -1) {
          this.contactList.push(jsn);
        }

      })


    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.contactLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
          group_number: this.groupInfo.number,
        }
        this.fetchGroupContacts(options)
      }
    },
    clearSearchGroupContactData(){
      this.search = ""
      this.searchGroupContactData()
    },
    returnsListWhenNull(){
      if(!this.search){
        this.searchGroupContactData()
      }
    },
    searchGroupContactData(){
      this.contactList = []
      //this.selectedPhone = []
      this.contactLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        group_number: this.groupInfo.number,
      }
      this.fetchGroupContacts(options)
    },
    addGroupContact(item){
      let data = {
        contactNumber:item.number,
        groupNumber:this.groupNumber
      }
      //alert(JSON.stringify(data))
      this.doAddGroupContacts(data)

    }


  },
  computed:{
    ...mapGetters(
        [
          "getGroupContactList",
           "getNewContactGroupList"
        ]
    )
  },
  watch:{
    getGroupContactList(data){
      if(data){
        this.filterGroupContact(data)
      }
    },
    groupInfo(){
      this.checkGroup()
    },
    getNewContactGroupList(data){
      if(data){
        this.successToast("Success", data.message)
        this.searchGroupContactData()
      }
    }

  }
}
</script>

<style scoped>

</style>