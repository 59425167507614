<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
     <v-form @submit.prevent="registerUser">

        <v-card-text>

          <v-text-field
              outlined
              v-model="form.surname"
              label="Surname"
              required
              prepend-inner-icon="mdi-account"
              type="text"
              dense
          ></v-text-field>


           <v-text-field
               outlined
               v-model="form.second_name"
               label="Second Name"
               required
               prepend-inner-icon="mdi-account"
               type="text"
               dense
           ></v-text-field>

           <v-text-field
               outlined
               v-model="form.other_names"
               label="Other Names"
               required
               prepend-inner-icon="mdi-account"
               type="text"
               dense
           ></v-text-field>


          <v-select
              v-model="form.gender"
              label="Gender"
              :items="genderList"
              item-value="id"
              outlined
              required
              prepend-inner-icon="mdi-gender-male-female"
              dense
          ></v-select>

          <div
              v-for="(mail, index) in form.email"
              :key="index+'1'"
          >
          <v-text-field
              hide-details
              class="mb-5"
              outlined
              v-model="mail.mail"
              :label="'Email '+(index+1)"
              required
              prepend-inner-icon="mdi-email-outline"
              type="email"
              dense
              :append-outer-icon="!mail.number ? 'mdi-minus' : ''"
              @click:append-outer="removeEmail(index)"
          ></v-text-field>
          </div>

<v-row class="mx-auto">
  <v-spacer></v-spacer>
            <v-btn
                class="mb-5"
                color="#01a59a"
                dark
                type="button"
                small
                outlined
                @click="addEmail()"
            >
              <v-icon dark>mdi-plus</v-icon>
             Add Email
            </v-btn>
</v-row>

  <div
      v-for="(phoneNo, index2) in form.phone"
      :key="index2+'2'"
  >
          <v-text-field
              @keypress="onlyNumberValidator($event)"
              maxlength="12"
              class="mb-5"
              hide-details
              outlined
              v-model="phoneNo.phoneNumber"
              :label="'Phone '+(index2+1)"
              required
              prepend-inner-icon="mdi-phone"
              type="tel"
              dense
              :append-outer-icon="index2 > 0 && !phoneNo.number ? 'mdi-minus' : ''"
              @click:append-outer="removePhone(index2)"
          ></v-text-field>
  </div>

          <v-row class="mx-auto">
  <v-spacer></v-spacer>
             <v-btn
                 class="mb-5"
                 color="#01a59a"
                 dark
                 type="button"
                 small
                 outlined
                 @click="addPhone()"
             >
              <v-icon dark>mdi-plus</v-icon>
             Add Phone
            </v-btn>
          </v-row>

 <v-divider class="mt-4 mb-3"></v-divider>

             <v-btn
                 class="mb-2"
                 color="indigo"
                 dark
                 type="button"
                 small
                 outlined
                 @click="addCustomField()"
             >
              <v-icon dark>mdi-plus</v-icon>
             Add Field
            </v-btn>

<!--
            <v-simple-table v-if="form.customFields.length > 0" class="table" dense style="width: 100%; border-top: 1px solid #eeebeb; border-bottom: 1px solid #eeebeb; margin-bottom: 3%;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Type</th>
                      <th class="text-left">Label</th>
                      <th class="text-left" colspan="2">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="(list, key) in form.customFields"
                        v-bind:key="key"
                    >

                        <td style="border-right: 1px solid #e0e0e0; width: 24%;">
                            <v-select
                                v-model="list.type"
                                :items="typeList"
                                item-value="id"
                                flat
                                solo
                                required
                                hide-details
                                dense
                            ></v-select>
                      </td>

                       <td style="border-right: 1px solid #e0e0e0; width: 24%;">
                        <v-text-field
                            v-model="list.name"
                            flat
                            solo
                            dense
                            type="text"
                            hide-details
                        ></v-text-field>
                      </td>

                      <td style="border-right: 1px solid #e0e0e0; width: 24%;">
                        <v-text-field
                            v-model="list.value"
                            flat
                            solo
                            dense
                            type="text"
                            hide-details
                        ></v-text-field>
                      </td>

                       <td width="1%">
                        <v-icon
                            @click="removeItem(key)"
                            style="font-size: 20px;"
                        >mdi-close</v-icon
                        >
                      </td>

                    </tr>
                  </tbody>
                </template>
      </v-simple-table>
-->



          <v-divider class="mt-3 mb-3"></v-divider>

          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <v-btn
                class="mt-5"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
          {{userInfo ? "Edit User" : "Save User"}}
            </v-btn>
          </v-row>


        </v-card-text>
      </v-form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewUser",
  props:{
    userInfo:{}
  },
  data() {
    return {
      show1: false,
      spinner:false,
      userId:null,
      form: {
        surname: null,
        second_name: null,
        other_names: null,
        email: [
          {mail:null, number: null}
        ],
        phone: [
          {phoneNumber:null, number: null}
        ],
        gender: null,
        customFields:[]
      },
      errorMessage:null,
      genderList:[
        {text:"Male", id:"Male"},
        {text:"Female", id:"Female"}
      ],
      typeList:[
        {text:"Text", id:"text"},
        {text:"Text Area", id:"text-area"}
      ]
    }
  },
  mounted() {
    this.checkUserDetails()
  },
  methods:{
    ...mapActions(
        [
            "registerNewInnerUser",
            "fetchUserInfo",
            "registerEditUser"
        ]
    ),
    registerUser(){
      this.spinner = true
      this.errorMessage = ""
      let formData = this.form
      if(this.userId){
        formData.userId = this.userId
        this.registerEditUser(formData)
      }else {
        this.registerNewInnerUser(formData)
      }
    },
    checkUserDetails(){
      if(this.userInfo){
        this.fetchUserInfo(this.userInfo.number)
      }
    },
    removePhone(index){
      this.form.phone.splice(index, 1)
    },
    removeEmail(index){
      this.form.email.splice(index, 1)
    },
    addEmail(){
      this.form.email.push({mail: null, number: null})
    },
    addPhone(){
      this.form.phone.push({phoneNumber: null, number: null})
    },
    addCustomField(){
      this.form.customFields.push({type: null, name: null, value: null})
    },
    removeItem(key){
      this.form.customFields.splice(key, 1)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewUserError",
            "getUserProfileInfo",
        ]
    )
  },
  watch:{
    getNewUserError(data){
      if(data){
        this.spinner = false
        this.errorToast("Error", data.message)
        this.errorMessage = data.message
      }
    },
    userInfo(){
      this.checkUserDetails()
    },
    getUserProfileInfo(data){
      if(data.data) {
        let dataInfo = data.data
        this.userId = dataInfo.number ? dataInfo.number : null
        this.form.surname = dataInfo.surname ? dataInfo.surname : null
        this.form.second_name = dataInfo.second_name ? dataInfo.second_name : null
        this.form.other_names = dataInfo.other_names ? dataInfo.other_names : null
        this.form.gender = dataInfo.gender ? dataInfo.gender : null

        if (dataInfo.Contacts.length > 0) {
          this.form.email = []
          dataInfo.Contacts.forEach(list => {
            if (list.type === 'Email') {
              this.form.email.push({mail: list.contact, number: list.number})
            }
          })

        this.form.phone = []
        dataInfo.Contacts.forEach(list => {
          if (list.type === 'Phone') {
            this.form.phone.push({phoneNumber: list.contact, number: list.number})
          }
        })
      }

      }
    },


  }
}
</script>

<style scoped>

</style>