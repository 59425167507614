<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="3">

          <v-card
              tile
              class="mx-auto"
          >

            <v-toolbar dense flat>
              <v-toolbar-title>Chat</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-menu
                  bottom
                  left
                  open-on-hover
                  offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-card width="180">
                <v-list dense>
                  <v-list-item @click="newItem('contact')">
                    <small>New Contact</small>
                  </v-list-item>
                  <v-list-item @click="newItem('group')">
                    <small>New Group</small>
                  </v-list-item>
                </v-list>
                </v-card>
              </v-menu>

            </v-toolbar>

<v-divider></v-divider>

            <v-tabs  v-model="activeTab" class="mt-3" color="#242f7e">

              <v-tabs-slider color="#242f7e"></v-tabs-slider>
              <v-tab @click="tabSelectedType('con')" href="#contacts">
               <small>Contacts</small>
              </v-tab>

              <v-tab @click="tabSelectedType('grp')" href="#groups">
                <small>Groups</small>
              </v-tab>

              <v-tab-item id="contacts">
                <v-divider></v-divider>
                <messenger-contacts ref="contact_list_component"></messenger-contacts>
              </v-tab-item>

              <v-tab-item id="groups">
                <v-divider></v-divider>
                  <messenger-groups ref="group_list_component"></messenger-groups>
              </v-tab-item>

            </v-tabs>

          </v-card>

           
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" >

          <messenger-charts></messenger-charts>

        </v-col>

        <v-col cols="12" sm="12" md="3" lg="3">

          <contact-profile></contact-profile>

        </v-col>
      </v-row>
    </v-container>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="contactDialog"
        width="42%"
    >

      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>New Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
              color="red"
              @click="contactDialog = false"
          >
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

          <new-contact ref="contact_component"></new-contact>

        </v-card>

      </v-card>
    </v-dialog>

    <v-dialog
        transition="dialog-bottom-transition"
        v-model="groupDialog"
        width="42%"
    >

      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>New Group</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
              color="red"
              @click="groupDialog = false"
          >
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

          <new-group ref="group_component"></new-group>

        </v-card>


      </v-card>
    </v-dialog>


  </div>
</template>
<script>
import MessengerContacts from "@/views/Messenger/MessengerContacts/MessengerContacts";
import MessengerGroups from "@/views/Messenger/MessengerGroups/MessengerGroups";
import ContactProfile from "@/views/Messenger/ContactProfile/ContactProfile";
import MessengerCharts from "@/views/Messenger/MessengerCharts/MessengerCharts";
import NewContact from "@/views/Main/contacts/NewContact";
import NewGroup from "@/views/Main/Groups/NewGroup";
import {mapGetters} from "vuex";
import router from "@/Global/router";
export default {
  name: "Messenger",
  components: {NewGroup, NewContact, MessengerCharts, ContactProfile, MessengerGroups, MessengerContacts},
  data(){
    return{
      activeTab:"contacts",
      contactDialog:false,
      groupDialog:false
    }
  },
  mounted() {
    this.activeTab = this.$route.query.type === "grp" ? 'groups' : 'contacts';
  },
  methods: {
    newItem(data){
      if(data === 'group'){
        this.groupDialog = true
      }else {
        this.contactDialog = true
      }
    },
    tabSelectedType(data){
      router.push({name:'messenger', query:{type:data}})
    }
    },
  computed: {
   ...mapGetters(
       [
           "getNewContact",
           "getNewGroupFile"
       ]
   )
  },
  watch:{
    getNewContact(data){
      if(data){
        this.successToast("Success", data.message)
        this.contactDialog = false
        this.$refs["contact_component"].spinner = false
        this.$refs["contact_component"].form = {
          surname:null,
          second_name:null,
          other_names:null,
          phone:null,
          gender:null,
        }
        this.$refs["contact_list_component"].fetchContacts()

      }
    },
    getNewGroupFile(data){
      if(data){
        this.successToast("Success", data.message)
        this.groupDialog = false
        this.$refs["group_component"].spinner = false
        this.$refs["group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.$refs["group_list_component"].fetchListContacts()
      }
    },

  }
  
};
</script>
<style scoped>



/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  /*background-color: blue;*/
  background: linear-gradient(to top, #33ccff 0%, #cc00ff 100%)
}


</style>
