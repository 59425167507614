import Vue from 'vue'
import Vuex from 'vuex'
import send_from_file_store from "@/views/Main/SendFromFile/vuex-store/send_from_file_store";
import register_store from "@/views/Auth/Register/vuex-store/register_store";
import login_store from "@/views/Auth/Login/vuex-store/login_store";
import contact_store from "@/views/Main/contacts/vuex-store/contact_store";
import group_store from "@/views/Main/Groups/vuex-store/group_store";
import contact_profile_store from "@/views/Messenger/ContactProfile/vuex-store/contact_profile_store";
import messenger_chart_store from "@/views/Messenger/MessengerCharts/vuex-store/messenger_chart_store";
import pair_store from "@/views/Messenger/PairPhone/vuex-store/pair_store";
import dashboard_store from "@/views/Main/vuex-store/dashboard_store";
import company_store from "@/views/Main/company/vuex-store/company_store";
import users_store from "@/views/Main/users/vuex-store/users_store";
import message_logs_store from "@/views/Main/messageLogs/vuex-store/message_logs_store";
import import_group_contacts_store from "@/views/Messenger/importcontacts/vuex-store/import_group_contacts_store";
import home_store from "@/views/Main/Home/vuex-store/home_store";
import call_logs_store from "@/views/Main/CallLogs/vuex-store/call_logs_store";
import roles_and_permissions_store from "@/views/Main/RolesAndPermissions/vuex-store/roles_and_permissions_store";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        send_from_file_store,
        register_store,
        login_store,
        contact_store,
        group_store,
        contact_profile_store,
        messenger_chart_store,
        pair_store,
        dashboard_store,
        company_store,
        users_store,
        message_logs_store,
        import_group_contacts_store,
        home_store,
        call_logs_store,
        roles_and_permissions_store
    }
})
