import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    newCompanyData: null,
    newCompanyList: null,
    companyDataInfo: null,
    editCompanyDataInfo: null,
    newCompanyError: null,
};

const getters = {
    getNewCompanyData: state => state.newCompanyData,
    getNewCompanyList: state => state.newCompanyList,
    getCompanyDataInfo: state => state.companyDataInfo,
    getEditCompanyDataInfo: state => state.editCompanyDataInfo,
    getNewCompanyError: state => state.newCompanyError,
};

const actions = {
    async createCompany({commit}, data) {
        await axios
            .post(IP + "message/save-new-company", data)
            .then(response => {
                commit("setNewCompanyData", response);
            })
            .catch(error => {
                commit("setNewCompanyError", error);
            });
    },
    async fetchCompanyInfo({commit}, data) {
        await axios
            .get(IP + "message/company-profile/"+data)
            .then(response => {
                commit("setCompanyDataInfo", response);
            })
            .catch(error => {
                commit("setNewCompanyError", error);
            });
    },
    async editCompanyInfo({commit}, data) {
        await axios
            .post(IP + "message/edit-company-profile", data)
            .then(response => {
                commit("setEditCompanyInfo", response);
            })
            .catch(error => {
                commit("setNewCompanyError", error);
            });
    },
    async fetchCompanyPaginated({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let userStatus = data?data.userStatus ? data.userStatus: "":""
        await axios
            .get(IP +
                "message/company-list-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                "&search="+search+
                "&userStatus="+userStatus
            )
            .then(response => {
                commit("setNewCompanyList", response);
            })
            .catch(error => {
                commit("setNewCompanyError", error);
            });
    },



};

const mutations = {
    setNewCompanyData: (state, response) => {
        state.newCompanyData = response.data;
    },
    setCompanyDataInfo: (state, response) => {
        state.companyDataInfo = response.data;
    },
    setEditCompanyInfo: (state, response) => {
        state.editCompanyDataInfo = response.data;
    },
    setNewCompanyList: (state, response) => {
        state.newCompanyList = response.data;
    },
    setNewCompanyError: (state, error) => {
        state.newCompanyError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

