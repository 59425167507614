<template>
<span>
   <v-card style="margin-top: 20%; box-shadow: 2px 2px  #01a59a;">

    <v-card-text>

      <div class="mt-5" style="text-align: center;">
         <span style="font-size: 25px;">Noregrets</span> <br />
      Sign In
      </div>

      <v-form ref="form" @submit.prevent="loginUser" lazy-validation>
        <v-alert type="error" v-if="loginError">
          {{ loginError }}
        </v-alert>
        <v-card-text>
          <v-text-field
              outlined
              v-model="form.email_phone"
              :rules="[rules.email_phone_required]"
              label="Email / Phone"
              required
              prepend-inner-icon="mdi-email-outline"
              type="text"
              dense
          ></v-text-field>

          <v-text-field
              outlined
              v-model="form.password"
              :rules="[rules.password_required]"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Password"
              required
              @click:append="show1 = !show1"
              dense
              prepend-inner-icon="mdi-lock-outline"
          >
          </v-text-field>


            <v-row class="mx-auto">
              <v-btn
                  class="mt-7 mb-7"
                  :loading="loginSpinner"
                  color="#01a59a"
                  dark
                  block
                  rounded
                  type="submit"
                  large
              >
                Login
              </v-btn>
            </v-row>


          <div style="text-align: center;" class="mb-3">

            <router-link
                style="color: #242f7e;"
                :to="{ name: 'reset-password' }"
                class="text-decoration-none"
            >Forgot Password?
            </router-link>
          </div>

            <div style="text-align: center;">
            New at Noregrets? &nbsp;
            <router-link
                style="color: #242f7e;"
                class="text-decoration-none"
                :to="{ name: 'register' }"
            >
              Get an account
            </router-link>
          </div>

           <div style="text-align: center;">

            <a
                target="_blank"
                style="color: #242f7e;"
                class="text-decoration-none"
                href="https://www.mediafire.com/file/787p6leda0jozt2/apprelease.apk/file"
            >
             Download mobile app
            </a>
          </div>



        </v-card-text>
      </v-form>
    </v-card-text>
  </v-card>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Login",
  data(){
    return{
      show1: false,
      loginError:null,
      form:{
        email_phone:null,
        password:null
      },
      rules: {
        password_required: value => !!value || "Password is Required.",
        email_phone_required: value => !!value || "Email or Phone is Required.",
      },
      loginSpinner:false,
    }
  },
  methods:{
    ...mapActions(
        [
            "doUserLogin"
        ]
    ),
    loginUser(){
      this.loginError = ""
      this.loginSpinner = true
      this.doUserLogin(this.form)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getUserLogin",
            "getUserLoginError"
        ]
    )
  },
  watch:{
    getUserLogin(data){
      if(data){
        this.successToast("Success", data.message)
        this.loginSpinner = false
        let r = this.$router.resolve({
          name: "home",
        });
        window.location.assign(r.href);
      }
    },
    getUserLoginError(data){
      if(data){
        this.errorToast("Error", data.message)
        this.loginError = data.message
        this.loginSpinner = false
      }
    }
  }
}
</script>

<style scoped>

</style>