<template>
<span>
   <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Pair Phones</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>

          <v-divider></v-divider>


           <v-row class="mx-auto">
   <v-col cols="12" lg="6" md="6" sm="12" class="mt-10">

     <pair-new-phone ref="pair_phone_component"></pair-new-phone>

     <qr-code-image :qrCodeNumber="qrCodeNumber"></qr-code-image>

   </v-col>
              <v-col cols="12" lg="6" md="6" sm="12" class="mt-5">
                <connected-devices></connected-devices>
              </v-col>
 </v-row>



        </v-card>
      </v-flex>
   </v-layout>



</span>
</template>

<script>
import PairNewPhone from "@/views/Messenger/PairPhone/PairNewPhone";
import QrCodeImage from "@/views/Messenger/PairPhone/QrCodeImage";
import {mapGetters} from "vuex";
import ConnectedDevices from "@/views/Messenger/PairPhone/ConnectedDevices";

export default {
  name: "PairPhone",
  components: {
    ConnectedDevices,
    QrCodeImage,
    PairNewPhone,
  },
  data() {
    return {
      qrCodeNumber:null
    }
  },
  computed:{
    ...mapGetters(
        [
          "getPairPhone"
        ]
    )
  },
  watch:{
    getPairPhone(data){
      if(data){
        this.$refs["pair_phone_component"].spinner = false
        this.$refs["pair_phone_component"].form = {
          sim_slot:null,
          phone_number:null,
        }
        this.qrCodeNumber = data.data
        this.successToast("Success", data.message)
      }
    },
  }
}
</script>

<style scoped>

</style>