var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('v-card',{staticStyle:{"width":"100%","overflow":"scroll","margin-bottom":"13px"},attrs:{"outlined":"","max-height":"640px"}},[_c('v-row',{staticClass:"mx-auto"},[_c('v-col',_vm._l((_vm.connectedDeviceData),function(device,index){return _c('v-card',{key:index,staticClass:"mb-5 mt-5 pa-4",attrs:{"outlined":""}},[_c('v-row',{staticClass:"mx-auto mb-2"},[_c('div',[_vm._v(" "+_vm._s(device.Device.device_desc)),_c('br'),_c('small',{staticStyle:{"color":"#1976d2"}},[_vm._v("Last Seen: "),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(device.Device.UpdatedAt))])])]),_c('v-spacer')],1),_c('v-divider'),_vm._l((device.DeviceInfo),function(deviceInfo,index){return _c('div',{key:index,staticClass:"ml-4 mb-3 mt-2"},[_c('v-row',{staticClass:"mx-auto mt-3 mb-1"},[_c('div',[_vm._v(" "+_vm._s(deviceInfo.DevicePhone.phone_number)+" "),_c('small',{staticClass:"ml-3"},[_c('i',[_vm._v("["+_vm._s(deviceInfo.DevicePhone.phone_sim)+"]")])]),_c('v-chip',{staticClass:"ml-3",attrs:{"outlined":"","small":"","color":deviceInfo.is_active ? 'green' : 'red',"label":""}},[_vm._v(" "+_vm._s(deviceInfo.is_active ? "Active" : "Inactive")+" ")]),(deviceInfo.is_default)?_c('v-chip',{staticClass:"ml-3",attrs:{"small":"","color":"primary","label":""}},[_vm._v(" Default sender phone ")]):_vm._e()],1),_c('v-spacer'),(_vm.deviceLoader === deviceInfo.number)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":"20","indeterminate":"","color":"#787878"}}):_vm._e(),_c('div',[(deviceInfo.is_default === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"20","color":"green"},on:{"click":function($event){return _vm.setDefaultSender(deviceInfo)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone-classic ")])]}}],null,true)},[_c('span',[_vm._v("Unset Default Sender Phone")])]):_vm._e(),(deviceInfo.is_default === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"20","color":"red"},on:{"click":function($event){return _vm.setDefaultSender(deviceInfo, index)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone-minus ")])]}}],null,true)},[_c('span',[_vm._v("Set Default Sender")])]):_vm._e(),(deviceInfo.is_active === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"20","color":"green"},on:{"click":function($event){return _vm.setEnableDisableNumber(deviceInfo, index)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-checkbox-marked-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Disable Number")])]):_vm._e(),(deviceInfo.is_active === 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"size":"20","color":"red"},on:{"click":function($event){return _vm.setEnableDisableNumber(deviceInfo)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v("Enable Number")])]):_vm._e()],1)],1),_c('small',[_vm._v("Paired by: "+_vm._s(deviceInfo.DevicePhone.User.surname + " " + deviceInfo.DevicePhone.User.second_name + " " + deviceInfo.DevicePhone.User.other_names))]),_c('br'),_c('small',[_vm._v("company: "+_vm._s(deviceInfo.CompanyProfile.company_name))]),_c('v-divider')],1)})],2)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }