import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP_IMPORT;

const state = {
    importGroupContact: null,
    importGroupContactError: null,
    processFileData: null,
};

const getters = {
    getImportGroupContact: state => state.importGroupContact,
    getProcessFileData: state => state.processFileData,
    getImportGroupContactError: state => state.importGroupContactError,
};

const actions = {
    async doImportUserContacts({commit}, data) {
        await axios
            .post(IP + "read-file", data)
            .then(response => {
                commit("setImportGroupContact", response);
            })
            .catch(error => {
                commit("setImportGroupContactError", error);
            });
    },
    async saveExcelMappings({commit}, data) {
        await axios
            .post(IP + "process-file", data)
            .then(response => {
                commit("setProcessFileData", response);
            })
            .catch(error => {
                commit("setImportGroupContactError", error);
            });
    },



};

const mutations = {
    setImportGroupContact: (state, response) => {
        state.importGroupContact = response.data;
    },
    setProcessFileData: (state, response) => {
        state.processFileData = response.data;
    },
    setImportGroupContactError: (state, error) => {
        state.importGroupContactError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

