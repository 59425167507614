import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    userLogin: null,
    userLoginError: null,
};

const getters = {
    getUserLogin: state => state.userLogin,
    getUserLoginError: state => state.userLoginError,
};

const actions = {
    async doUserLogin({commit}, data) {
        await axios
            .post(IP + "auth/login", data)
            .then(response => {
                commit("setUserLogin", response);
            })
            .catch(error => {
                commit("setUserLoginError", error);
            });
    },

};

const mutations = {
    setUserLogin: (state, response) => {
        let user = response.data;
        localStorage.setItem("userProfile", JSON.stringify(user.data));
        localStorage.setItem("companyProfile", JSON.stringify(user.data.CompanyProfile));
        localStorage.setItem("token", user.token);
        state.userLogin = user;
    },
    setUserLoginError: (state, error) => {
        state.userLoginError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

