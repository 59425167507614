<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
     <v-form @submit.prevent="registerCompany">

        <v-card-text>

          <v-text-field
              outlined
              v-model="form.company_name"
              label="Company Name"
              required
              type="text"
              dense
          ></v-text-field>



          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <v-btn
                class="mt-2"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
             {{companyInfo ? "Edit Company" : "Save Company"}}
            </v-btn>
          </v-row>


        </v-card-text>
      </v-form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewCompany",
  props:{
    companyInfo:{}
  },
  mounted() {
    this.checkCompanyInfo()
  },
  data(){
    return{
      spinner:false,
      errorMessage:null,
      form:{
        company_name:null
      },
      companyId:null,
    }
  },
  methods:{
    ...mapActions(
        [
            "createCompany",
            "fetchCompanyInfo",
            "editCompanyInfo"
        ]
    ),
    registerCompany(){
      this.spinner = true
      this.errorMessage = ""
      let formData = this.form
      if(this.companyId){
        formData.companyId = this.companyId
        this.editCompanyInfo(formData)
      }else{
        this.createCompany(formData)
      }
    },
    checkCompanyInfo(){
      this.fetchCompanyInfo(this.companyInfo.number)
    }
  },
  computed:{
...mapGetters(
    [
        "getNewCompanyError",
        "getCompanyDataInfo"
    ]
)
  },
  watch:{
    getNewCompanyError(data){
      if(data){
        this.spinner = false
        this.errorToast("Error", data.message)
      }
    },
    companyInfo(){
      this.checkCompanyInfo()
    },
    getCompanyDataInfo(data){
      if(data){
        let dataInfo = data.data
        this.form.company_name = dataInfo.company_name
        this.companyId = dataInfo.number
      }
    }
  }
}
</script>

<style scoped>

</style>