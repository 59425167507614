<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>

   <v-card-text>
    <v-row class="mx-auto">
            <v-col cols="12">
              <v-row>
                  <v-file-input
                      @click:clear="clearDataUpload"
                      @change="uploadExcelFile"
                      clearable
                      outlined
                      v-model="file"
                      dense
                      show-size
                      truncate-length="20"
                      prepend-icon=""
                      type="file"
                      label="Please upload Excel File"
                      prepend-inner-icon="mdi-paperclip"
                      required
                      accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  ></v-file-input>

<!--               <v-btn
                   v-if="showButton"
                   :loading="uploadLoader"
                   color="blue-grey"
                   class=" ml-2 white&#45;&#45;text"
                   @click="uploadExcelFile"
               >
      <v-icon dark>
        mdi-cloud-upload
      </v-icon>
                 Upload File
    </v-btn>-->
              </v-row>

               <p> Contacts will be read from sheet one of the file.<br />
                Make sure the first row has titles</p>
            </v-col>

          </v-row>
   </v-card-text>

     <v-form @submit.prevent="mapExcelFields">

        <v-card-text>

<span v-if="mapListing.length > 0">


          <p style="font-size: 20px;">Import to group (Optional)</p>


<v-row>
  <v-col cols="6">
   <div> Required Fields</div>
  </v-col>
  <v-col cols="6">
    <div>Fields in the uploaded file</div>
  </v-col>
</v-row>



          <div v-for="(list,index) in form.mappings" :key="index">
            <v-row>
              <v-col cols="6">
                    <v-text-field
                        disabled
                        outlined
                        v-model="list.field"
                        label=""
                        required
                        type="text"
                        dense
                    ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="list.index"
                    :items="mapListing"
                    item-value="id"
                    outlined
                    dense
                ></v-select>
              </v-col>
            </v-row>






          </div>


            <v-select
                v-model="form.group_id"
                label="Group"
                :items="groupList"
                item-value="id"
                outlined
                dense
                append-outer-icon="mdi-plus"
                @click:append-outer="addGroupData"
            ></v-select>


                    <v-text-field
                        outlined
                        v-model="form.notice_email"
                        label="Email to get notification"
                        type="email"
                        dense
                    ></v-text-field>




          <v-row class="mx-auto mb-3">
             <v-btn
                 @click="clearForm"
                 outlined
                 class="mt-2"
             >
             Clear
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="mt-2"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
              Add Contact
            </v-btn>
          </v-row>
</span>

        </v-card-text>
      </v-form>


   <v-dialog
       transition="dialog-bottom-transition"
       v-model="groupDialog"
       width="42%"
   >

      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>New Group</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
              color="red"
              @click="groupDialog = false"
          >
            mdi-close
          </v-icon>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

          <new-group ref="group_component"></new-group>

        </v-card>


      </v-card>
    </v-dialog>

  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewGroup from "@/views/Main/Groups/NewGroup";

export default {
  name: "ImportUi",
  components: {NewGroup},
  data(){
    return{
      errorMessage:null,
      uploadLoader:false,
      showButton:true,
      file:null,
        form:{
          fileName:null,
          group_id:"",
          notice_email:null,
          mappings  :[
                {
                  field:"surname",
                  index:-1
                },
                {
                  field:"second_name",
                  index:-1
                },
                {
                  field:"other_names",
                  index:-1
                },
                {
                  field:"gender",
                  index:-1
                },
                {
                  field:"phone_one",
                  index:-1
                },
                {
                  field:"phone_two",
                  index:-1
                },
                {
                  field:"email_one",
                  index:-1
                },
                {
                  field:"email_two",
                  index:-1
                }
              ],
      },
      groupList:[],
      mapListing:[],
      spinner:false,
      groupDialog:false
    }
  },
  mounted() {
      this.fetchAllGroups()
  },
  methods:{
    ...mapActions(
      [
          "fetchGroupsPaginated",
          "doImportUserContacts",
          "saveExcelMappings"
      ]
    ),
    clearDataUpload(){
      this.showButton = true
      this.mapListing = []
      this.file = null
      this.form = {
        fileName:null,
        group_id:"",
        notice_email:null,
        mappings  :[
          {
            field:"surname",
            index:-1
          },
          {
            field:"second_name",
            index:-1
          },
          {
            field:"other_names",
            index:-1
          },
          {
            field:"gender",
            index:-1
          },
          {
            field:"phone_one",
            index:-1
          },
          {
            field:"phone_two",
            index:-1
          },
          {
            field:"email_one",
            index:-1
          },
          {
            field:"email_two",
            index:-1
          }
        ],
      }
    },
    uploadExcelFile(){
      this.uploadLoader = true
      this.errorMessage = null

      if(this.file){
        const formData = new FormData();
        formData.append("file", this.file);
        this.doImportUserContacts(formData)
      }


    },
    mapExcelFields(){
      this.saveExcelMappings(this.form)
    },
    fetchAllGroups() {
      let options = {
        page: 1,
        per_page: 20,
        search: this.search
      };

      this.fetchGroupsPaginated(options)
    },
    doAddNewGroup(){
      this.groupDialog = true
    },
    filterGroupData(data){
      let raw_data = data.data
      let obj = {}
      let arr = [{text:"--Select Group--", id:""}]
      raw_data.forEach(list => {
        obj = {
          text:list.grp_name,
          id:list.number
        }
        arr.push(obj)
      })
      this.groupList = arr
    },
    clearForm(){
      this.form = {
          file:null,
          groupId:null,
        }
    },
    addGroupData(){
      this.groupDialog = true
    }
  },
  computed:{
    ...mapGetters(
        [
            "getGroupListPagination",
            "getImportGroupContact",
            "getImportGroupContactError",
            "getProcessFileData",
            "getNewGroupFile"
        ]
    )
  },
  watch:{
    getGroupListPagination(data){
      if(data){
        this.filterGroupData(data)
      }
    },
    getImportGroupContact(data){
      if(data){
        this.showButton = false
        this.uploadLoader = false
        this.form.fileName = data.fileName

        let dataField = data.fieldIndices

        let obj = {}
        let arr = [{text:"--Select Mapping--", id:-1}]
        dataField.forEach(list => {
          obj = {
            text:list.field,
            id:list.index
          }
          arr.push(obj)
        })

        this.mapListing = arr
      }
    },
    getImportGroupContactError(error){
      if(error){
        this.errorMessage = error.message
        this.spinner = false
        this.uploadLoader = false
        this.errorToast("Error", error.message)
      }
    },
    getProcessFileData(data){
      if(data){
        this.clearDataUpload()
        this.successToast("Success", data.message)
      }
    },
    getNewGroupFile(data){
      if(data){
        this.successToast("Success", data.message)
        this.groupDialog = false
        this.$refs["group_component"].spinner = false
        this.$refs["group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.fetchAllGroups()
      }
    },
  }
}
</script>

<style scoped>

</style>