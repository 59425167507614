<script>
import { Line } from "vue-chartjs";
export default {
  name: "LineGraph",
  extends: Line,
  data() {
    return {
      chartData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ],
        datasets: [
          {
            label: "Logs",
            data: [0,
              0,
              2,
              0,
              2,
              1,
              7, 5,
              1,
              0,
              0,
              0
            ],
            fill: false,
            borderColor: "#9600bc",
            backgroundColor: "#9600bc",
            borderWidth: 1
          },
          {
            label: "Traces",
            data: [
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0,
              0
            ],
            fill: false,
            borderColor: "green",
            backgroundColor: "green",
            borderWidth: 1
          }
        ]
      },
      options: {
        tooltips: {
          mode: "index",
          axis: "y"
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>

<style scoped></style>
