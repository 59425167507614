import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;
const state = {
    newUser: null,
    userProfileInfo: null,
    editUserInfo: null,
    newUserError: null,
};

const getters = {
    getNewUser: state => state.newUser,
    getUserProfileInfo: state => state.userProfileInfo,
    getEditUserInfo: state => state.editUserInfo,
    getNewUserError: state => state.newUserError,
};

const actions = {
    async registerNewUser({commit}, data) {
        await axios
            .post(IP + "auth/register", data)
            .then(response => {
                commit("setNewUser", response);
            })
            .catch(error => {
                commit("setNewUserError", error);
            });
    },
    async registerEditUser({commit}, data) {
        await axios
            .post(IP+ "message/edit-user", data)
            .then(response => {
                commit("setEditUser", response);
            })
            .catch(error => {
                commit("setNewUserError", error);
            });
    },
    async registerNewInnerUser({commit}, data) {
        await axios
            .post(IP+ "message/register", data)
            .then(response => {
                commit("setNewUser", response);
            })
            .catch(error => {
                commit("setNewUserError", error);
            });
    },
    async fetchUserInfo({commit}, data) {
        await axios
            .get(IP+ "message/fetch-user-profile/"+data)
            .then(response => {
                commit("setUserProfile", response);
            })
            .catch(error => {
                commit("setNewUserError", error);
            });
    },


};

const mutations = {
    setNewUser: (state, response) => {
        state.newUser = response.data;
    },
    setEditUser: (state, response) => {
        state.editUserInfo = response.data;
    },
    setUserProfile: (state, response) => {
        state.userProfileInfo = response.data;
    },
    setNewUserError: (state, error) => {
        state.newUserError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

