<template>
<span>

      <v-text-field
          @click:clear="returnsContactListWhenCleared"
          clearable
          class="mt-2 mb-2"
          dense
          hide-details
          outlined
          v-model="search"
          placeholder="Search Contact"
          prepend-inner-icon="mdi-magnify"
          v-on:keyup.enter="searchContactData"
          @keyup="returnsContactListWhenNull"
      ></v-text-field>


            <v-divider></v-divider>


  <div style="height: 70vh;">
 <v-progress-linear
     v-if="contactLoader"
     indeterminate
     color="#242f7e"
     class="mb-0"
     height="2"
 ></v-progress-linear>
                <v-virtual-scroll
                    :items="contactList"
                    item-height="61"
                    @scroll="onScroll"
                >
              <template v-slot:default="{ item }">

                <v-list  subheader>

                  <v-list-item-group
                      :v-model="item.number"
                  >

                    <v-list-item
                        style="border-bottom: 0.5px solid #f1f1f1;"
                        @click.prevent="selectUserContact(item)"
                        link>

                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-account-circle
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <small v-text="item.phone"></small>
                      </v-list-item-content>

                      <v-list-item-action>


                         <v-menu
                             bottom
                             left
                             open-on-hover
                         >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                      <v-card width="180">
                          <v-list dense>
                            <v-list-item @click="editContact(item)">
                              <small>Edit Contact</small>
                            </v-list-item>
                          </v-list>
                      </v-card>
                        </v-menu>

        </v-list-item-action>

                    </v-list-item>

                  </v-list-item-group>

                </v-list>

              </template>
            </v-virtual-scroll>

  </div>


 <v-dialog
     transition="dialog-bottom-transition"
     v-model="editUserDialog"
     width="42%"
 >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit User</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editUserDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-user :userInfo="userInfo" ref="edit_user_component"></new-user>

         </v-card>


      </v-card>
    </v-dialog>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewUser from "@/views/Main/users/NewUser";

export default {
  name: "MessengerContacts",
  components: {NewUser},
  data(){
    return{
      contactLoader:false,
      search:"",
      contactList:[],
      items_per_page: 13,
      page: 1,
      editUserDialog:false,
      userInfo:{}
    }
  },
  mounted() {
    if(this.$route.query.q){
      this.search = this.$route.query.q
    }
    this.fetchContacts()
    this.connectedCompanyPhones()
  },
  methods: {
    ...mapActions(
        [
          "fetchContactList",
            "contactMessageList",
            "fetchContactProfile",
            "connectedCompanyPhones"
        ]
    ),
    fetchContacts(){
      this.contactLoader = true
      this.contactList = []
      let options = {
        page: this.page,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchContactList(options)
    },
    selectUserContact(item) {
      let options = {
        page: 1,
        per_page: 11,
        contact_number: item.number,
       // search: this.search,
      }
      this.contactMessageList(options)

      //  fetch user contact profile
      this.fetchContactProfile(item.number)

      // router.push({name: 'messenger', query: {type: 'contacts', id: item.number, page: this.page+'', per_page: this.items_per_page+''}})
    },
    filterContactData(data) {
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.contactLoader = false;


      let jsn = {};
      // let arr = [];

      dataObj.forEach(list => {
        let user = list.User
        jsn = {
          title: user.surname + " " + user.second_name + " " + user.other_names,
          phone: list.contact,
          number: list.number,
          userNumber: user.number,
        }
        //arr.push(jsn)

        const index = this.contactList.findIndex(object => object.number === jsn.number);

        if (index === -1) {
          this.contactList.push(jsn);
        }

      })


      // this.contactList = arr;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.contactLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
        }
        this.fetchContactList(options)

      }
    },
    searchContactData(){
      this.contactLoader = true
      this.contactList = []
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchContactList(options)
    },
    editContact(item){
      this.editUserDialog = true
      this.userInfo = {number: item.userNumber}
    },
    returnsContactListWhenNull(){
      if(!this.search){
        this.searchContactData()
      }
    },
    returnsContactListWhenCleared(){
     this.search = ""
      this.searchContactData()
    }


  },
  computed:{
    ...mapGetters(
        [
          "getContactList",
          "getEditUserInfo"
        ]
    )
  },
  watch:{
    getContactList(data){
      if(data){
        if (data) {
          this.filterContactData(data)

          //if no contact is selected then with use the first contact to display its data
       if(data.page === 1) {
         if(this.$route.query.type !== 'grp') {
           let profileNumber = this.contactList[0].number
           this.selectUserContact({number: profileNumber})
         }
       }

        }
      }
    },
    getEditUserInfo(data){
      if(data){
        this.$refs["edit_user_component"].spinner = false
        this.$refs["edit_user_component"].form = {
          surname: null,
          second_name: null,
          other_names: null,
          email: [
            {mail:null, number: null}
          ],
          phone: [
            {phoneNumber:null, number: null}
          ],
          gender: null,
        }
        this.fetchContacts()
        this.successToast("Success", data.message)
        this.editUserDialog = false
      }
    }
  }
}
</script>

<style scoped>
.activeClass{
  background-color: #e4e4e4;
}
</style>