<template>
<span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Send message from a file</v-toolbar-title>
        </v-toolbar>
          <v-divider></v-divider>
            <v-card flat>
                <v-row class="mx-auto mt-10 pb-5">
                  <v-col cols="8" class="mx-auto">
                    <v-alert type="error" v-if="errorMessage">
                      {{ errorMessage }}
                    </v-alert>



                      <v-card-text>
                        <v-row>
                                <v-col>
                                  <v-row>
                                      <v-file-input
                                          @click:clear="clearDataUpload"
                                          @change="uploadExcelFile"
                                          clearable
                                          outlined
                                          v-model="file"
                                          dense
                                          show-size
                                          truncate-length="20"
                                          prepend-icon=""
                                          type="file"
                                          label="Please upload Excel File"
                                          prepend-inner-icon="mdi-paperclip"
                                          required
                                          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                      ></v-file-input>

<!--                                   <v-btn
                                       v-if="showButton"
                                       :loading="uploadLoader"
                                       color="blue-grey"
                                       class=" ml-2 white&#45;&#45;text"
                                       @click="uploadExcelFile"
                                   >
                          <v-icon dark>
                            mdi-paperclip
                          </v-icon>
                                     Upload File
                        </v-btn>-->
                                  </v-row>

                                   <p> Contacts will be read from sheet one of the file.<br />
                                    Make sure the first row has titles</p>
                                </v-col>

                              </v-row>
                      </v-card-text>



                    <span v-if="mapListing.length > 0">
                        <form ref="form" @submit.prevent="newMessage">


                              <v-autocomplete
                                  label="Receiver Phone Column"
                                  v-model="form.phones"
                                  :items="mapListing"
                                  item-value="id"
                                  outlined
                                  dense
                                  hide-selected
                                  clearable
                                  multiple
                                  persistent-hint
                                  hint="You need to specify receiver column from excel:ie [phone1,phone2]"
                              ></v-autocomplete>


                      <v-radio-group
                          v-model="toggleMessageField"
                          column
                      >
                        <v-radio
                            label="I want to write a custom message?"
                            value="have_message"
                        ></v-radio>
                        <v-radio
                            label="Pre typed Message In file"
                            value="have_message_field"
                        ></v-radio>
                      </v-radio-group>



                      <v-textarea
                          ref="text_component"
                          v-if="show_have_message"
                          v-model="form.message"
                          label="Message"
                          outlined
                          rows="2"
                          dense
                          required
                          hide-details
                      ></v-textarea>



  <div class="mb-6"  v-if="show_have_message" style="color: #525050;">
  Tags:
    <span v-for="(list, index) in mapListing" :key="index">
      <small v-if="list.id" class="tags mr-1" style="cursor: pointer;" @click="addTag(list.id)">{{'{'+list.id+'}'}}</small>
    </span>
</div>


                            <v-select
                                class="mb-3"
                                v-if="show_have_message_field"
                                v-model="form.messageField"
                                :items="mapListing"
                                item-value="id"
                                label="Specify message Field"
                                outlined
                                dense
                                persistent-hint
                                hint="please copy the message field from excel file."
                            ></v-select>



                      <v-row class="mx-auto">
                        <v-checkbox
                            v-model="showMail"
                            class="shrink mr-0 mt-0 float-left"
                            label="Notify me on progress."
                            color="#242f7e"
                            small
                        ></v-checkbox>
                      </v-row>


                       <span style="color: #858585;"> Create user profile (Optional)</span>

                            <v-row>
                            <v-col cols="6">
                                  <v-text-field
                                      disabled
                                      outlined
                                      label="Surname"
                                      required
                                      type="text"
                                      dense
                                  ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                  label="Select Surname"
                                  v-model="form.surname"
                                  :items="mapListing"
                                  item-value="id"
                                  outlined
                                  dense
                              ></v-select>
                            </v-col>
                          </v-row>


                           <v-row>
                            <v-col cols="6">
                                  <v-text-field
                                      disabled
                                      outlined
                                      label="Second Name"
                                      required
                                      type="text"
                                      dense
                                  ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                  label="Select Second Name"
                                  v-model="form.second_name"
                                  :items="mapListing"
                                  item-value="id"
                                  outlined
                                  dense
                              ></v-select>
                            </v-col>
                          </v-row>


                           <v-row>
                            <v-col cols="6">
                                  <v-text-field
                                      disabled
                                      outlined
                                      label="Other Names"
                                      required
                                      type="text"
                                      dense
                                  ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                  label="Select Other Name"
                                  v-model="form.other_names"
                                  :items="mapListing"
                                  item-value="id"
                                  outlined
                                  dense
                              ></v-select>
                            </v-col>
                          </v-row>



                           <v-row>
                            <v-col cols="6">
                                  <v-text-field
                                      disabled
                                      outlined
                                      label="Gender"
                                      required
                                      type="text"
                                      dense
                                  ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-select
                                  label="Select Gender"
                                  v-model="form.gender"
                                  :items="mapListing"
                                  item-value="id"
                                  outlined
                                  dense
                              ></v-select>
                            </v-col>
                          </v-row>


                      <v-text-field
                          outlined
                          v-if="showMail"
                          v-model="form.notice_email"
                          label="Email"
                          required
                          type="text"
                          dense
                      ></v-text-field>



                      <div class="text-right">
                        <v-btn
                            class="mt-4"
                            :loading="spinner"
                            color="#242f7e"
                            dark
                            type="submit"
                        >
                          Save
                        </v-btn>
                      </div>
                    </form>
                    </span>

                  </v-col>
                </v-row>


            </v-card>
        </v-card>
      </v-flex>
    </v-layout>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "SendFromFile",
  data() {
    return {
      toggleMessageField:null,
      errorMessage: null,
      have_message:false,
      have_message_field:false,
      showMail:false,
      file:null,
      showButton:true,
      uploadLoader:false,
      mapListing:[],
      form: {
        fileName: null,
        message: null,
        messageField:"",
        phones:[],
        notice_email:null,
        surname:"",
        second_name:"",
        other_names:"",
        gender:""
      },
      spinner: false,
      show_have_message:false,
      show_have_message_field:false,

    }
  },
  methods:{
    ...mapActions(
        [
          "sendMessageFromFile",
          "doImportUserContacts"
        ]
    ),
    newMessage(){
      this.spinner = true
      this.errorMessage = null
      this.sendMessageFromFile(this.form)
    },
    uploadExcelFile(){
      this.uploadLoader = true
      this.errorMessage = ""
if( this.file){
  const formData = new FormData();
  formData.append("file", this.file);

  this.doImportUserContacts(formData)
}


    },
    clearDataUpload(){
      this.file = null
      this.showButton = true
      this.mapListing = []
      this.form = {
        fileName: null,
          message: null,
          messageField:"",
          phones:"",
          notice_email:null,
          surname:null,
          second_name:null,
          other_names:null,
          gender:null
      }

    },
    addTag(dataInfo){
      let data  = '{'+dataInfo+'}'
      const insertText = data

      if (!insertText.length) return

      const textarea = this.$refs.text_component.$refs.input
      const sentence = textarea.value

      const len = sentence.length
      let pos = textarea.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.form.message = before +" "+ insertText+" " + after

    },
  },
  computed:{
    ...mapGetters(
        [
          "getNewSendFromFile",
           "getSendFromFileError",
           "getImportGroupContact",
           "getImportGroupContactError"
        ]
    )
  },
  watch:{
    getNewSendFromFile(data){
      if(data){
        this.spinner = false
        this.successToast("Success")
        this.clearDataUpload()
      }
    },
    toggleMessageField(){
      if(this.toggleMessageField === "have_message"){
        this.show_have_message = true
        this.show_have_message_field = false
        this.form.messageField = ""
      }else if (this.toggleMessageField === "have_message_field") {
        this.show_have_message_field = true
        this.show_have_message = false
        this.form.message = ""
      }
    },
    getSendFromFileError(data){
      if(data){
        this.spinner = false
        this.errorMessage = data.message
        this.errorToast("Error", data.message)
      }
    },
    getImportGroupContact(data){
      if(data){
        this.showButton = false
        this.uploadLoader = false
        this.form.fileName = data.fileName
        //alert(JSON.stringify(data))

        let dataField = data.fieldIndices
        let obj = {}
        let arr = [{text:"Select Mapping", id:"", disabled:true}]
        dataField.forEach(list => {
          obj = {
            text:list.field,
            id:list.field
          }
          arr.push(obj)
        })

        this.mapListing = arr

      }
    },
    getImportGroupContactError(error){
      if(error){
        this.errorMessage = error.message
        this.spinner = false
        this.uploadLoader = false
        this.errorToast("Error", error.message)
      }
    },
  }
}
</script>

<style scoped>
.tags:hover{
  color: green;
}
</style>