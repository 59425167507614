<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Message Logs</v-toolbar-title>
        </v-toolbar>

          <v-divider></v-divider>



            <v-card flat class="mb-10">


       <v-row class="mx-auto mt-5">
           <v-col cols="2">
         <v-autocomplete
             clearable
             v-model="msg_type"
             :items="typeList"
             item-value="id"
             label="Type"
             dense
             v-on:change="filterList"
             hide-details
         ></v-autocomplete>
        </v-col>
        <v-col cols="2">
         <v-autocomplete
             clearable
             v-model="msg_status"
             :items="statusList"
             item-value="id"
             label="Status"
             dense
             v-on:change="filterList"
             hide-details
         ></v-autocomplete>
        </v-col>
      <v-col cols="2">
         <v-select
             clearable
             v-model="sender_phone"
             label="Sender:"
             :items="senderList"
             item-value="id"
             dense
             v-on:change="filterList"
             hide-details
         ></v-select>
      </v-col>
           <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="startDate"
                      dense
                      readonly
                      label="Start Date"
                      v-bind="attrs"
                      v-on="on"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="endDate"
                      dense
                      readonly
                      label="End Date"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      v-bind="attrs"
                      clearable
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title></v-date-picker>
              </v-menu>
            </v-col>
            </v-row>

              <v-row class="mx-auto">
                <v-col cols="8">
                   <v-btn
                       :loading="batchStatusLoader"
                       v-if="cancelRetryTextAndIcon.text"
                       outlined
                       dark
                       color="#242f7e"
                       small
                       @click="retryCancelMessages(cancelRetryTextAndIcon.messageStatus)"
                   >
                      <v-icon dark>
                       {{cancelRetryTextAndIcon.icon}}
                      </v-icon>
                          {{cancelRetryTextAndIcon.text}}
                    </v-btn>
                </v-col>
                <v-col cols="4">
   <v-text-field
       clearable
       v-model="search"
       append-icon="mdi-magnify"
       label="Search"
       dense
       outlined
       persistent-hint
       hint="Press enter to search"
       v-on:keyup.enter="filterList"
   ></v-text-field>
</v-col>
              </v-row>

    <v-progress-linear
        v-if="messageListLoader"
        indeterminate
        color="#242f7e"
        class="mb-0"
        height="3"
    ></v-progress-linear>
              <v-row class="mx-auto mb-1">

<v-spacer></v-spacer>
                  <small class="mr-4">Page: {{page}}, Items:  {{(totalResultsFound)}} / {{totalMessageCount}}</small>

              </v-row>

<v-divider></v-divider>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>

             <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Message
                      </th>
                      <th class="text-left">
                        Sender
                      </th>
                      <th class="text-left">
                        Receiver
                      </th>
                      <th class="text-left">
                        Type
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.name"
                    >
                      <td>{{ item.key }}</td>
<!--                      <td>{{ timeAgo(new Date(item.date)) }}</td>-->
                      <td style="width:10%;">{{ item.date }}</td>
                      <td style="width: 30%;" class="pt-5 pb-5">
                     <div style="word-wrap: break-word;"> {{ item.msg_text }}</div>
                      </td>
                      <td style="width: 5%;">
                           <v-select
                               :readonly="item.status !== 'Pending'"
                               v-model="item.sender"
                               :items="senderList"
                               item-value="id"
                               dense
                               v-on:change="altSender(item)"
                               hide-details
                           ></v-select>

                        </td>
                      <td>
                       <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:item.receiverNumber, q: (item.receiver).replace('+', '') }}">
                        <span v-text="item.receiver"></span>
                         <br />
                       <small style="color: #000000;">{{ item.receiver_name }}</small>
                         </router-link>

                      </td>
                      <td>{{ item.msg_type }}</td>
                      <td>{{ item.status }}</td>
                      <td>
                         <v-menu
                             v-if="item.status !== 'Delivered' || item.status !== 'Sent'"
                             :nudge-width="120"
                             offset-x
                             offset-y
                             open-on-hover
                             origin="center center"
                             transition="fab-transition"
                         >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>


                    <v-progress-circular
                        v-if="loaderIndex === item.number"
                        width="1"
                        size="20"
                        color="#242f7e"
                        indeterminate
                    ></v-progress-circular>
                  </v-btn>




                </template>

                <v-list dense>


                  <v-list-item
                      class="text-decoration-none"
                      v-for="(actionItem, i) in messageAction"
                      :key="i"
                      @click="doAction(actionItem.todo, item)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="actionItem.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="actionItem.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-menu>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>

        </v-card>

        </v-card>

      </v-flex>
    </v-layout>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MessageLogs",
  data(){
    return{
      contactLoader:false,
      batchStatusLoader:false,
      search: null,
      loading:false,
      startDate:"",
      endDate: "",
      sender_phone:null,
      senderList:[],
      msg_status: null,
      totalMessageCount: 0,
      msg_type: null,
      tableLoader: false,
      pagination: {},
      totalResultsFound: 0,
      page: 1,
      items_per_page: 15,
      items_per_page_options: [20, 50, 100, 500],
      server_items_length: 0,
      contactDialog:false,
      generalTableData: [],
      companyDialog:false,
      loaderIndex:undefined,
      groupInfo:{},
      groupContactDialog:false,
      typeList:[
        { text: '--Select Status--', id:"" },
        { text: 'Outgoing', id:"Sent" },
        { text: 'Incoming', id:"Received" },
      ],
      statusList:[
        { text: '--Select Status--', id:"" },
        { text: 'Delivered', id:"Delivered" },
        { text: 'Pending', id:"Pending" },
        { text: 'Cancelled', id:"Cancelled" },
        { text: 'Failed', id:"Failed" },
        { text: 'Processing', id:"Processing" },
        { text: 'Sent', id:"Sent" },
        { text: 'Received', id:"Received" },
        { text: 'Blacklist', id:"Blacklist" },
        { text: 'Unknown', id:"Unknown" },
      ],
      messageAction: [
        { text: 'Pending', icon:"mdi-checkbox-blank-circle-outline", todo:"Pending" },
        { text: 'Unknown', icon:"mdi-checkbox-blank-circle-outline", todo:"Unknown" },
        { text: 'Failed', icon:"mdi-checkbox-blank-circle-outline", todo:"Failed" },
        { text: 'Blacklist', icon:"mdi-checkbox-blank-circle-outline", todo:"Blacklist" },
        { text: 'Cancelled', icon:"mdi-checkbox-blank-circle-outline", todo:"Cancelled" },
      ],
      messageListLoader:false
    }
  },
  mounted() {
    this.filterList()
    this.connectedCompanyPhones()
  },
  methods:{
    ...mapActions(
        [
          "fetchMessageLogsPaginated",
          "updateMessageStatus",
         "connectedCompanyPhones",
         "updateMessageSenderPhone",
         "retryCancelMessageStatus"
        ]
    ),

    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.messageListLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
          msg_status: this.msg_status,
          msg_type: this.msg_type,
          sender_phone: this.sender_phone,
          start_date:this.startDate,
          end_date:this.endDate,
        }
        this.fetchMessageLogsPaginated(options)
      }
    },
    filterList(){
      this.messageListLoader = true
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        msg_type: this.msg_type,
        msg_status: this.msg_status,
        sender_phone: this.sender_phone,
        start_date:this.startDate,
        end_date:this.endDate,
      }
      this.fetchMessageLogsPaginated(options)
    },
    filterMessageLogsData(data){
      this.messageListLoader = false
      if(data.data) {
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.totalMessageCount = data.total > 0 ? data.total : 0
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;
      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.totalResultsFound = data.resultsFount

        let obj = {};


        dataObj.forEach((list, i) => {
          obj = {
            key: (current_page - 1) * per_page + i + 1,
            date: list.updated_at ? this.timeAgo(new Date(list.updated_at)) : "",
            msg_text: list.msg_text ? list.msg_text : "",
            sender: list.msg_sender_phone ? list.msg_sender_phone : "",
            receiver: list.msg_receiver_phone ? list.msg_receiver_phone : "",
            status: list.msg_status ? list.msg_status : "",
            msg_type: list.msg_type === "Sent" ? "Outgoing" : "Incoming",
            number: list.number ? list.number : "",
            receiverNumber: list.receiver_id_number ? list.receiver_id_number : "",
            receiver_name: list.receiver_name ? list.receiver_name : "",
          }


          const index = this.generalTableData.findIndex(object => object.number === obj.number);

          if (index === -1) {
            this.generalTableData.push(obj);
          }

        })
      }


    },
    doAction(todo, item){
      let data = {
        action:todo,
        number:item.number
      }
      this.loaderIndex = item.number
      this.updateMessageStatus(data)
    },
    devicePhoneFilter(dataList) {
      let dataObj = [];
      let dataJson = {};
      dataList.forEach(list => {
        dataJson = {
          text: list.DevicePhone.phone_number+" [ "+list.DevicePhone.ConnectedDevice.device_desc+" ]",
          id: list.DevicePhone.phone_number
        };
        dataObj.push(dataJson);
      })


      return dataObj;
    },
    altSender(data){
      this.updateMessageSenderPhone({sender:data.sender, number:data.number})
    },
    retryCancelMessages(messageStatus){
      this.batchStatusLoader = true
      let options = {
        search: this.search,
        msg_type: this.msg_type,
        msg_status: this.msg_status,
        sender_phone: this.sender_phone,
        start_date:this.startDate,
        end_date:this.endDate,
        message_status:messageStatus,
      }
      this.retryCancelMessageStatus(options)

    }

  },
  computed:{
    ...mapGetters(
        [
          "getMessageLogsList",
          "getMessageStatus",
          "getMessageLogsError",
          "getCompanyConnectedPhone",
          "getMessageSender",
          "getMessageSenderError",
          "getBatchMessageStatusRetry",
          "getBatchMessageStatusRetryError"
        ]
    ),
    cancelRetryTextAndIcon(){
      let text = ""
      let icon = ""
      let messageStatus = ""
      if(this.msg_type ==="Sent"){
          if(this.msg_status ==="Pending") {
            text = "Cancel Pending Messages"
            icon = "mdi-undo-variant"
            messageStatus = "Cancelled"
          }else if(this.msg_status ==="Failed"){
            text = "Retry Failed Messages"
            icon = "mdi-redo-variant"
            messageStatus = "Pending"
          }
      }
      return {text:text, icon:icon, messageStatus:messageStatus}
    }
  },
  watch:{
    /*pagination(obj) {
      this.tableLoader = true

      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search,
        msg_status: this.msg_status,
        msg_type: this.msg_type,
        sender_phone: this.sender_phone,
        start_date:this.startDate,
        end_date:this.endDate,
      };

      this.fetchMessageLogsPaginated(options)
    },*/
    getMessageLogsList(data){
      if(data){
        this.filterMessageLogsData(data)
      }
    },
    getMessageStatus(data){
      if(data){
        this.loaderIndex = undefined
        this.messageListLoader = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
    getMessageLogsError(data){
      if(data){
        this.loaderIndex = undefined
        this.messageListLoader = false
        this.errorToast("Error", data.message)
      }
    },
    getCompanyConnectedPhone(data){
      if(data){
        this.senderList = this.devicePhoneFilter(data.data)
      }
    },
    startDate(){
      this.filterList()
    },
    endDate(){
      this.filterList()
    },
    getMessageSender(data){
      if(data){
        this.successToast("Success", data.data.message)
      }
    },
    getMessageSenderError(data){
      if(data){
        this.errorToast("Error", data.data.message)
      }
    },
    getBatchMessageStatusRetryError(data){
      if(data){
        this.batchStatusLoader = false
        this.errorToast("Error", data.data.message)
      }
    },
    getBatchMessageStatusRetry(data){
      if(data){
        this.batchStatusLoader = false
        this.successToast("Success", data.data.message)
        this.filterList()
      }
    }
  }
}
</script>

<style scoped>

.messageClass table.v-table tr td {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: auto;
  padding: 10px;
}

</style>