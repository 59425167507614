<template>
 <span>
  <v-card flat>
    <v-toolbar v-if="!removeTitle" dense flat>
      <v-toolbar-title>Contacts under {{groupInfo.title}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider v-if="!removeTitle"></v-divider>

   <v-row class="mt-5">
    <v-col cols="12" lg="6" md="6" sm="12" class="mb-2" >
        <v-card outlined>
          <p class="ml-5 mt-3">Added Contacts</p>
          <v-divider></v-divider>
            <added-contacts :groupInfo="groupInfo"></added-contacts>
        </v-card>
     </v-col>
     <v-col cols="12" lg="6" md="6" sm="12">
        <v-card outlined>
          <p class="ml-5 mt-3">Remaining Contacts</p>
          <v-divider></v-divider>
          <remaining-contacts :groupInfo="groupInfo"></remaining-contacts>
        </v-card>
     </v-col>
   </v-row>

  </v-card>
  </span>
</template>

<script>
import AddedContacts from "@/views/Main/Groups/AddedContacts";
import RemainingContacts from "@/views/Main/Groups/RemainingContacts";
export default {
  name: "GroupContactManager",
  components: {RemainingContacts, AddedContacts},
  props:{
    groupInfo:{},
    removeTitle:String
  }
}
</script>

<style scoped>

</style>