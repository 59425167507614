import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    metrics: null,
    ListError: null,
};

const getters = {
    getMetrics: state => state.metrics,
    getMetricsError: state => state.ListError,
};

const actions = {
    async fetchMetrics({commit}, data) {
        await axios
            .get(IP + "message/metrics", data)
            .then(response => {
                commit("setMetrics", response);
            })
            .catch(error => {
                commit("setMetricsError", error);
            });
    },


};

const mutations = {
    setMetrics: (state, response) => {
        state.metrics = response.data;
    },

    setMetricsError: (state, error) => {
        state.ListError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

