<template>
  <span>
  <VerticalMenu />


<v-main class="mx-auto" style="background-color: #f0f2f5; height:100vh;">
    <router-view></router-view>
</v-main>
  </span>
</template>


<script>
import VerticalMenu from "@/views/Commons/NavigationMenu";
export default {
  name: "MainDashboard",
  components: {VerticalMenu}
}
</script>

<style scoped>

</style>