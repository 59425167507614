import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    contactMessages: null,
    groupMessagesData: null,
    receivedMessages: null,
    newGroupMessages: null,
    newContactMessages: null,
    companyConnectedPhone: null,
    messageError: null,
};

const getters = {
    getContactMessages: state => state.contactMessages,
    getGroupMessages: state => state.groupMessagesData,
    getNewGroupMessages: state => state.newGroupMessages,
    getNewContactMessages: state => state.newContactMessages,
    getCompanyConnectedPhone: state => state.companyConnectedPhone,
    getReceivedMessages: state => state.receivedMessages,
    getMessageError: state => state.messageError,
};

const actions = {
    async contactMessageList({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(IP +
                "message/contact-messages?page="+data.page+
                "&per_page="+ data.per_page+
                "&contact_number="+ data.contact_number+
                "&search="+search
            )
            .then(response => {
                commit("setContactMessages", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },
    async groupMessages({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let contact_number = data?data.contact_number ? data.contact_number: "":""
        await axios
            .get(IP +
                "message/group-messages?page="+data.page+
                "&per_page="+ data.per_page+
                "&contact_number="+ contact_number+
                "&search="+search
            )
            .then(response => {
                commit("setGroupMessages", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },
    async fetchReceivedMessages({commit}, data) {
        await axios
            .get(IP +
                "message/received-message-list?page="+data.page+
                "&per_page="+ data.per_page
            )
            .then(response => {
                commit("setReceivedMessages", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },
    async sendGroupMessage({commit}, data) {
        await axios
            .post(IP + "message/send-group-messages", data)
            .then(response => {
                commit("setNewGroupMessages", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },
    async sendContactMessage({commit}, data) {
        await axios
            .post(IP + "message/send-contact-messages", data)
            .then(response => {
                commit("setNewContactMessages", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },
    async connectedCompanyPhones({commit}, data) {
        await axios
            .get(IP + "message/connected-company-phones", data)
            .then(response => {
                commit("setCompanyConnectedPhone", response);
            })
            .catch(error => {
                commit("setMessageError", error);
            });
    },


};

const mutations = {
    setContactMessages: (state, response) => {
        state.contactMessages = response.data;
    },
    setGroupMessages: (state, response) => {
        state.groupMessagesData = response.data;
    },
    setReceivedMessages: (state, response) => {
        state.receivedMessages = response.data;
    },
    setNewGroupMessages: (state, response) => {
        state.newGroupMessages = response.data;
    },
    setNewContactMessages: (state, response) => {
        state.newContactMessages = response.data;
    },
    setCompanyConnectedPhone: (state, response) => {
        state.companyConnectedPhone = response.data;
    },
    setMessageError: (state, error) => {
        state.messageError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

