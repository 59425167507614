<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Group Message Logs</v-toolbar-title>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat class="mb-10">


                <v-row class="mx-auto mt-5">
                    <v-col cols="2">
                     <v-autocomplete
                         clearable
                         v-model="userStatus"
                         :items="statusList"
                         item-value="id"
                         label="Status"
                         dense
                         v-on:change="filterList"
                         hide-details
                     ></v-autocomplete>
                    </v-col>
                   <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="startDate"
                      dense
                      readonly
                      label="Start Date"
                      v-bind="attrs"
                      v-on="on"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="endDate"
                      dense
                      readonly
                      label="End Date"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      v-bind="attrs"
                      clearable
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title></v-date-picker>
              </v-menu>
            </v-col>
<v-col cols="2"></v-col>
<v-col cols="4">
   <v-text-field
       clearable
       v-model="search"
       append-icon="mdi-magnify"
       label="Search"
       dense
       outlined
       persistent-hint
       hint="Press enter to search"
       v-on:keyup.enter="filterList"
   ></v-text-field>
</v-col>

            </v-row>

    <v-progress-linear
        v-if="messageListLoader"
        indeterminate
        color="#242f7e"
        class="mb-0"
        height="3"
    ></v-progress-linear>

<v-divider></v-divider>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>

             <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Message
                      </th>
                      <th class="text-left">
                        Group
                      </th>

                      <th class="text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.name"
                    >
                      <td>{{ item.key }}</td>
                      <td>{{ item.date }}</td>
                      <td class="pt-5 pb-5">
                     <div style="word-wrap: break-word; width: 300px;"> {{ item.msg_text }}</div>
                      </td>
                      <td>
                         <router-link class="text-decoration-none" :to="{name:'messenger', query:{type:'grp', id:item.groupId, q:item.group}}">
                              <v-icon class="mr-3">
                             mdi-account-group
                            </v-icon>
                            {{item.group}}
                          </router-link>
                        </td>
                      <td>{{ item.status }}</td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>



        </v-card>



        </v-card>

      </v-flex>
    </v-layout>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MessageLogs",
  data(){
    return{
      contactLoader:false,
      search: null,
      tableLoader: false,
      pagination: {},
      startDate:"",
      endDate: "",
      page: 1,
      items_per_page: 15,
      items_per_page_options: [20, 50, 100, 500],
      server_items_length: 0,
      contactDialog:false,
      generalTableData: [],
      companyDialog:false,
      loaderIndex:undefined,
      userStatus: null,
      statusList:[
        { text: '--Select Status--', id:"" },
        { text: 'Processing', id:"processing" },
        { text: 'Complete', id:"complete" },
      ],
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Date", value: "date", width: "10%"},
        {text: "Message", value: "msg_text"},
        {text: "Sender", value: "sender"},
        {text: "Receiver", value: "receiver"},
        {text: "Status", value: "status"},
        {text: "Action", value: "actions", width: "5%"}
      ],
      groupInfo:{},
      groupContactDialog:false,
      messageAction: [
        { text: 'Pending', icon:"mdi-checkbox-blank-circle-outline", todo:"Pending" },
        { text: 'Unknown', icon:"mdi-checkbox-blank-circle-outline", todo:"Unknown" },
        { text: 'Failed', icon:"mdi-checkbox-blank-circle-outline", todo:"Failed" },
        { text: 'Blacklist', icon:"mdi-checkbox-blank-circle-outline", todo:"Blacklist" },
        { text: 'Cancelled', icon:"mdi-checkbox-blank-circle-outline", todo:"Cancelled" },
      ],
      messageListLoader:false
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
          "fetchGroupMessageLogsPaginated",
          "updateMessageStatus"
        ]
    ),
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.messageListLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
          userStatus: this.userStatus,
          start_date:this.startDate,
          end_date:this.endDate,
        }
        this.fetchGroupMessageLogsPaginated(options)
      }
    },
    filterList(){
      this.messageListLoader = true
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        userStatus: this.userStatus,
        start_date:this.startDate,
        end_date:this.endDate,
      }
      this.fetchGroupMessageLogsPaginated(options)
    },
    filterMessageLogsData(data){
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;


      this.messageListLoader = false


      let obj = {};


      dataObj.forEach((list, i) => {
        obj = {
          key: (current_page - 1) * per_page + i + 1,
          // date: this.timeAgo(new Date(list.created_at)),
          date: this.timeAgo(new Date(list.created_at)),
          msg_text: list.message,
          status: list.status,
          number: list.number,
          group: list.Group.grp_name,
          groupId: list.Group.number,
          receiverNumber: list.receiver_id_number,
        }


        const index = this.generalTableData.findIndex(object => object.number === obj.number);

        if (index === -1) {
          this.generalTableData.push(obj);
        }

      })


    },
    doAction(todo, item){
      let data = {
        action:todo,
        number:item.number
      }
      this.loaderIndex = item.number
      this.updateMessageStatus(data)
    }

  },
  computed:{
    ...mapGetters(
        [
          "getGroupMessageLogsList",
          "getMessageStatus",
          "getMessageLogsError"
        ]
    )
  },
  watch:{
    getGroupMessageLogsList(data){
      if(data){
       this.filterMessageLogsData(data)
      }
    },

    getMessageLogsError(data){
      if(data){
        this.loaderIndex = undefined
        this.errorToast("Error", data.message)
      }
    },
    startDate(){
      this.filterList()
    },
    endDate(){
      this.filterList()
    },
  }
}
</script>

<style scoped>

.messageClass table.v-table tr td {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  height: auto;
  padding: 10px;
}

</style>