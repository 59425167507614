<template>
<span>

        <v-row  class="mx-auto mt-5">
<v-col>


   <v-row class="mb-4">
          <v-col>
            <v-card>
              <v-progress-linear
                  v-if="progressLoader"
                  color="#1f7087"
                  indeterminate
                  rounded
                  height="2"
              ></v-progress-linear>

              <v-card-subtitle style="font-size: 18px;">Statistics</v-card-subtitle>

              <v-row  class="mx-auto">

                <v-col style="margin:0;
                    padding:0;
                    overflow-x:hidden; white-space: nowrap;" cols="6" v-for="(statList, index) in analyticsDataSet"
                       :key="index"
                       lg="2"
                       sm="6"
                       md="4"

                >
                                  <v-card-title  class="d-inline-flex pa-2">
                                    <v-avatar  size="35" color="#eeecfe">
                                      <v-icon
                                          size="22"
                                          :color="statList.color"
                                      >
                                       {{statList.icon}}
                                      </v-icon>
                                    </v-avatar>
                                    <span>

                  <v-card-title style="color: #726c84;">


                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span
                              style="cursor: pointer;"
                              v-bind="attrs"
                              v-on="on"
                          >{{abbreviateNumber(statList.total)}}</span>
                        </template>
                        <span>{{statList.total.toLocaleString()}}</span>
                      </v-tooltip>




                          </v-card-title>
                          <v-card-subtitle>
                         <router-link class="text-decoration-none" style="color: #726c84;" :to="{name: statList.name}" >  {{statList.title}}  </router-link>
                          </v-card-subtitle>

                </span>
                  </v-card-title>
                </v-col>

              </v-row>



            </v-card>
          </v-col>
        </v-row>



               <v-row class="mt-5" dense>

          <v-col col lg="12" md="12" sm="12">
            <v-card>
              <v-row

                  class="mx-auto"
                  justify="space-between"
              >
                <v-card-title>SMS Analytics</v-card-title>

                <div style="width: 20%;" class="mt-2">
                  <v-overflow-btn
                      style="border-style: hidden;"
                      outlined
                      background-color="#fbfdff"
                      dense
                      class="my-2 filterIcon"
                      :items="filterItems"
                      v-model="filterItemsDashboard"
                      label="Filter"
                      prepend-inner-icon="mdi mdi-calendar-text"
                  ></v-overflow-btn>
                </div>
              </v-row>

              <v-divider></v-divider>

              <line-graph></line-graph>
            </v-card>
          </v-col>

          <v-col col lg="6" md="6" sm="12">
            <v-card>
              <bar-graph></bar-graph>
            </v-card>
          </v-col>
          <v-col col lg="6" md="6" sm="12">
            <v-card>
              <doughnut-graph></doughnut-graph>
            </v-card>
          </v-col>
        </v-row>
          </v-col>
        </v-row>

</span>
</template>

<script>
import DoughnutGraph from "@/views/Commons/Graphs/DoughnutGraph";
import BarGraph from "@/views/Commons/Graphs/BarGraph";
import LineGraph from "@/views/Commons/Graphs/LineGraph";
import {mapActions,mapGetters} from "vuex";
export default {
  name: "Home",
  components: {LineGraph, BarGraph, DoughnutGraph},
  data(){
    return{
      progressLoader:false,
      analyticsDataSet:[
        {
          color: "#0072a7",
          icon: "mdi-script",
          title: "Sent",
          total: "0",
          name: "message-logs",

        },
        {
          // dark green color
          color: "green",
          icon: "mdi-script",
          title: "Delivered",
          total: "0",
          name: "message-logs",

        }, {
        //purple color
          color: "#9600bc",
          icon: "mdi-script",
          title: "Pending",
          total: "0",
          name: "message-logs",

        },
        {
          //red color
          color: "red",
          icon: "mdi-script",
          title: "Failed",
          total: "0",
          name: "message-logs",

        },{
          //cyan color
          color: "#00a8a8",
          icon: "mdi-account-multiple",
          title: "Contacts",
          total: "0",
          name: "new-contact",

        },{
          //cyan color
          color: "#00a8a8",
          icon: "mdi-account-group",
          title: "Groups",
          total: "0",
          name: "group",

        },
      ],
    }
  },
  mounted() {
    this.progressLoader = true
    this.fetchMetrics()
  },
  methods:{

    ...mapActions([
        "fetchMetrics"
    ])
  },

  computed :{
    ...mapGetters([
        "getMetrics"
    ])
  },

  watch:{
    getMetrics(){
      this.analyticsDataSet[0].total = this.getMetrics.metrics.sent
      this.analyticsDataSet[1].total = this.getMetrics.metrics.delivered
      this.analyticsDataSet[2].total = this.getMetrics.metrics.pending
      this.analyticsDataSet[3].total = this.getMetrics.metrics.failed
      this.analyticsDataSet[4].total = this.getMetrics.metrics.users
      this.analyticsDataSet[5].total = this.getMetrics.metrics.groups
      this.progressLoader = false
    }
  }

}
</script>

<style scoped>


</style>