<template>
  <span>
  <nav>
    <v-navigation-drawer
        :mini-variant.sync="mini"
        v-model="drawer"
        app
    >

      <v-list-item class="px-2">
        <v-list-item-avatar>
        <v-img src="../../assets/logo.png"></v-img>


        </v-list-item-avatar>

        <v-list-item-title style="font-size: 20px;">Noregrets</v-list-item-title>


      </v-list-item>

      <v-divider></v-divider>

      <v-list>

        <v-list-item>
          <v-list-item-content>
             <v-list-item-subtitle>Company:</v-list-item-subtitle>
            <v-list-item-title class="text-h6">
               {{companyName}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-divider></v-divider>

      <v-list shaped dense>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="{name:item.name}"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


    </v-navigation-drawer>
  </nav>


<!--------------------------------------------->



     <v-app-bar
         color="#ffffff"
         dense
        app
     >
      <v-app-bar-nav-icon  @click.stop="mini = !mini"></v-app-bar-nav-icon>

      <v-toolbar-title></v-toolbar-title>





      <v-spacer></v-spacer>



        <v-menu
            :close-on-content-click="false"
            :nudge-width="220"
            offset-x
            offset-y
            origin="center center"
            transition="fab-transition"
        >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" style="font-size: 12px;">
              New Message
          </span>

          <v-btn v-bind="attrs" v-on="on"   icon>
           <v-badge
               style="font-size: 12px;"
               bordered
               bottom
               color="#1976d2"
               :content="messageCounterInfo"
               offset-x="12"
               offset-y="12"
           >
            <v-avatar size="35">
                <v-icon dark>mdi-bell</v-icon>
            </v-avatar>
      </v-badge>
          </v-btn>



        </template>

        <v-card>
          <v-list dense>

        <v-subheader style="font-size: 15px;">New Received Messages: {{messageCounterInfo}}</v-subheader>

<v-divider></v-divider>

           <div style="width: 400px;">
   <received-messages></received-messages>
</div>

          </v-list>
        </v-card>
      </v-menu>









       <v-menu
           :close-on-content-click="false"
           :nudge-width="220"
           offset-x
           offset-y
           origin="center center"
           transition="fab-transition"
       >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" style="font-size: 12px;">
             Company
          </span>

           <v-btn v-bind="attrs" v-on="on"   icon>
            <v-avatar size="35">
                <v-icon dark>mdi-account-multiple</v-icon>
            </v-avatar>
          </v-btn>



        </template>

        <v-card>
          <v-list dense>
            <v-subheader style="font-size: 16px;">Switch Company</v-subheader>

             <div class="ml-3" style="font-size: 14px; color: #757575;">Company:</div>
            <div class="ml-3 mb-3" style="font-size: 12px; color: #757575;">
               {{companyName}}
            </div>

<v-divider></v-divider>

            <v-list-item-group color="primary">
              <v-list-item
                  v-for="(item, i) in companyList"
                  :key="i"
                  link
                  @click="switchCompanyProfile(item)"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>


  <v-menu
      :nudge-width="120"
      offset-x
      offset-y
      open-on-hover
      origin="center center"
      transition="fab-transition"
  >
        <template v-slot:activator="{ on, attrs }">
          <span>
            <v-list-item-content v-bind="attrs" v-on="on">
              <v-list-item-title>
                <small>
                  {{userName}}<br />
                  {{userEmail}}
                </small>
              </v-list-item-title>
            </v-list-item-content>
          </span>

          <v-btn v-bind="attrs" v-on="on" icon>
            <v-avatar color="" size="35">
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card width="300px">
          <v-list-item dense>
            <v-avatar class="mr-3">
              <v-icon large style="color: #1976d2">mdi-account-circle</v-icon>
            </v-avatar>

            <v-list-item-content>
              <v-list-item-title class="mb-1 mt-2">
                <span>{{userName}}</span><br />
                 <span class="">{{userEmail}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list dense>
            <v-divider></v-divider>

            <v-list-item :to="{name:'profile'}">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Profile </v-list-item-title>
            </v-list-item>

            <v-list-item @click="doLogout">
              <v-list-item-icon>
                <v-icon>mdi-location-exit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

    </v-app-bar>

  </span>



</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReceivedMessages from "@/views/Messenger/MessengerCharts/ReceivedMessages";

export default {
  name: "VerticalMenu",
  components: {ReceivedMessages},
  data: () =>({
    drawer: true,
    messageCounterInfo: null,
    items: [
      { title: 'Home',name:'home', icon: 'mdi-home' },
      { title: 'Messenger',name:'messenger', icon: 'mdi-facebook-messenger' },
      { title: 'Contacts',name:'new-contact', icon: 'mdi-account' },
      { title: 'Users',name:'users', icon: 'mdi-account-plus' },
      // { title: 'Groups',name:'group', icon: 'mdi-account-multiple' },
      { title: 'Group Manager',name:'group-contacts', icon: 'mdi-account-multiple-plus' },
      { title: 'Connected Devices',name:'pair-phone', icon: 'mdi-cellphone' },
      { title: 'Import Contacts',name:'import-contacts', icon: 'mdi-download' },
      { title: 'Send From File',name:'send_from_file', icon: 'mdi-file' },
      { title: 'Company',name:'company', icon: 'mdi-account-settings' },
      { title: 'Message Logs',name:'message-logs', icon: 'mdi-message-text-outline' },
      { title: 'Group Message Logs',name:'group-message-logs', icon: 'mdi-account-switch' },
      { title: 'Call Logs',name:'call-logs', icon: 'mdi-phone-forward' },
      { title: 'Settings',name:'settings', icon: 'mdi-cog' }
    ],
    companyList: [],
    quickLinks: [
      {
        text: "Chart Of Accounts",
        icon: "mdi-chart-line",
        routeName: "chart-of-accounts",
        action: "view",
        resource: "chart"
      },
      {
        text: "Items List",
        icon: "mdi-format-list-bulleted",
        routeName: "item-list",
        action: "view",
        resource: "items"
      },
      {
        text: "Invoice",
        icon: "mdi-script",
        routeName: "invoice",
        action: "view",
        resource: "invoices"
      },
      {
        text: "Bill",
        icon: "mdi-receipt",
        routeName: "bill",
        action: "view",
        resource: "bills"
      },
      {
        text: "Customer",
        icon: "mdi-account-multiple-plus",
        routeName: "customer",
        action: "view",
        resource: "customers"
      },
      {
        text: "Supplier",
        icon: "mdi-account-multiple",
        routeName: "supplier",
        action: "view",
        resource: "suppliers"
      },
      {
        text: "Profit and Loss",
        icon: "mdi-elevation-rise",
        routeName: "profit-and-loss",
        action: "view",
        resource: "profitAndLoss"
      },
      {
        text: "Balance Sheet",
        icon: "mdi-scale",
        routeName: "balance-sheet",
        action: "view",
        resource: "balanceSheet"
      },
      {
        text: "Bank Accounts",
        icon: "mdi-bank",
        routeName: "storage-accounts",
        action: "view",
        resource: "bankAccounts"
      }
    ],
    mini: false,
    userName:null,
    userEmail:null,
    companyName:null,

  }),
  mounted() {
    let profile = this.$userProfile
    let compProfile = this.$companyProfile
    this.userName =  profile.surname+" "+profile.second_name+" "+profile.other_names
    this.userEmail =  profile.Contacts[1].contact
   this.companyName = compProfile.company_name
    this.fetchCompanyList()
    this.fetchMessageCount()
  },
  methods:{
    ...mapActions(
        [
            "fetchCompanyList",
            "doSwitchCompanyProfile",
            "fetchMessageCount"
        ]
    ),
    doLogout(){
      localStorage.clear();
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
    filterCompanyList(dataOBJ){
      if(dataOBJ) {
        let jsn = {};
        let arr = [];

        dataOBJ.forEach(list => {
          jsn = {
            name: list.CompanyProfile.company_name,
            id: list.company_profile_number,
            user_number: list.user_number,
          }
          arr.push(jsn)
        })
        this.companyList = arr;
      }
  },
    switchCompanyProfile(item){
     let data = {
        user_number:item.user_number,
        company_number:item.id
      }
       this.doSwitchCompanyProfile(data)
},
   /* messageCounter(data){
      if(data){
        this.messageCounterInfo = data.toLocaleString()
      }
    }*/
  },
  computed:{
    ...mapGetters(
        [
            "getCompanyList",
            "getCompanyListError",
            "getSwitchCompanyProfile",
            "getMessageCount"
        ]
    )
  },
  watch:{
    getCompanyList(data){
      if(data){
        this.filterCompanyList(data.data)
      }
    },
    getCompanyListError(data){
      if(data){
        this.errorToast("Error", data.message)
      }
    },
    getSwitchCompanyProfile(data){
      if(data){
        localStorage.setItem("userProfile", JSON.stringify(data.user));
        localStorage.setItem("companyProfile", JSON.stringify(data.company));
        localStorage.setItem("token", data.token);
        this.$router.go()
      }
    },
    getMessageCount(data){
      if(data){
        this.messageCounterInfo = data.data.toLocaleString()
      }
    }
  }
}
</script>

<style scoped>

</style>