import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    usersList: null,
    usersError: null,
};

const getters = {
    getUsersList: state => state.usersList,
    getUsersError: state => state.usersError,
};

const actions = {

    async fetchUsersPaginated({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let userStatus = data?data.userStatus ? data.userStatus: "":""
        let userGender = data?data.userGender ? data.userGender: "":""
        await axios
            .get(IP +
                "message/users-list-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                "&search="+search+
                "&userStatus="+userStatus+
                "&userGender="+userGender
            )
            .then(response => {
                commit("setUsersList", response);
            })
            .catch(error => {
                commit("setUsersError", error);
            });
    },


};

const mutations = {
    setUsersList: (state, response) => {
        state.usersList = response.data;
    },
    setUsersError: (state, error) => {
        state.usersError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

