<template>
  <v-app id="app" style="background-color: #f0f2f5;">
    <notifications group="foo" position="bottom right" />
    <div class="app-holder">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </v-app>
</template>

<script>
// import SideBar from './components/SideBar';

export default {
  name: 'App',

  components: {
    // SideBar,
  },

  data: () => ({
    //
  }),
};
</script>

<style scoped>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: 100%;
}
html,
body {
  height: 100%;
}

.app-holder {
  height: 100%;
}



*{
  scrollbar-width: thin;
  scrollbar-color: #cdcdcd #f0f0f0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  /*background: linear-gradient(to top, #33ccff 0%, #cc00ff 100%)*/
}

</style>
