<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
     <v-form @submit.prevent="registerGroup">

        <v-card-text>

          <v-text-field
              outlined
              v-model="form.group_name"
              label="Group Name"
              required
              type="text"
              dense
          ></v-text-field>

            <v-textarea
                v-model="form.grp_desc"
                label="Description"
                outlined
                rows="2"
                dense
            ></v-textarea>



          <v-row class="mx-auto">
                <v-btn
                    @click="clearGroupForm"
                    outlined
                    class="mt-2"
                >
             Clear
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                class="mt-2"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
             {{groupInfo ? "Edit Group" : "Save Group"}}
            </v-btn>
          </v-row>


        </v-card-text>
      </v-form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "NewGroup",
  props:{
      groupInfo:{}
  },
  data(){
    return{
      errorMessage:null,
      spinner:false,
      form:{
        group_name:null,
        grp_desc:null,
      },
      groupId:null
    }
  },
  mounted() {
    this.checkGroupInfo()
  },
  methods:{
    ...mapActions(
        [
            "saveNewGroup",
            "fetchGroupInfo",
            "doUpdateGroup"
        ]
    ),
    registerGroup(){
      this.errorMessage = ""
      this.spinner = true
      let formData = this.form
      if(this.groupId){
        formData.groupId = this.groupId
        this.doUpdateGroup(formData)
      }else {
        this.saveNewGroup(formData)
      }
    },
    checkGroupInfo(){
      this.fetchGroupInfo(this.groupInfo.number)
    },
    clearGroupForm(){
      this.form = {
        group_name:null,
        grp_desc:null,
      }
    }
  },
  computed:{
    ...mapGetters(
        [
            "getNewGroupError",
            "getGroupDetails"
        ]
    )
  },
  watch:{
    getNewGroupError(data){
      if(data){
        this.errorToast("Error", data.message)
        this.errorMessage = data.message
        this.spinner = false
      }
    },
    groupInfo(){
      this.checkGroupInfo()
    },
    getGroupDetails(data){
      if(data){
        let groupData = data.data
        this.form.group_name =  groupData.grp_name
        this.form.grp_desc =  groupData.grp_desc
        this.groupId =  groupData.number
      }
    }
  }
}
</script>

<style scoped>

</style>