<template>
<span>
  <v-card class="mb-10" style="margin-top: 14%; box-shadow: 2px 2px  #01a59a;">
    <v-card-text>



      <div class="mt-4" style="text-align: center;">
        <span style="font-size: 25px;">Noregrets</span> <br />
        Create Account
      </div>

      <v-form ref="form" @submit.prevent="registerUser" lazy-validation>
        <v-alert type="error" v-if="registerError">
          {{ registerError }}
        </v-alert>
        <v-card-text>

          <v-text-field
              outlined
              :rules="[rules.surname]"
              v-model="form.surname"
              label="Surname"
              required
              prepend-inner-icon="mdi-account"
              type="text"
              dense
          ></v-text-field>


           <v-text-field
               outlined
               :rules="[rules.second_name]"
               v-model="form.second_name"
               label="Second Name"
               required
               prepend-inner-icon="mdi-account"
               type="text"
               dense
           ></v-text-field>

           <v-text-field
               outlined
               :rules="[rules.other_names]"
               v-model="form.other_names"
               label="Other Names"
               required
               prepend-inner-icon="mdi-account"
               type="text"
               dense
           ></v-text-field>


          <v-select
              v-model="form.gender"
              label="Gender"
              :items="genderList"
              item-value="id"
              outlined
              required
              prepend-inner-icon="mdi-gender-male-female"
              dense
          ></v-select>

<!--          <v-text-field
              outlined
              v-model="form.email"
              :rules="[rules.email]"
              label="Email"
              required
              prepend-inner-icon="mdi-email-outline"
              type="email"
              dense
          ></v-text-field>

          <v-text-field
              outlined
              v-model="form.phone"
              :rules="[rules.phone]"
              label="Phone"
              required
              prepend-inner-icon="mdi-phone"
              type="tel"
              dense
          ></v-text-field>-->

            <div
                v-for="(mail, index) in form.email"
                :key="index"
            >
          <v-text-field
              hide-details
              class="mb-5"
              outlined
              v-model="mail.mail"
              :label="'Email '+(index+1)"
              required
              prepend-inner-icon="mdi-email-outline"
              type="email"
              dense
              :append-outer-icon="index > 0 ? 'mdi-minus' : ''"
              @click:append-outer="removeEmail(index)"
          ></v-text-field>
          </div>

<v-row class="mx-auto">
  <v-spacer></v-spacer>
            <v-btn
                class="mb-5"
                color="#01a59a"
                dark
                type="button"
                small
                outlined
                @click="addEmail()"
            >
              <v-icon dark>mdi-plus</v-icon>
             Add Email
            </v-btn>
</v-row>

  <div
      v-for="(phoneNo, index2) in form.phone"
      :key="index2"
  >
          <v-text-field
              @keypress="onlyNumberValidator($event)"
              maxlength="12"
              class="mb-5"
              hide-details
              outlined
              v-model="phoneNo.phoneNumber"
              :label="'Phone '+(index2+1)"
              required
              prepend-inner-icon="mdi-phone"
              type="tel"
              dense
              :append-outer-icon="index2 > 0 ? 'mdi-minus' : ''"
              @click:append-outer="removePhone(index2)"
          ></v-text-field>
  </div>

          <v-row class="mx-auto">
  <v-spacer></v-spacer>
             <v-btn
                 class="mb-5"
                 color="#01a59a"
                 dark
                 type="button"
                 small
                 outlined
                 @click="addPhone()"
             >
              <v-icon dark>mdi-plus</v-icon>
             Add Phone
            </v-btn>
          </v-row>


               <v-text-field
                   outlined
                   :rules="[rules.company_name]"
                   v-model="form.company_name"
                   label="Company Name"
                   required
                   prepend-inner-icon="mdi-account-multiple"
                   type="text"
                   dense
               ></v-text-field>

          <v-row>
      <v-col cols="12" sm="12" md="6" lg="6">

          <v-text-field
              outlined
              v-model="form.password"
              :rules="[rules.password_required]"
              :type="show1 ? 'text' : 'password'"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              label="Password"
              required
              @click:append="show1 = !show1"
              dense
              prepend-inner-icon="mdi-lock-outline"
          >
              </v-text-field>
      </v-col>

             <v-col cols="12" sm="12" md="6" lg="6">
              <v-text-field
                  outlined
                  v-model="form.confirm_password"
                  :rules="[rules.confirm_password_required, password_match]"
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  label="Confirm Password"
                  required
                  @click:append="show1 = !show1"
                  dense
                  prepend-inner-icon="mdi-lock-outline"
              >
              </v-text-field>
             </v-col>
          </v-row>






          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <v-btn
                class="mb-7"
                :loading="registerSpinner"
                color="#01a59a"
                dark
                block
                rounded
                type="submit"
                large
            >
              Register
            </v-btn>
          </v-row>


          <div class="text-center">
            Already have an account? &nbsp; &nbsp;
            <router-link style="color: #242f7e;" :to="{ name: 'login' }">
              Login
            </router-link>
          </div>
        </v-card-text>
      </v-form>
    </v-card-text>
  </v-card>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import router from "@/Global/router";

export default {
  name: "Register",
  data(){
    return{
      registerError:null,
      registerSpinner:false,
      show1: false,
      form:{
        surname:null,
        second_name:null,
        other_names:null,
        email: [
          {mail:null, number: null}
        ],
        phone: [
          {phoneNumber:null, number: null}
        ],
        gender:null,
        company_name:null,
        password:null,
        confirm_password:null,
      },
      rules: {
        surname: value => !!value || "Surname is Required.",
        second_name: value => !!value || "Second Name is Required.",
        other_names: value => !!value || "Other names is Required.",
        company_name: value => !!value || "company name is Required.",
        phone: value => !!value || "Phone is Required.",
        email: value => !!value || "Email is Required.",
        password_required: value => !!value || "Password is Required.",
        confirm_password_required: value => !!value || "Confirm Password is Required.",
      },
      genderList:[
        {text:"Male", id:"Male"},
        {text:"Female", id:"Female"}
      ]
    }
  },
  methods:{
    ...mapActions(
        [
          "registerNewUser"
        ]
    ),
    registerUser(){
      this.registerError = ""
      if(this.form.password !== this.form.confirm_password){
        this.registerError = "Password Mismatch"
        return
      }

      this.registerSpinner = true

      this.registerNewUser(this.form)
    },
    addEmail(){
      this.form.email.push({mail: null, number: null})
    },
    addPhone(){
      this.form.phone.push({phoneNumber: null, number: null})
    },
    removePhone(index){
      this.form.phone.splice(index, 1)
    },
    removeEmail(index){
      this.form.email.splice(index, 1)
    },
  },
  computed:{
    ...mapGetters(
        [
          "getNewUserError",
          "getNewUser"
        ]
    ),
    password_match() {
      return () =>
          this.form.password === this.form.confirm_password ||
          "Password Mismatch.";
    }

  },
  watch:{
    getNewUserError(data){
      if(data){
        this.errorToast("Error", data.message)
        this.registerError = data.message
        this.registerSpinner = false
      }
    },
    getNewUser(data){
      if(data){
        this.successToast("Success", data.message)
        this.registerSpinner = false
        this.form = {
          surname:null,
          second_name:null,
          other_names:null,
          email:null,
          phone:null,
          gender:null,
          company_name:null,
          password:null,
          confirm_password:null,
        }
        router.push({name:"login"})
      }
    }
  }
}
</script>

<style scoped>

</style>