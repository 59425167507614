import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP_IMPORT;

const state = {
    newSendFromFile: null,
    newSendFromFileError: null,
};

const getters = {
    getNewSendFromFile: state => state.newSendFromFile,
    getSendFromFileError: state => state.newSendFromFileError,
};

const actions = {
    async sendMessageFromFile({commit}, data) {
        await axios
            .post(IP + "send-from-file", data)
            .then(response => {
                commit("setNewSendFromFile", response);
            })
            .catch(error => {
                commit("setNewSendFromFileError", error);
            });
    },


};

const mutations = {
    setNewSendFromFile: (state, response) => {
        state.newSendFromFile = response.data;
    },
    setNewSendFromFileError: (state, error) => {
        state.newSendFromFileError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

