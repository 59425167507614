<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Contacts</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2"
              dark
              color="teal"
              small
              @click="doAddNewContact"
          >
      <v-icon dark>
       mdi-plus
      </v-icon>
            New Contact
    </v-btn>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat>
                <v-row class="mx-auto mt-5">
                <v-col cols="8"></v-col>
                <v-col cols="4">
                   <v-text-field
                       v-model="search"
                       append-icon="mdi-magnify"
                       label="Search"
                       dense
                       outlined
                       clearable
                       persistent-hint
                       hint="Press enter to search"
                       v-on:keyup.enter="filterList"
                   ></v-text-field>
                </v-col>
            </v-row>



                <v-divider></v-divider>
                   <v-progress-linear
                       v-if="tableLoader"
                       indeterminate
                       color="#242f7e"
                       class="mb-0"
                       height="2"
                   ></v-progress-linear>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>



             <v-simple-table
                 fixed-header
             >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Gender
                      </th>
                      <th class="text-left">
                        Phone
                      </th>
                      <th class="text-left">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.number"
                    >
                      <td>{{ item.key }}</td>
                      <td>
                      <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:item.number, q: (item.contact).replace('+', '') }}">
                              <v-icon>
                                mdi-account
                              </v-icon>
                           {{item.name}}
                            </router-link>
                      </td>
                      <td>{{ item.gender }}</td>
                      <td>
                    <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:item.number, q: (item.contact).replace('+', '') }}">
                           {{item.contact}}
                      </router-link>
                      </td>
                      <td>

                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                               <v-icon  v-bind="attrs"
                                        v-on="on" small class="mr-2" @click="editContact(item)">
                              mdi-pencil
                            </v-icon>
                            </template>
                            <span>Edit Contact</span>
                          </v-tooltip>


                      </td>

                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>



        </v-card>






        </v-card>
      </v-flex>
    </v-layout>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="contactDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>New Contact</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="contactDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

<!--            <new-contact ref="contact_component"></new-contact>-->
           <new-user ref="user_component"></new-user>

         </v-card>





      </v-card>
    </v-dialog>

   <v-dialog
       transition="dialog-bottom-transition"
       v-model="editContactDialog"
       width="42%"
   >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit Contact</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editContactDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

            <new-user :userInfo="contactInfo" ref="edit_user_component"></new-user>

         </v-card>


      </v-card>
    </v-dialog>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewUser from "@/views/Main/users/NewUser";
export default {
  name: "Contacts",
  components: {NewUser},
  data(){
    return{
      search: null,
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page_options: [20, 50, 100, 500],
      items_per_page: 15,
      server_items_length: 0,
      contactDialog:false,
      editContactDialog:false,
      generalTableData: [],
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Name", value: "name",  width: "30%",},
        {text: "Gender", value: "gender"},
        {text: "Phone", value: "contact"},
      ],
      contactInfo:[]
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
          "fetchContactListPaginated"
        ]
    ),
    doAddNewContact(){
      this.contactDialog = true
    },
    editContact(item){
      this.editContactDialog = true
      this.contactInfo = {number:item.userNumber}
    },
    filterContactData(data){
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.tableLoader = false;

      let tableJsn = {};
      // let tableObj = [];

      dataObj.forEach((list, i) => {
            let user = list.User
            tableJsn = {
              key: (current_page - 1) * per_page + i + 1,
              // key: i,
              contact: list.contact,
              name: user.surname+" "+user.second_name+" "+user.other_names,
              gender:user.gender,
              email:"",
              number: list.number,
              userNumber: user.number,
            };
            // tableObj.push(tableJsn);
            const index = this.generalTableData.findIndex(object => object.number === tableJsn.number);

            if (index === -1) {
              this.generalTableData.push(tableJsn);
            }
          }
      )

      // this.generalTableData = tableObj;

    },
    filterList() {
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
      }
      this.fetchContactListPaginated(options)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.tableLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
        }
        this.fetchContactListPaginated(options)
      }
    },
  },
  computed:{
    ...mapGetters(
        [
          "getNewContact",
          "getContactListPaginated",
          "getEditUserContactInfo",
          "getNewUser",
          "getEditUserInfo"
        ]
    )
  },
  watch:{
    pagination(obj) {
      this.tableLoader = true

      let options = {
        page: obj.page,
        per_page: obj.itemsPerPage,
        search: this.search
      };
      this.fetchContactListPaginated(options)
    },
  /*  getNewContact(data){
      if(data){
        this.successToast("Success", data.message)
        this.contactDialog = false
        this.$refs["contact_component"].spinner = false
        this.$refs["contact_component"].form = {
          surname:null,
          second_name:null,
          other_names:null,
          phone:null,
          gender:null,
        }
        this.filterList()

      }
    },*/
    getContactListPaginated(data){
      if(data){
        this.filterContactData(data)
      }
    },
    getNewUser(data){
      if(data){
        this.$refs["user_component"].spinner = false
        this.$refs["user_component"].form = {
          surname: null,
          second_name: null,
          other_names: null,
          email: [
            {mail:null}
          ],
          phone: [
            {phoneNumber:null}
          ],
          gender: null,
        }
        this.contactDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
    getEditUserInfo(data){
      if(data) {
        this.$refs["edit_user_component"].spinner = false
        this.editContactDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
  }
}
</script>

<style scoped>

</style>