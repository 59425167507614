import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
  rolesAndPermission: null,
  newRole: null,
  newPermission:null,
  rolesAndPermissionList: null,
  roleList:null,
  roleDetails: null,
  permissions:null,
  error:null
};

const getters = {
  getNewRole: state => state.newRole,
  getRolesAndPermissionList: state => state.rolesAndPermissionList,
  getPermmissions: state => state.editRolesAndPermission,
  getUpdateRolesAndPermission: state => state.updateRolesAndPermission,
  getRevertRoles: state => state.revertRolesAndPermissionList,
  getRolesAndPermissionError: state => state.error
};

const actions = {
  async getRolesList({ commit }) {
    await axios
      .get(IP + "roles/")
      .then(response => {
        commit("setRolesAndPermission", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async doSaveUserRolesAndPermissions({ commit }, data) {
    await axios
      .post(IP + "roles/new", data)
      .then(response => {
        commit("setNewRoleData", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async fetchRoleInfo({ commit }, data) {
    await axios
      .get(IP + "/roles/details/" + data)
      .then(response => {
        commit("setEditRoleData", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async updateRoleAndPermission({ commit }, data) {
    await axios
      .post(IP + "/roles/update/", data)
      .then(response => {
        commit("setUpdateRoleData", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async getUserRolesAndPermissionsList({ commit }, data) {
    await axios
      .get(
        IP + "roles/?page=" + data.page + "&per_page=" + data.per_page
      )
      .then(response => {
        commit("setRolesError", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async savePermission({ commit }, data) {
    await axios
      .post(IP + "/roles/new-permission", data)
      .then(response => {
        commit("setNewPermissionData", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  },
  async doRevertRole({ commit }, data) {
    await axios
      .post(
        IP +
          "roles/remove-role?role_number=" +
          data.role_number +
          "&user_number=" +
          data.user_number
      )
      .then(response => {
        commit("setRevokeRole", response);
      })
      .catch(error => {
        commit("setRolesError", error);
      });
  }
};

const mutations = {
  setRolesAndPermission: (state, response) => {
    state.rolesAndPermission = response.data;
  },
  setNewRoleData: (state, response) => {
    state.newRolesAndPermission = response.data;
  },
  setEditRoleData: (state, response) => {
    state.editRolesAndPermission = response.data;
  },
  setUpdateRoleData: (state, response) => {
    state.updateRolesAndPermission = response.data;
  },
  setRolesAndPermissionList: (state, response) => {
    state.rolesAndPermissionList = response.data;
  },
  setRevokeRole: (state, response) => {
    state.revertRolesAndPermissionList = response.data;
  },
  setRolesError: (state, error) => {
    state.error = error.response
      ? error.response.data
      : "Error: Network Error";
  },
    setNewPermissionData: (state, response) => {
    state.newPermission = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations
};
