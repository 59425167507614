<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Company</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2"
              dark
              color="teal"
              small
              @click="doAddNewCompany"
          >
            <v-icon dark>
             mdi-plus
            </v-icon>
            New Company
    </v-btn>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat>


                <v-row class="mx-auto mt-5">
                   <v-col cols="2">
                     <v-autocomplete
                         clearable
                         v-model="userStatus"
                         :items="statusList"
                         item-value="id"
                         label="Status"
                         dense
                         v-on:change="filterList"
                         hide-details
                     ></v-autocomplete>
                    </v-col>
<v-col cols="6"></v-col>
<v-col cols="4">
   <v-text-field
       clearable
       v-model="search"
       append-icon="mdi-magnify"
       label="Search"
       dense
       outlined
       persistent-hint
       hint="Press enter to search"
       v-on:keyup.enter="filterList"
   ></v-text-field>
</v-col>

            </v-row>


              <v-divider></v-divider>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>



             <v-simple-table
                 fixed-header
             >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Action
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.number"
                    >
                      <td>{{ item.key }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.status }}</td>
                      <td width="5%">
                 <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                               <v-icon  v-bind="attrs"
                                        v-on="on" small class="mr-2" @click="editCompany(item)">
                              mdi-pencil
                            </v-icon>
                            </template>
                            <span>Edit Company</span>
                          </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>

        </v-card>



        </v-card>

      </v-flex>
    </v-layout>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="companyDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>New Company</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="companyDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-company ref="company_component"></new-company>

         </v-card>


      </v-card>
    </v-dialog>

   <v-dialog
        transition="dialog-bottom-transition"
        v-model="editCompanyDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit Company</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editCompanyDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-company :companyInfo="companyInfo" ref="edit_company_component"></new-company>

         </v-card>


      </v-card>
    </v-dialog>





</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewCompany from "@/views/Main/company/NewCompany";

export default {
  name: "Company",
  components: {NewCompany},
  data(){
    return{
      search: null,
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page: 20,
      items_per_page_options: [20, 50, 100, 500],
      server_items_length: 0,
      contactDialog:false,
      generalTableData: [],
      companyDialog:false,
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Name", value: "name", width: "30%"},
        {text: "Status", value: "status" , width: "50%"},
      ],
      groupInfo:{},
      groupContactDialog:false,
      companyInfo:{},
      editCompanyDialog:false,
      userStatus: null,
      statusList:[
        { text: '--Select Status--', id:"" },
        { text: 'Active', id:"Active" },
        { text: 'Inactive', id:"Inactive" },
      ],
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
          "fetchCompanyPaginated"
        ]
    ),
    doAddNewCompany(){
      this.companyDialog = true
    },
    filterList(){
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        userStatus: this.userStatus,
      }
      this.fetchCompanyPaginated(options)
    },
    filterCompanyData(data){

      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

     /* let raw_data = data.data
      let dataObj = raw_data.records;

      this.server_items_length = raw_data.total_records;

      let current_page = (raw_data.current_page * 1);

      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;*/

      this.tableLoader = false;


      let obj = {};


      dataObj.forEach((list, i) => {
        let company = list.CompanyProfile
        obj = {
          key: (current_page - 1) * per_page + i + 1,
          name: company.company_name,
          status: company.company_status ? "Active" : "Inactive",
          number: company.number,
        }

        const index = this.generalTableData.findIndex(object => object.number === obj.number);

        if (index === -1) {
          this.generalTableData.push(obj);
        }

      })



    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.messageListLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
          userStatus: this.userStatus,
        }
        this.fetchCompanyPaginated(options)
      }
    },
    editCompany(item){
      this.editCompanyDialog = true
      this.companyInfo = item
    }
  },
  computed:{
    ...mapGetters(
        [
          "getNewCompanyList",
          "getNewCompanyData",
            "getEditCompanyDataInfo"
        ]
    )
  },
  watch:{
    /*    pagination(obj) {
          this.tableLoader = true

          let options = {
            page: obj.page,
            per_page: obj.itemsPerPage,
            search: this.search
          };

          this.fetchCompanyPaginated(options)
        },*/
    getNewCompanyList(data){
      if(data) {
        this.filterCompanyData(data)
      }
    },
    getNewCompanyData(data){
      if(data){
        this.$refs["company_component"].spinner = false
        this.$refs["company_component"].form = {
          company_name:null
        }
        this.companyDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
    getEditCompanyDataInfo(data){
      if(data){
        this.$refs["edit_company_component"].spinner = false
        this.$refs["edit_company_component"].form = {
          company_name:null
        }
        this.editCompanyDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    }
  }
}
</script>

<style scoped>

</style>