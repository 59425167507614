<template>
<span>
  <v-card
      outlined
      max-height="640px"
      style="
      width: 100%;
      overflow: scroll;
      margin-bottom: 13px;
      "
  >
   <v-row class="mx-auto">
     <v-col>

        <v-card outlined class="mb-5 mt-5 pa-4" v-for="(device, index) in connectedDeviceData" :key="index">
         <v-row class="mx-auto mb-2">
         <div>
           {{ device.Device.device_desc }}<br/>
         <small style="color: #1976d2;">Last Seen: <span class="ml-3">{{ device.Device.UpdatedAt }}</span></small>
         </div>
         <v-spacer></v-spacer>

         </v-row>
          <v-divider></v-divider>

         <div class="ml-4 mb-3 mt-2" v-for="(deviceInfo, index) in device.DeviceInfo" :key="index">

           <v-row class="mx-auto mt-3 mb-1">
           <div>
             {{ deviceInfo.DevicePhone.phone_number }}
             <small class="ml-3"><i>[{{ deviceInfo.DevicePhone.phone_sim }}]</i></small>

              <v-chip
                  outlined
                  small
                  class="ml-3"
                  :color="deviceInfo.is_active ? 'green' : 'red'"
                  label
              >
                {{ deviceInfo.is_active ? "Active" : "Inactive" }}
              </v-chip>

               <v-chip
                   v-if="deviceInfo.is_default"
                   small
                   class="ml-3"
                   color="primary"
                   label
               >
      Default sender phone
    </v-chip>

           </div>

<v-spacer></v-spacer>

               <v-progress-circular
                   class="mr-2"
                   v-if="deviceLoader === deviceInfo.number"
                   size="20"
                   indeterminate
                   color="#787878"
               ></v-progress-circular>

<div>
     <v-tooltip bottom v-if="deviceInfo.is_default === 1">
      <template v-slot:activator="{ on, attrs }">

         <v-icon
             class="mr-2"
             @click="setDefaultSender(deviceInfo)"
             style="cursor: pointer"
             v-bind="attrs"
             v-on="on"
             size="20"
             color="green">
           mdi-phone-classic
         </v-icon>

      </template>
      <span>Unset Default Sender Phone</span>
    </v-tooltip>

      <v-tooltip bottom v-if="deviceInfo.is_default === 0">
      <template v-slot:activator="{ on, attrs }">

         <v-icon
             class="mr-2"
             @click="setDefaultSender(deviceInfo, index)"
             style="cursor: pointer"
             v-bind="attrs"
             v-on="on"
             size="20"
             color="red">
           mdi-phone-minus
         </v-icon>

      </template>
      <span>Set Default Sender</span>
    </v-tooltip>



   <v-tooltip bottom v-if="deviceInfo.is_active === 1">
      <template v-slot:activator="{ on, attrs }">

         <v-icon
             class="mr-2"
             @click="setEnableDisableNumber(deviceInfo, index)"
             style="cursor: pointer"
             v-bind="attrs"
             v-on="on"
             size="20"
             color="green">
          mdi-checkbox-marked-circle-outline
         </v-icon>

      </template>
      <span>Disable Number</span>
    </v-tooltip>

    <v-tooltip bottom v-if="deviceInfo.is_active === 0">
      <template v-slot:activator="{ on, attrs }">

         <v-icon
             class="mr-2"
             @click="setEnableDisableNumber(deviceInfo)"
             style="cursor: pointer"
             v-bind="attrs"
             v-on="on"
             size="20"
             color="red">
         mdi-close-circle-outline
         </v-icon>

      </template>
      <span>Enable Number</span>
    </v-tooltip>
</div>

</v-row>

           <small>Paired by: {{ deviceInfo.DevicePhone.User.surname + " " + deviceInfo.DevicePhone.User.second_name + " " + deviceInfo.DevicePhone.User.other_names }}</small>
           <br/>
           <small>company: {{ deviceInfo.CompanyProfile.company_name }}</small>

            <v-divider></v-divider>

         </div>

       </v-card>

     </v-col>
   </v-row>
  </v-card>
</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ConnectedDevices",
  data() {
    return {
      connectedDeviceData: null,
      deviceLoader: undefined
    }
  },
  mounted() {
    this.fetchConnectedDevices()
  },
  methods: {
    ...mapActions(
        [
          "fetchConnectedDevices",
          "setDefaultPhoneSender",
          "enableDisablePhoneNumber"
        ]
    ),
    setDefaultSender(data) {
      this.deviceLoader = data.number
      this.setDefaultPhoneSender({
        deviceNo: data.number,
        isDefault: data.is_default ? 'true' : 'false',
        devicePhoneNumber: data.device_phone_number
      })
    },
    setEnableDisableNumber(data) {
      this.deviceLoader = data.number
      this.enableDisablePhoneNumber({
        deviceNo: data.number,
        isActive: data.is_active ? 'true' : 'false',
        isDefault: data.is_default ? 'true' : 'false',
        devicePhoneNumber: data.device_phone_number
      })
    }
  },
  computed: {
    ...mapGetters(
        [
          "getPairDeviceData",
          "getDefaultSenderData",
          "getEnableDisablePhoneNumberData",
          "getPairPhoneError"
        ]
    ),
  },
  watch: {
    getPairDeviceData(data) {
      if (data) {
        this.connectedDeviceData = data.data
      }
    },
    getDefaultSenderData(data) {
      if (data) {
        this.fetchConnectedDevices()
        this.deviceLoader = undefined
        this.successToast("Success", data.message)
      }
    },
    getEnableDisablePhoneNumberData(data) {
      if (data) {
        this.fetchConnectedDevices()
        this.deviceLoader = undefined
        this.successToast("Success", data.message)
      }
    },
    getPairPhoneError(data) {
      if (data) {
        this.deviceLoader = undefined
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>