import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    messageLogsList: null,
    groupMessageLogsList: null,
    messageStatus: null,
    messageLogsError: null,
    batchMessageStatusRetryError: null,
    messageSenderError: null,
    batchMessageStatusRetry: null,
    messageSender: null,
};

const getters = {
    getMessageLogsList: state => state.messageLogsList,
    getMessageStatus: state => state.messageStatus,
    getGroupMessageLogsList: state => state.groupMessageLogsList,
    getMessageLogsError: state => state.messageLogsError,
    getMessageSender: state => state.messageSender,
    getMessageSenderError: state => state.messageSenderError,
    getBatchMessageStatusRetry: state => state.batchMessageStatusRetry,
    getBatchMessageStatusRetryError: state => state.batchMessageStatusRetryError,
};

const actions = {

    async fetchMessageLogsPaginated({commit}, data) {
        let search = data ? data.search ? data.search : "" : ""
        let msg_status = data ? data.msg_status ? data.msg_status : "" : ""
        let sender_phone = data ? data.sender_phone ? data.sender_phone : "" : ""
        let start_date = data ? data.start_date ? data.start_date : "" : ""
        let end_date = data ? data.end_date ? data.end_date : "" : ""
        let msg_type = data ? data.msg_type ? data.msg_type : "" : ""
        await axios
            .get(IP +
                "message/message-logs-list-paginated?page=" + data.page +
                "&per_page=" + data.per_page +
                "&search=" + search+
                "&msg_status=" + msg_status+
                "&sender_phone=" + sender_phone+
                "&start_date=" + start_date+
                "&end_date=" + end_date+
                "&msg_type=" + msg_type
            )
            .then(response => {
                commit("setMessageLogsList", response);
            })
            .catch(error => {
                commit("setMessageLogsError", error);
            });
    },
    async fetchGroupMessageLogsPaginated({commit}, data) {
        let search = data ? data.search ? data.search : "" : ""
        let userStatus = data ? data.userStatus ? data.userStatus : "" : ""
        let start_date = data ? data.start_date ? data.start_date : "" : ""
        let end_date = data ? data.end_date ? data.end_date : "" : ""
        await axios
            .get(IP +
                "message/group-message-logs-list-paginated?page=" + data.page +
                "&per_page=" + data.per_page +
                "&search=" + search+
                "&userStatus=" + userStatus+
                "&start_date="+start_date+
                "&end_date="+end_date
            )
            .then(response => {
                commit("setGroupMessageLogsList", response);
            })
            .catch(error => {
                commit("setMessageLogsError", error);
            });
    },
    async updateMessageStatus({commit}, data) {
        await axios
            .post(IP + "message/change-message-status", data)
            .then(response => {
                commit("setMessageStatus", response);
            })
            .catch(error => {
                commit("setMessageLogsError", error);
            });
    },
    async retryCancelMessageStatus({commit}, data) {
        await axios
            .post(IP + "message/batch-message-status-retry", data)
            .then(response => {
                commit("setBatchMessageStatusRetry", response);
            })
            .catch(error => {
                commit("setBatchMessageStatusRetryError", error);
            });
    },
    async updateMessageSenderPhone({commit}, data) {
        await axios
            .post(IP + "message/change-message-sender", data)
            .then(response => {
                commit("setMessageSender", response);
            })
            .catch(error => {
                commit("setMessageSenderError", error);
            });
    },

};

const mutations = {
    setMessageStatus: (state, response) => {
        state.messageStatus = response.data;
    },
    setBatchMessageStatusRetry: (state, response) => {
        state.batchMessageStatusRetry = response;
    },
    setMessageSender: (state, response) => {
        state.messageSender = response;
    },
    setMessageLogsList: (state, response) => {
        state.messageLogsList = response.data;
    },
    setGroupMessageLogsList: (state, response) => {
        state.groupMessageLogsList = response.data;
    },
    setMessageLogsError: (state, error) => {
        state.messageLogsError = error.response ? error.response.data : "Network Error!";
    },
    setBatchMessageStatusRetryError: (state, error) => {
        state.batchMessageStatusRetryError = error.response ? error.response : "Network Error!";
    },
    setMessageSenderError: (state, error) => {
        state.messageSenderError = error.response ? error.response : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

