<template>
<span>
<div style="background-color: #f0f2f5; height:100vh;">
  <v-app-bar
      app
      color="#01a59a"
      dark
      dense
  >
      <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-4"
      >

          <router-link
              style="color: #ffffff;"
              class="text-decoration-none"
              :to="{ name: 'login' }"
          >
            <span class="hidden-sm-and-down">Noregrets</span>
            </router-link>

      </v-toolbar-title>




    </v-app-bar>

  <v-row
      style="height: 100%;"
      align="center"
       justify="center">
    <v-col cols="11" sm="11" md="6" lg="4" class="mx-auto">
       <router-view></router-view>
    </v-col>
  </v-row>



  <m-footer></m-footer>
</div>
</span>
</template>

<script>
import MFooter from "../Commons/MFooter";
export default {
  name: "AuthDashboard",
  components: {MFooter}
}
</script>

<style scoped>

</style>