import Vue from 'vue'
import App from './App.vue'
import router from './Global/router'
import store from './Global/store'
import vuetify from './plugins/vuetify';
import Notifications from "vue-notification";
import axios from "axios";
import VueChatScroll from 'vue-chat-scroll';



Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(VueChatScroll);

// localStorage.clear();

const user = localStorage.getItem("userProfile");
const company = localStorage.getItem("companyProfile");
if (user != null) {
  let usr = JSON.parse(user);
  let comp = JSON.parse(company);
  let token = localStorage.getItem("token");

  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  Vue.prototype.$userProfile = usr;
  Vue.prototype.$companyProfile = comp;

  // axios.defaults.headers.common["user-number"] = usr.number;
  // axios.defaults.headers.common["company-number"] = comp.number;

  axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (!error.response) {
          /* alert(
             "Please check your internet connection. \n Try again or reload the page to continue"
           );*/
        }

        if (error.response.status === 401) {
          localStorage.clear();
          /* if (
             !alert("Your session has expired! \n reload the page to continue")
           ) {
             window.location.reload();
           }*/
          window.location.reload();
        } else if (error.response.status >= 500){
          store.dispatch('notifySupportError',"That's our fault, kindly seek technical support")
          return error
        }
        return Promise.reject(error);
      }
  );
}


Vue.mixin({
  methods: {
    successToast: function (
        title = "Success",
        text = "Request was processed successfully"
    ) {
      this.$notify({
        group: "foo",
        type: "success",
        title: title,
        text: text
      });
    },
    errorToast: function (
        title = "Error",
        text = "Something went wrong try again later"
    ) {
      this.$notify({
        group: "foo",
        type: "error",
        title: title,
        text: text
      });
    },
    warningToast: function (
        title = "Warning",
        text = "It seems something is not right"
    ) {
      this.$notify({
        group: "foo",
        type: "warn",
        title: title,
        text: text
      });
    },
    onlyNumberValidator(event) {
      if (!/[\d]/.test(event.key)) return event.preventDefault();
    },
    timeAgo(date){


      let intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 },
        { label: 'second', seconds: 1 }
      ];

        const seconds = Math.floor((Date.now() - date.getTime()) / 1000);

        const interval = intervals.find(i => i.seconds < seconds);

        const count = interval ? Math.floor(seconds / interval.seconds) : "";

        return interval ? `${count} ${interval.label}${count !== 1 ? 's' : ''} ago` : date;

    },
    abbreviateNumber(value) {
      var newValue = value;
      if (value >= 1000) {
        var suffixes = ["", "k", "m", "b","t"];
        var suffixNum = Math.floor( (""+value).length/3 );
        var shortValue = '';
        for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
        }
        if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
        newValue = shortValue+suffixes[suffixNum];
      }
      return newValue;
    },

  }
});



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
