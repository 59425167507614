<script>
import { Doughnut } from "vue-chartjs";
export default {
  name: "DoughnutGraph",
  extends: Doughnut,
  data() {
    return {
      chartData: {
        labels: ["Revenue", "Sales", "Credit"],
        datasets: [
          {
            borderWidth: 1,
            borderColor: [],
            backgroundColor: ["#9600bc", "green", "#98d1ee"],
            data: [0, 0, 0]
          }
        ]
      },
      options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>

<style scoped></style>
