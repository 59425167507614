<template>
<span>
    <v-card class="text-center mt-8 mb-3" shaped>
<!--      <v-badge
          v-if="profileIcon"
          bordered
          bottom
          color="green"
          offset-x="11"
          offset-y="13"
          content="120000"
      >-->
        <v-avatar class="mt-n7" size="60" elevation="10">
            <v-icon style="font-size: 60px;">
                {{ profileIcon }}
            </v-icon>
        </v-avatar>
<!--        </v-badge>-->
          <v-card-title class="layout justify-center">
            {{ name }}

              <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                               <v-icon
                                   class="ml-5"
                                   v-bind="attrs"
                                   v-on="on"
                                   small
                                   @click="editProfile()">
                              mdi-pencil
                            </v-icon>
                            </template>
                            <span>Edit Profile</span>
                          </v-tooltip>


          </v-card-title>
          <v-card-subtitle v-if="description" class="layout justify-center">{{ description }}</v-card-subtitle>

      <v-divider v-if="showGroupContactDataList"></v-divider>
      <div v-if="showGroupContactDataList" style="text-align: left; color: #757575;">
      <small class="ml-5"><i>Group: <span v-if="contact_count" style="color: #242f7e;">{{contact_count.toLocaleString()}} {{contact_count > 1 ? 'participants' : 'participant'}}</span></i></small>
      </div>
        </v-card>


    <v-card class="mt-3 mb-3" v-if="!showGroupContactDataList">
      <div class="ml-4 pt-4 pb-4">
        <span style="font-size: 14px;" v-for="(list, i) in userContact" :key="i">
          <span v-if="list.type === 'Email' && list.contact"><b>Email:</b> {{ list.contact }} <br/></span>
        </span>

         <span style="font-size: 14px;" v-for="(list, i) in userContact" :key="i">
          <span  v-if="list.type === 'Phone' && list.contact"><b>Phone:</b>
           <span style="color: #217ad3; cursor: pointer;"  @click.prevent="selectUserContact({contactNumber:list.number})">{{ list.contact }}</span>
            <br/></span>
        </span>
      </div>
   </v-card>


     <v-card class="mt-3 mb-3" v-if="showGroupContactDataList">

        <v-list subheader>

    <v-row class="mx-auto mt-3 mb-5">
        <small class="ml-3 mt-3" style="color: #696969; font-size: 14px;">Group contacts</small>
              <v-spacer></v-spacer>
            <v-btn
                outlined
                class="mt-2 mr-2"
                small
                @click="doAddContactToGroup"
            >
          <v-icon dark>
           mdi-plus
          </v-icon>
               Add contact
        </v-btn>
    </v-row>

              <v-text-field
                  @click:clear="returnsGroupListWhenCleared"
                  @keyup="returnsGroupListWhenNull"
                  class="mb-2"
                  dense
                  hide-details
                  outlined
                  clearable
                  v-model="search"
                  placeholder="Search Contact"
                  prepend-inner-icon="mdi-magnify"
                  v-on:keyup.enter="filterContactList"
              ></v-text-field>

           <div style="height: 55vh;">
              <v-progress-linear
                  v-if="contactLoader"
                  indeterminate
                  color="#242f7e"
                  class="mb-0"
                  height="2"
              ></v-progress-linear>
               <v-virtual-scroll
                      :items="groupContact"
                      item-height="61"
                      @scroll="onScroll"
                  >
                    <template v-slot:default="{ item }">
                      <v-list subheader>
                        <v-list-item-group
                            :v-model="item.number"
                            color="primary"
                        >
                          <v-list-item
                           style="border-bottom: 0.5px solid #f1f1f1;"
                           @click.prevent="selectUserContact(item)"
                           link
                       >
                          <v-list-item-avatar>
                            <v-icon large>
                               mdi-account-circle
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                            <small v-text="item.contact"></small>
                          </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </template>
               </v-virtual-scroll>
           </div>




       </v-list>

   </v-card>


 <v-dialog
     transition="dialog-bottom-transition"
     v-model="groupContactDialog"
     width="70%"
 >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Contacts under {{ groupDataInfo.grp_name }}</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="groupContactDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

              <group-contact-manager removeTitle="removeTitle" :groupInfo="groupData" ref="group_component"></group-contact-manager>


         </v-card>


      </v-card>
    </v-dialog>



  <v-dialog
      transition="dialog-bottom-transition"
      v-model="editUserDialog"
      width="42%"
  >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit User</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editUserDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-user :userInfo="userInfo" ref="edit_user_component"></new-user>

         </v-card>


      </v-card>
    </v-dialog>


  <v-dialog
      transition="dialog-bottom-transition"
      v-model="editGroupDialog"
      width="42%"
  >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit Group</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editGroupDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-group :groupInfo="groupInfo" ref="edit_group_component"></new-group>

         </v-card>


      </v-card>
    </v-dialog>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import GroupContacts from "@/views/Main/Groups/GroupContacts";
import NewUser from "@/views/Main/users/NewUser";
import NewGroup from "@/views/Main/Groups/NewGroup";
import GroupContactManager from "@/views/Main/Groups/GroupContactManager";

export default {
  name: "ContactProfile",
  components: {
    GroupContactManager,
    NewGroup,
    NewUser,
    // GroupContacts
  },
  data() {
    return {
      search: null,
      name: null,
      description: null,
      userContact: null,
      groupContact: [],
      profileIcon: null,
      items_per_page: 11,
      page: 1,
      contactLoader: false,
      groupContactDialog: false,
      groupDataInfo: false,
      groupData: false,
      editUserDialog:false,
      editGroupDialog:false,
      userInfo:{},
      groupInfo:{},
      userNumber:null,
      contactNumber:null,
      groupNumber:null,
      showGroupContactDataList:false,
      messageType:false,
      contact_count:null
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions(
        [
          "fetchContactProfile",
          "fetchGroupProfile",
          "contactMessageList"
        ]
    ),
    filterGroupContactList(data) {


        if(data.page === 1) {
          this.groupContact = []
        }
      let dataObj = data.data.GroupContact;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page;
      let per_page = data.per_page;
      this.items_per_page = (per_page * 1);

      this.contactLoader = false

      let jsn = {};
      // let arr = [];

      dataObj.forEach(list => {
        let contact = list.User
        jsn = {
          name: contact.surname + " " + contact.second_name + " " + contact.other_names,
          contact: list.contact,
          contactNumber: list.number,
          number: list.number
        }
        // arr.push(jsn)

        const index = this.groupContact.findIndex(object => object.number === jsn.number);

        if (index === -1) {
          this.groupContact.push(jsn);
        }

      })



    },
    returnsGroupListWhenNull(){
      if(!this.search){
        this.filterContactList()
      }
    },
    returnsGroupListWhenCleared(){
      this.search = ""
      this.filterContactList()
    },
    filterContactList() {
      this.contactLoader = true
      this.groupContact = []
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        group_number: this.groupNumber,
        search: this.search,
      }
      this.fetchGroupProfile(options)
    },
    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.contactLoader = true
        let options = {
          page: this.page + 1,
          per_page: this.items_per_page,
          search: this.search,
          group_number: this.groupNumber,
        }
        this.fetchGroupProfile(options)

      }
    },
    doAddContactToGroup() {
      this.groupContactDialog = true
    },
    selectUserContact(item) {
      this.description = ""
      let options = {
        page: 1,
        per_page: 11,
        contact_number: item.contactNumber,
        // search: this.search,
      }
      this.contactMessageList(options)

      //  fetch user contact profile
      this.fetchContactProfile(item.contactNumber)
    },
    editProfile(){
      if (this.messageType === "contact") {
        this.editUserDialog = true
        this.userInfo = {number: this.userNumber}

      } else if (this.messageType === "group") {
        this.editGroupDialog = true
        this.groupInfo = {number: this.groupNumber}
      }

    }
  },
  computed: {
    ...mapGetters(
        [
          "getContactProfileError",
          "getGroupProfile",
          "getContactProfile",
          "getNewContactGroupList",
            "getUpdateGroupInfo",
            "getEditUserInfo"
        ]
    )
  },
  watch: {
    getContactProfile(data) {
      if (data) {
        let profileData = data.data

        this.name = profileData.User.surname + " " + profileData.User.second_name + " " + profileData.User.other_names
        this.userContact = profileData.User.Contacts
        // this.successToast("Success", data.message)
        this.profileIcon = "mdi-account-circle"
        this.userNumber = profileData.User.number
        this.contactNumber = profileData.Contact.number
        this.messageType = "contact"

        this.showGroupContactDataList = false
      }
    },
    getGroupProfile(data) {
      if (data) {



        this.showGroupContactDataList = true



        let groupData = data.data

        this.name = groupData.grp_name
        this.contact_count = groupData.contact_count
        this.description = groupData.grp_desc
        this.groupDataInfo = groupData
        this.groupNumber = groupData.number
        this.messageType = "group"

        this.groupData = {
                      title: groupData.grp_name ? groupData.grp_name : "",
                      description: groupData.grp_desc ? groupData.grp_desc : "",
                      number: groupData.number ? groupData.number : "",
                      }


        this.profileIcon = "mdi-account-multiple-outline"


        this.filterGroupContactList(data)
      }
    },
    getContactProfileError(data) {
      if (data) {
        this.errorToast("Error", data.message)
      }
    },
    getNewContactGroupList(data) {
      if (data) {
        this.filterContactList()
        this.successToast("Success", data.message)
      }
    },
    getUpdateGroupInfo(data){
      if(data){
        this.editGroupDialog = false
        this.$refs["edit_group_component"].spinner = false
        this.$refs["edit_group_component"].form = {
          group_name:null,
          grp_desc:null,
        }
        this.filterContactList()
        this.successToast("Success", data.message)

      }
    },
    getEditUserInfo(data){
      if(data) {
        this.$refs["edit_user_component"].spinner = false
        this.editUserDialog = false
        this.selectUserContact({contactNumber: this.contactNumber})
        this.successToast("Success", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>