<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Import Contacts</v-toolbar-title>
        </v-toolbar>
          <v-divider></v-divider>

            <v-card flat>

                <v-row class="mx-auto mt-5">
                  <v-col cols="8" class="mx-auto">
                     <import-ui></import-ui>
                  </v-col>

                </v-row>



            </v-card>
        </v-card>
      </v-flex>
    </v-layout>
 </span>
</template>

<script>
import ImportUi from "@/views/Messenger/importcontacts/ImportUi";
export default {
  name: "ImportContacts",
  components: {ImportUi}
}
</script>

<style scoped>

</style>