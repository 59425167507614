<template>
<span>
    <v-alert type="error" v-if="errorMessage">
      {{ errorMessage }}
    </v-alert>
     <v-form @submit.prevent="PairNewPhone">

        <v-card-text>


<v-row>
  <v-col cols="12" sm="12" md="4" lg="4">
    <v-radio-group
        v-model="form.sim_slot"
        mandatory
        label="Select Sim"
        hide-details
    >
            <v-radio
                label="Sim 1"
                value="1"
            ></v-radio>
            <v-radio
                label="Sim 2"
                value="2"
            ></v-radio>
          </v-radio-group>
  </v-col>
  <v-col cols="12" sm="12" md="8" lg="8">
    <div class="mt-4">

<small style="color: #696969; font-size: 13px;">Phone Number</small>
     <v-text-field
         outlined
         v-model="form.phone_number"
         label="256"
         required
         type="tel"
         dense
         maxLength="15"
         @keypress="onlyNumberValidator($event)"
     ></v-text-field>
      </div>

  </v-col>
</v-row>

          <v-row class="mx-auto">
            <v-spacer></v-spacer>
            <v-btn
                class="mt-2"
                :loading="spinner"
                color="#01a59a"
                dark
                type="submit"
            >
              Continue
            </v-btn>
          </v-row>


        </v-card-text>
      </v-form>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PairNewPhone",
  data(){
    return{
      errorMessage:null,
      spinner:false,
      radios: null,
      form:{
        sim_slot:null,
        phone_number:null,
      }
    }
  },
  methods:{
    ...mapActions(
        [
            "doPairPhones"
        ]
    ),
    PairNewPhone(){
      this.errorMessage = ""
      this.spinner = true
      this.doPairPhones(this.form)
    }
  },
  computed:{
    ...mapGetters(
        [
            "getPairPhoneError"
        ]
    )
  },
  watch:{
    getPairPhoneError(data){
      if(data){
        this.spinner = false
        this.errorToast("Error", data.message)
      }
    }
  }
}
</script>

<style scoped>

</style>