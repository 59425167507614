<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!--    :class="item.label === this.$route.params.id ? 'activeLink' : ''"-->

        <v-row>
          <v-col col lg="3" md="3" sm="12">
            <v-card class="mx-auto" tile>
              <v-list dense>
                <v-subheader>Settings</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                      class="text-decoration-none"
                      v-for="(item, i) in items"
                      :key="i"
                      :to="{ name: 'settings', params: { id: item.name } }"
                      :value="item.active"
                      :class="item.name === tabSelector ? 'activeLink' : ''"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>

          <v-col col lg="9" md="9" sm="12">
            <v-card v-if="tabSelector === 'roles-and-permissions'">
              <RolesAndPermissions></RolesAndPermissions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RolesAndPermissions from "@/views/Main/RolesAndPermissions/RolesAndPermissions";

export default {
  name: "Settings",
  components: {
    RolesAndPermissions
  },
  data() {
    return {
      items: [
        {
          text: "Roles and Permissions",
          icon: "mdi-checkbox-blank-circle-outline",
          name: "roles-and-permissions"
        },
      ],
      tabSelector: this.$route.params.id
          ? this.$route.params.id
          : "roles-and-permissions"
    };
  }
};
</script>

<style scoped>
.activeLink {
  background-color: #e3eefa;
  color: #1976d2;
}
</style>
