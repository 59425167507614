import axios from "axios";
import configs from "../../../../Global/config.json";

const IP_MAIN = configs.SERVER_IP;

const state = {
    newContact: null,
    contactList: null,
    contactListPaginated: null,
    contactDataInfo: null,
    editUserContactInfo: null,
    newContactError: null,
};

const getters = {
    getNewContact: state => state.newContact,
    getContactList: state => state.contactList,
    getContactListPaginated: state => state.contactListPaginated,
    getContactDataInfo: state => state.contactDataInfo,
    getEditUserContactInfo: state => state.editUserContactInfo,
    getNewContactError: state => state.newContactError,
};

const actions = {
    async addNewContact({commit}, data) {
        await axios
            .post(IP_MAIN + "message/save-user-contact", data)
            .then(response => {
                commit("setNewContact", response);
            })
            .catch(error => {
                commit("setNewContactError", error);
            });
    },
    async editUserContact({commit}, data) {
        await axios
            .post(IP_MAIN + "message/edit-user-contact", data)
            .then(response => {
                commit("setEditUserContact", response);
            })
            .catch(error => {
                commit("setNewContactError", error);
            });
    },
    async fetchContactInfo({commit}, data) {
        await axios
            .get(IP_MAIN + "message/fetch-user-contact/"+data)
            .then(response => {
                commit("setContactDataInfo", response);
            })
            .catch(error => {
                commit("setNewContactError", error);
            });
    },
    async fetchContactList({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(IP_MAIN +
                "message/contact-list-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                "&search="+search
            )
            .then(response => {
                commit("setContactList", response);
            })
            .catch(error => {
                commit("setNewContactError", error);
            });
    },
    async fetchContactListPaginated({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(IP_MAIN +
                "message/contact-list-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                "&search="+search)
            .then(response => {
                commit("setContactListPaginated", response);
            })
            .catch(error => {
                commit("setNewContactError", error);
            });
    },


};

const mutations = {
    setNewContact: (state, response) => {
        state.newContact = response.data;
    },
    setEditUserContact: (state, response) => {
        state.editUserContactInfo = response.data;
    },
    setContactDataInfo: (state, response) => {
        state.contactDataInfo = response.data;
    },
    setContactList: (state, response) => {
        state.contactList = response.data;
    },
    setContactListPaginated: (state, response) => {
        state.contactListPaginated = response.data;
    },
    setNewContactError: (state, error) => {
        state.newContactError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

