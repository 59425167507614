<template>
<span>
      <v-card
          v-if="qrText"
          class="mt-10"
          style="text-align: center;"
          outlined
      >
      <div class="mt-5">
         <qrcode :size="size" :cls="qrCls" :value="qrText"></qrcode>
      </div>

      </v-card>
</span>
</template>

<script>
import Qrcode from 'v-qrcode/src/index'

export default {
  name: "QrCodeImage",
  props:{
    qrCodeNumber:String
  },
  components: {
    Qrcode
  },
  data(){
    return{
      qrCls: 'qrcode',
      qrText: "",
      size: 300,
    }
  },
  mounted() {
    this.checkNumber()
  },
  methods:{
    checkNumber(){
      this.qrText = this.qrCodeNumber

    }
  },
  watch:{
    qrCodeNumber(){
      this.checkNumber()
    }
  }

}
</script>

<style scoped>

</style>