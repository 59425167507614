var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('nav',[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"app":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("../../assets/logo.png")}})],1),_c('v-list-item-title',{staticStyle:{"font-size":"20px"}},[_vm._v("Noregrets")])],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("Company:")]),_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.companyName)+" ")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"shaped":"","dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":{name:item.name}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1)],1),_c('v-app-bar',{attrs:{"color":"#ffffff","dense":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}}),_c('v-toolbar-title'),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":220,"offset-x":"","offset-y":"","origin":"center center","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"12px"}},'span',attrs,false),on),[_vm._v(" New Message ")]),_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-badge',{staticStyle:{"font-size":"12px"},attrs:{"bordered":"","bottom":"","color":"#1976d2","content":_vm.messageCounterInfo,"offset-x":"12","offset-y":"12"}},[_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-bell")])],1)],1)],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',{staticStyle:{"font-size":"15px"}},[_vm._v("New Received Messages: "+_vm._s(_vm.messageCounterInfo))]),_c('v-divider'),_c('div',{staticStyle:{"width":"400px"}},[_c('received-messages')],1)],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":220,"offset-x":"","offset-y":"","origin":"center center","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"font-size":"12px"}},'span',attrs,false),on),[_vm._v(" Company ")]),_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"35"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-multiple")])],1)],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-subheader',{staticStyle:{"font-size":"16px"}},[_vm._v("Switch Company")]),_c('div',{staticClass:"ml-3",staticStyle:{"font-size":"14px","color":"#757575"}},[_vm._v("Company:")]),_c('div',{staticClass:"ml-3 mb-3",staticStyle:{"font-size":"12px","color":"#757575"}},[_vm._v(" "+_vm._s(_vm.companyName)+" ")]),_c('v-divider'),_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.companyList),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.switchCompanyProfile(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}})],1)],1)}),1)],1)],1)],1),_c('v-menu',{attrs:{"nudge-width":120,"offset-x":"","offset-y":"","open-on-hover":"","origin":"center center","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',[_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',[_c('small',[_vm._v(" "+_vm._s(_vm.userName)),_c('br'),_vm._v(" "+_vm._s(_vm.userEmail)+" ")])])],1)],1),_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"color":"","size":"35"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-account-circle")])],1)],1)]}}])},[_c('v-card',{attrs:{"width":"300px"}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-avatar',{staticClass:"mr-3"},[_c('v-icon',{staticStyle:{"color":"#1976d2"},attrs:{"large":""}},[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-1 mt-2"},[_c('span',[_vm._v(_vm._s(_vm.userName))]),_c('br'),_c('span',{},[_vm._v(_vm._s(_vm.userEmail))])])],1)],1),_c('v-list',{attrs:{"dense":""}},[_c('v-divider'),_c('v-list-item',{attrs:{"to":{name:'profile'}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("Profile ")])],1),_c('v-list-item',{on:{"click":_vm.doLogout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-location-exit")])],1),_c('v-list-item-title',[_vm._v("Logout ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }