<script>
import { Bar } from "vue-chartjs";
export default {
  name: "BarGraph",
  extends: Bar,
  data() {
    return {
      chartData: {
        labels: [
          "2015-01",
          "2015-02",
          "2015-03",
          "2015-04",
          "2015-05",
          "2015-06",
          "2015-07",
          "2015-08",
          "2015-09",
          "2015-10",
          "2015-11",
          "2015-12",
          "2015-12",
          "2015-12",
          "2015-12",
          "2015-12",
          "2015-12"
        ],
        datasets: [
          {
            label: "Revenue",
            borderWidth: 1,
            backgroundColor: [
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc",
              "#9600bc"
            ],
            borderColor: [],
            pointBorderColor: "#2554FF",
            data: [12, 19, 3, 5, 2, 60, 20, 30, 5, 6, 2, 10, 30, 23, 12, 8, 90]
          },
          {
            label: "Sales",
            borderWidth: 1,
            backgroundColor: [
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green",
              "green"
            ],
            borderColor: [],
            pointBorderColor: "green",
            data: [12, 19, 1, 5, 2, 60, 20, 30, 5, 6, 2, 10, 30, 23, 12, 8, 10]
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>

<style scoped></style>
