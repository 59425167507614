<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
              class="mx-2"
              dark
              color="teal"
              small
              @click="doAddNewUser"
          >
            <v-icon dark>
             mdi-plus
            </v-icon>
            New User
    </v-btn>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat>

                <v-row class="mx-auto mt-5">
                    <v-col cols="2">
                     <v-autocomplete
                         clearable
                         v-model="userStatus"
                         :items="statusList"
                         item-value="id"
                         label="Status"
                         dense
                         v-on:change="filterList"
                         hide-details
                     ></v-autocomplete>
                    </v-col>
                  <v-col cols="2">
                     <v-autocomplete
                         clearable
                         v-model="userGender"
                         :items="genderList"
                         item-value="id"
                         label="Gender"
                         dense
                         v-on:change="filterList"
                         hide-details
                     ></v-autocomplete>
                    </v-col>
                <v-col cols="4">

                </v-col>
                <v-col cols="4">
                   <v-text-field
                       clearable
                       v-model="search"
                       append-icon="mdi-magnify"
                       label="Search"
                       dense
                       outlined
                       persistent-hint
                       hint="Press enter to search"
                       v-on:keyup.enter="filterList"
                   ></v-text-field>
                </v-col>

            </v-row>


                <v-divider></v-divider>
                <v-progress-linear
                    v-if="tableLoader"
                    indeterminate
                    color="#242f7e"
                    class="mb-0"
                    height="2"
                ></v-progress-linear>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>



             <v-simple-table
                 fixed-header
             >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Name
                      </th>
                      <th class="text-left">
                        Gender
                      </th>
                      <th class="text-left">
                        Status
                      </th>
                      <th class="text-left">
                        Phone
                      </th>
                      <th class="text-left">
                        Email
                      </th>
                      <th width="5" class="text-left">
                        Active
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.number"
                    >
                      <td>{{ item.key }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.gender }}</td>
                      <td>{{ item.status }}</td>
                      <td>
                        <span v-for="(list, index) in item.phone" :key="index">
                          <span v-if="list.type === 'Phone'">
                            <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:list.number, q: (list.contact).replace('+', '') }}">
                             {{list.contact}}
                             </router-link>
                             <br />
                          </span>

                        </span>
                      </td>
                      <td>
                        <span v-for="(list, index) in item.email" :key="index">
                          <span v-if="list.type === 'Email'">

                            {{list.contact}}
 <br />
                          </span>
                        </span>
                      </td>
                       <td>

                            <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                               <v-icon  v-bind="attrs"
                                        v-on="on" small class="mr-2" @click="editUser(item)">
                              mdi-pencil
                            </v-icon>
                            </template>
                            <span>Edit User</span>
                        </v-tooltip>

                       </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>



        </v-card>



        </v-card>

      </v-flex>
    </v-layout>


    <v-dialog
        transition="dialog-bottom-transition"
        v-model="userDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>New User</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="userDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-user ref="user_component"></new-user>

         </v-card>


      </v-card>
    </v-dialog>

   <v-dialog
        transition="dialog-bottom-transition"
        v-model="editUserDialog"
        width="42%"
    >

      <v-card>
         <v-toolbar dense flat>
          <v-toolbar-title>Edit User</v-toolbar-title>
          <v-spacer></v-spacer>
            <v-icon
                color="red"
                @click="editUserDialog = false"
            >
             mdi-close
            </v-icon>
         </v-toolbar>
        <v-divider></v-divider>

         <v-card class="mx-auto pb-4 mt-6" width="96%" flat>

           <new-user :userInfo="userInfo" ref="edit_user_component"></new-user>

         </v-card>


      </v-card>
    </v-dialog>





</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NewUser from "@/views/Main/users/NewUser";

export default {
  name: "Users",
  components: {NewUser},
  data(){
    return{
      search: null,
      userStatus: null,
      userGender: null,
      tableLoader: false,
      pagination: {},
      page: 1,
      items_per_page: 15,
      items_per_page_options: [20, 50, 100, 500],
      server_items_length: 0,
      contactDialog:false,
      generalTableData: [],
      companyDialog:false,
      tableHeader: [
        {
          text: "#",
          align: "start",
          width: "5%",
          value: "key"
        },
        {text: "Name", value: "name", width: "30%"},
        {text: "Gender", value: "gender"},
        {text: "Status", value: "status" },
        {text: "Phone", value: "phone" },
        {text: "Email", value: "email"},
        // {text: "Action", value: "actions", width: "5%"}
      ],
      groupInfo:{},
      groupContactDialog:false,
      userDialog:false,
      editUserDialog:false,
      userInfo:{},
      genderList:[
        { text: '--Select Status--', id:"" },
        { text: 'Male', id:"Male" },
        { text: 'Female', id:"Female" },
      ],
      statusList:[
        { text: '--Select Status--', id:"" },
        { text: 'Active', id:"Active" },
        { text: 'Inactive', id:"Inactive" },
      ],
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
            "fetchUsersPaginated"
        ]
    ),
    filterList(){
      this.tableLoader = true
      this.generalTableData = []
      this.page = 1
      let options = {
        page: 1,//this.page,
        per_page: this.items_per_page,
        search: this.search,
        userStatus: this.userStatus,
        userGender: this.userGender,
      }
      this.fetchUsersPaginated(options)
    },
    filterUsersData(data){
      let dataObj = data.data;
      let current_page = (data.page * 1);
      this.page = dataObj.length === 0 ? (current_page - 1) : current_page ;

      let per_page = data.per_page;
      this.items_per_page = (per_page * 1) ;

      this.tableLoader = false;


      let obj = {};
      // let arr = [];

      dataObj.forEach((list, i) => {
        obj = {
          key: (current_page - 1) * per_page + i + 1,
          name: list.surname+" "+list.second_name+" "+list.other_names,
          gender: list.gender,
          status: list.user_active ? "Active" : "Inactive",
          email: list.Contacts,
          phone: list.Contacts,
          number: list.number,
        }

        const index = this.generalTableData.findIndex(object => object.number === obj.number);

        if (index === -1) {
          this.generalTableData.push(obj);
        }

      })

   //   this.generalTableData = arr;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.tableLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          search: this.search,
          userStatus: this.userStatus,
          userGender: this.userGender,
        }
        this.fetchUsersPaginated(options)
      }
    },
    doAddNewUser(){
      this.userDialog = true
    },
    editUser(item){
      this.editUserDialog = true
      this.userInfo = item
    }
  },
  computed:{
    ...mapGetters(
        [
            "getUsersList",
            "getNewUser",
            "getEditUserInfo"
        ]
    )
  },
  watch:{
    getUsersList(data){
      if(data){
        this.filterUsersData(data)
      }
    },
    getNewUser(data){
      if(data){
        this.$refs["user_component"].spinner = false
        this.$refs["user_component"].form = {
          surname: null,
          second_name: null,
          other_names: null,
          email: [
            {mail:null}
          ],
          phone: [
            {phoneNumber:null}
          ],
          gender: null,
        }
        this.userDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },
    getEditUserInfo(data){
      if(data) {
        this.$refs["edit_user_component"].spinner = false
        this.editUserDialog = false
        this.successToast("Success", data.message)
        this.filterList()
      }
    },

  }
}
</script>

<style scoped>

</style>