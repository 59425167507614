<template>
 <span>
    <v-layout class="mx-auto" row>
      <v-flex md12>

        <v-card tile width="97%" class="mx-auto mt-10">

        <v-toolbar dense flat>
          <v-toolbar-title>Call Logs</v-toolbar-title>
        </v-toolbar>

          <v-divider></v-divider>


            <v-card flat class="mb-10">


                     <v-row class="mx-auto mt-5">
                           <v-col cols="2">
                     <v-autocomplete
                         clearable
                         v-model="callType"
                         :items="typeList"
                         item-value="id"
                         label="Status"
                         dense
                         v-on:change="filterList"
                         hide-details
                     ></v-autocomplete>
                    </v-col>

                       <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="startDate"
                      dense
                      readonly
                      label="Start Date"
                      v-bind="attrs"
                      v-on="on"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      clearable
                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="2">
              <v-menu
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      class="mr-1"
                      v-model="endDate"
                      dense
                      readonly
                      label="End Date"
                      hint="[ YYY-MM-DD ]"
                      persistent-hint
                      v-bind="attrs"
                      clearable
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title></v-date-picker>
              </v-menu>
            </v-col>
<v-col cols="2"></v-col>
<v-col cols="4">
   <v-text-field
       clearable
       v-model="search"
       append-icon="mdi-magnify"
       label="Search"
       dense
       outlined
       persistent-hint
       hint="Press enter to search"
       v-on:keyup.enter="filterList"
   ></v-text-field>
</v-col>

            </v-row>



    <v-progress-linear
        v-if="callLogListLoader"
        indeterminate
        color="#242f7e"
        class="mb-0"
        height="3"
    ></v-progress-linear>

<v-divider></v-divider>
<v-card
    flat
    @scroll="onScroll"
    max-height="620px"
    style="width: 100%; overflow: scroll;"
>

             <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Date
                      </th>
                      <th class="text-left">
                        Call time
                      </th>
                      <th class="text-left">
                        Staff
                      </th>
                      <th class="text-left">
                        Client
                      </th>
                      <th class="text-left">
                        Type
                      </th>
                      <th class="text-left">
                        Duration (in seconds)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                        v-for="item in generalTableData"
                        :key="item.name"
                    >
                      <td>{{ item.key }}</td>
                      <td>{{ item.CreatedAt}}</td>
                      <td>{{ item.start_time }}</td>
                      <td>
                           <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:item.receiverNumber, q: (item.receiver).replace('+', '') }}">
                        <span v-text="item.receiver"></span>
                         <br />
                       <small style="color: #000000;">{{ item.receiver_name }}</small>
                         </router-link>
                      </td>

                       <td>

                             <router-link class="text-decoration-none" :to="{ name: 'messenger', query: {type:'con', id:item.caller_id_number, q: (item.caller).replace('+', '') }}">
                        <span v-text="item.caller"></span>
                         <br />
                       <small style="color: #000000;">{{ item.caller_name }}</small>
                         </router-link>
                       </td>
                      <td>
                        <v-chip
                            outlined
                            small
                            :color="typeColor(item.type)"
                            label
                        >
                       {{ item.type }}
                      </v-chip>
                        </td>
                      <td>{{ item.duration }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

</v-card>



        </v-card>



        </v-card>

      </v-flex>
    </v-layout>


</span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CallLogs",
  data(){
    return{
      search: "",
      page: 1,
      items_per_page: 15,
      callLogListLoader:false,
      generalTableData:[],
      callType:null,
      startDate:"",
      endDate: "",
      typeList:[
        { text: '--Select Type--', id:"" },
        { text: 'Outgoing', id:"OUTGOING" },
        { text: 'Incoming', id:"INCOMING" },
        { text: 'Missed', id:"MISSED" },
        { text: 'Unknown', id:"UNKNOWN" },
      ]
    }
  },
  mounted() {
    this.filterList()
  },
  methods:{
    ...mapActions(
        [
            "fetchCallLogsPaginated"
        ]
    ),
    filterList(){
      this.callLogListLoader = true
      this.generalTableData = []
      this.tableLoader = true
      this.page = 1
      let options = {
        page: 1,
        per_page: this.items_per_page,
        search: this.search,
        callType: this.callType,
        start_date:this.startDate,
        end_date:this.endDate,
      }
      this.fetchCallLogsPaginated(options)
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.messageListLoader = true
        let options = {
          page: this.page+1,
          per_page: this.items_per_page,
          callType: this.callType,
          start_date:this.startDate,
          end_date:this.endDate,
        }
        this.fetchCallLogsPaginated(options)
      }
    },
    filterCallLogsData(data){
      this.callLogListLoader = false
      if(data.data) {
        let dataObj = data.data;
        let current_page = (data.page * 1);
        this.page = dataObj.length === 0 ? (current_page - 1) : current_page;

        let per_page = data.per_page;
        this.items_per_page = (per_page * 1);


        let obj = {};


        dataObj.forEach((list, i) => {
          obj = {
            key: (current_page - 1) * per_page + i + 1,

            number: list.number,
            user_number: list.user_number ? list.user_number : "",
            company_profile_number: list.company_profile_number ? list.company_profile_number : "",
            connected_device_number: list.connected_device_number ? list.connected_device_number : "",
            receiver: list.receiver ? list.receiver : "",
            caller: list.caller ? list.caller : "",
            type: list.type ? list.type : "",
            start_time: list.start_time ? list.start_time : "",
            duration: list.duration,
            CreatedAt: list.created_at ? this.timeAgo(new Date(list.created_at)) : "",
            receiverNumber: list.receiver_id_number ? list.receiver_id_number : "",
            receiver_name: list.receiver_name ? list.receiver_name : "",
            caller_name: list.caller_name ? list.caller_name : "",
            caller_id_number: list.caller_id_number ? list.caller_id_number : "",
          }


          const index = this.generalTableData.findIndex(object => object.number === obj.number);

          if (index === -1) {
            this.generalTableData.push(obj);
          }

        })
      }

   },
    typeColor(data){
      let color = ""

      if(data === "MISSED"){
        color = 'red';
      } else if(data === "UNKNOWN"){
        color = 'purple'
      }else if(data === "OUTGOING"){
        color = 'blue'
      }else if(data === "INCOMING"){
        color = 'green'
      }

      return color
    }
  },
  computed:{
    ...mapGetters(
        [
            "getCallLogsListData"
        ]
    ),
  },
  watch:{
    getCallLogsListData(data){
      if(data){
        this.filterCallLogsData(data)
      }
    },
    startDate(){
      this.filterList()
    },
    endDate(){
      this.filterList()
    },
  }
}
</script>

<style scoped>

</style>