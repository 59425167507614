import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    callLogsListData: null,
    callLogsError: null,
};

const getters = {
    getCallLogsListData: state => state.callLogsListData,
    getCallLogsError: state => state.callLogsError,
};

const actions = {

    async fetchCallLogsPaginated({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let callType = data?data.callType ? data.callType: "":""
        let start_date = data ? data.start_date ? data.start_date : "" : ""
        let end_date = data ? data.end_date ? data.end_date : "" : ""
        await axios
            .get(IP +
                "message/call-logs-list-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                 "&search="+search+
                 "&callType="+callType+
                 "&start_date="+start_date+
                 "&end_date="+end_date
            )
            .then(response => {
                commit("setCallLogsList", response);
            })
            .catch(error => {
                commit("setCallLogsError", error);
            });
    },
  /*  async updateMessageStatus({commit}, data) {
        await axios
            .post(IP + "message/change-message-status", data)
            .then(response => {
                commit("setMessageStatus", response);
            })
            .catch(error => {
                commit("setMessageLogsError", error);
            });
    },*/


};

const mutations = {
    setCallLogsList: (state, response) => {
        state.callLogsListData = response.data;
    },
    setCallLogsError: (state, error) => {
        state.callLogsError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

