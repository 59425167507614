import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    pairPhone: null,
    pairDeviceData: null,
    defaultSenderData: null,
    enableDisablePhoneNumberData: null,
    pairPhoneError: null,
};

const getters = {
    getPairPhone: state => state.pairPhone,
    getPairDeviceData: state => state.pairDeviceData,
    getDefaultSenderData: state => state.defaultSenderData,
    getEnableDisablePhoneNumberData: state => state.enableDisablePhoneNumberData,
    getPairPhoneError: state => state.pairPhoneError,
};

const actions = {
    async doPairPhones({commit}, data) {
        await axios
            .post(IP + "message/pair-phone", data)
            .then(response => {
                commit("setPairPhone", response);
            })
            .catch(error => {
                commit("setPairPhoneError", error);
            });
    },
    async fetchConnectedDevices({commit}, data) {
        await axios
            .get(IP + "message/get-paired-device-data", data)
            .then(response => {
                commit("setPairDeviceData", response);
            })
            .catch(error => {
                commit("setPairPhoneError", error);
            });
    },
    async setDefaultPhoneSender({commit}, data) {
        await axios
            .post(IP + "message/set-default-phone-sender", data)
            .then(response => {
                commit("setDefaultSenderData", response);
            })
            .catch(error => {
                commit("setPairPhoneError", error);
            });
    },
    async enableDisablePhoneNumber({commit}, data) {
        await axios
            .post(IP + "message/set-enable-disable-phone-sender", data)
            .then(response => {
                commit("setEnableDisablePhoneNumberData", response);
            })
            .catch(error => {
                commit("setPairPhoneError", error);
            });
    },


};

const mutations = {
    setPairPhone: (state, response) => {
        state.pairPhone = response.data;
    },
    setPairDeviceData: (state, response) => {
        state.pairDeviceData = response.data;
    },
    setDefaultSenderData: (state, response) => {
        state.defaultSenderData = response.data;
    },
    setEnableDisablePhoneNumberData: (state, response) => {
        state.enableDisablePhoneNumberData = response.data;
    },
    setPairPhoneError: (state, error) => {
        state.pairPhoneError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

