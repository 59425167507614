import axios from "axios";
import configs from "../../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    newGroupFile: null,
    groupListFile: null,
    groupContactList: null,
    groupAddedContactList: null,
    newContactGroupList: null,
    groupListPagination: null,
    groupDetails: null,
    updateGroupInfo: null,
    newGroupError: null,
};

const getters = {
    getNewGroupFile: state => state.newGroupFile,
    getGroupListFile: state => state.groupListFile,
    getGroupContactList: state => state.groupContactList,
    getNewContactGroupList: state => state.newContactGroupList,
    getGroupListPagination: state => state.groupListPagination,
    getGroupDetails: state => state.groupDetails,
    getUpdateGroupInfo: state => state.updateGroupInfo,
    getGroupAddedContactList: state => state.groupAddedContactList,
    getNewGroupError: state => state.newGroupError,
};

const actions = {
    async saveNewGroup({commit}, data) {
        await axios
            .post(IP + "message/save-group-name", data)
            .then(response => {
                commit("setNewGroupFile", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async fetchGroupInfo({commit}, data) {
        await axios
            .get(IP + "message/fetch-group-info/"+data)
            .then(response => {
                commit("setGroupInfo", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async doUpdateGroup({commit}, data) {
        await axios
            .post(IP + "message/update-group-info", data)
            .then(response => {
                commit("setUpdateGroupInfo", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async fetchGroups({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let per_page = data ? data.per_page ? data.per_page : 20 : 20
        let page = data ? data.page ? data.page : 1 : 1
        await axios
            .get(IP +
                "message/fetch-groups-paginated?page="+page+
                "&per_page="+ per_page+
                "&search="+search
            )
            .then(response => {
                commit("setGroupListFile", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async fetchGroupsPaginated({commit}, data) {
        let search = data?data.search ? data.search: "":""
        await axios
            .get(IP +
                "message/fetch-groups-paginated?page="+data.page+
                "&per_page="+ data.per_page+
                "&search="+search
            )
            .then(response => {
                commit("setGroupListPagination", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async fetchGroupContacts({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let group_number = data?data.group_number ? data.group_number: "":""
        await axios
            .get(IP + "message/fetch-group-contacts?page="+data.page+
                "&per_page="+ data.per_page+
                "&group_number="+group_number+
                "&search="+search
            )
            .then(response => {
                commit("setGroupContactList", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async fetchGroupAddedContacts({commit}, data) {
        let search = data?data.search ? data.search: "":""
        let group_number = data?data.group_number ? data.group_number: "":""
        await axios
            .get(IP + "message/fetch-group-added-contacts?page="+data.page+
                "&per_page="+ data.per_page+
                "&group_number="+group_number+
                "&search="+search
            )
            .then(response => {
                commit("setGroupAddedContactList", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },
    async doAddGroupContacts({commit}, data) {
        await axios
            .post(IP + "message/add-contact-to-group", data)
            .then(response => {
                commit("setNewContactGroupList", response);
            })
            .catch(error => {
                commit("setNewGroupError", error);
            });
    },


};

const mutations = {
    setNewGroupFile: (state, response) => {
        state.newGroupFile = response.data;
    },
    setGroupInfo: (state, response) => {
        state.groupDetails = response.data;
    },
    setUpdateGroupInfo: (state, response) => {
        state.updateGroupInfo = response.data;
    },
    setGroupListFile: (state, response) => {
        state.groupListFile = response.data;
    },
    setGroupListPagination: (state, response) => {
        state.groupListPagination = response.data;
    },
    setGroupContactList: (state, response) => {
        state.groupContactList = response.data;
    },
    setGroupAddedContactList: (state, response) => {
        state.groupAddedContactList = response.data;
    },
    setNewContactGroupList: (state, response) => {
        state.newContactGroupList = response.data;
    },
    setNewGroupError: (state, error) => {
        state.newGroupError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

