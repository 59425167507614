import axios from "axios";
import configs from "../../../Global/config.json";

const IP = configs.SERVER_IP;

const state = {
    companyList: null,
    switchCompanyProfile: null,
    messageCount: null,
    companyListError: null,
};

const getters = {
    getCompanyList: state => state.companyList,
    getSwitchCompanyProfile: state => state.switchCompanyProfile,
    getMessageCount: state => state.messageCount,
    getCompanyListError: state => state.companyListError,
};

const actions = {
    async fetchCompanyList({commit}, data) {
        await axios
            .get(IP + "message/fetch-company-list", data)
            .then(response => {
                commit("setCompanyList", response);
            })
            .catch(error => {
                commit("setCompanyListError", error);
            });
    },
    async fetchMessageCount({commit}, data) {
        await axios
            .get(IP + "message/fetch-message-count", data)
            .then(response => {
                commit("setMessageCount", response);
            })
            .catch(error => {
                commit("setCompanyListError", error);
            });
    },
    async doSwitchCompanyProfile({commit}, data) {
        await axios
            .post(IP + "message/switch-company-profile", data)
            .then(response => {
                commit("setSwitchCompanyProfile", response);
            })
            .catch(error => {
                commit("setCompanyListError", error);
            });
    },


};

const mutations = {
    setCompanyList: (state, response) => {
        state.companyList = response.data;
    },
    setMessageCount: (state, response) => {
        state.messageCount = response.data;
    },
    setSwitchCompanyProfile: (state, response) => {
        state.switchCompanyProfile = response.data;
    },
    setCompanyListError: (state, error) => {
        state.companyListError = error.response ? error.response.data : "Network Error!";
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};

